import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Modal.css";

const AddProjects = ({ linkText, icon, className }) => {
  const [showProjects, setShowProjects] = useState(false);
  const [projectCount, setProjectCount] = useState(1);

  const handleProjectsOpen = () => {
    setShowProjects(true);
  };

  const handleProjectsClose = () => {
    setShowProjects(false);
  };

  const handleAddMoreProjects = () => {
    setProjectCount(projectCount + 1);
  };

  return (
    <>
      <Link to="" className={`add-new-label ${className}`} onClick={handleProjectsOpen}>
        {icon} {linkText}
      </Link>

      {showProjects && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Project</h4>
                <button type="button" className="close" onClick={handleProjectsClose}>
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  {Array.from({ length: projectCount }).map((_, index) => (
                    <div className="form-container border-bottom-2 mb-3" key={index}>
                      <div className="form-group">
                        <label htmlFor={`project_title_${index}`}>Project title <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Project title" id={`project_title_${index}`} />
                      </div>

                      <div className="form-group">
                        <label htmlFor={`tagline_${index}`}>Tag this project with your employment/education</label>
                        <select className="form-control" id={`tagline_${index}`}>
                          <option>Web Designer - service base</option>
                          <option>UG - Electronics/Telecommunication</option>
                          <option>Class XII</option>
                        </select>
                      </div>

                      <div className="d-sm-flex">
                        <div className="form-group flex-fill mr-0 mr-sm-3">
                          <label htmlFor={`start_date_${index}`}>Start</label>
                          <select className="form-control w-100" id={`start_date_${index}`}>
                            <option>Please Select</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                          </select>
                        </div>

                        <div className="form-group flex-fill ml-0 ml-sm-0">
                          <label htmlFor={`location_${index}`} className="hide">&nbsp;</label>
                          <select className="form-control w-100" id={`location_${index}`}>
                            <option>Please Select</option>
                            <option>2023</option>
                            <option>2022</option>
                            <option>2021</option>
                          </select>
                        </div>
                      </div>

                      <div className="d-sm-flex">
                        <div className="form-group flex-fill mr-0 mr-sm-3">
                          <label htmlFor={`start_date_${index}`}>End</label>
                          <select className="form-control w-100" id={`start_date_${index}`}>
                            <option>Please Select</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                          </select>
                        </div>

                        <div className="form-group flex-fill ml-0 ml-sm-3">
                          <label htmlFor={`location_${index}`} className="hide">&nbsp;</label>
                          <select className="form-control w-100" id={`location_${index}`}>
                            <option>Please Select</option>
                            <option>2023</option>
                            <option>2022</option>
                            <option>2021</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor={`project_skill_${index}`}> Skill <span className="text-danger"></span></label>
                        <input type="text" className="form-control" placeholder="ex. React , Angular " id={`project_skill_${index}`} />
                      </div>

                      <div className="form-group">
                        <label htmlFor={`description_${index}`}>Details of project</label>
                        <textarea className="form-control" rows="5" id={`description_${index}`} placeholder='Type here...'></textarea>
                      </div>
                    </div>
                  ))}

                  <div className='add-more-group my-3'>
                     <button type="button" className="btn btn-blue-outline " onClick={handleAddMoreProjects}>Add More Projects</button>
                  </div>

                </div>
                <div className="modal-footer text-right">
                  <button type="submit" className="btn blue-btn">Submit</button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProjects;
