import React from 'react'

const JobSearchStepsCard = (props) => {
    const {title, description }= props;
  return (
    <>
        <div className='job-search-content'>
            <h3>{title}</h3>
            <p>{description }</p>
        </div>
    </>
  )
}

export default JobSearchStepsCard
