import React from "react";
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import ApplicantCard from "./ApplicantCard";
import InsightFromProfile from "./InsightFromProfile";
import './viewapplicant.css';

const ViewApplicant = () => {
  return (
    <>
      <section className="pb-75 pt-4">
        <div className="fluid-container side-space">
          <div className="applicant-view">
            <div className="left-side">
              <LeftSidebar />
            </div>

            <div className="job-content">
              <div className="job-post-card mb-1">
                <ApplicantCard/>
                <InsightFromProfile/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewApplicant;
