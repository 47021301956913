import React from "react";
import { Link } from "react-router-dom";

const DefaultFaqcard = ({ title, icon, linkTo ,isActive  }) => {
  return (
    <div className="m-2">
      <div  className={`faq-card ${isActive ? "active" : ""}`}>
        <div className="faq-card-icon">{icon}</div>
        <div className="faq-title mt-2">
          <Link to={linkTo}>{title}</Link>
        </div>
      </div>
    </div>
  );
};

export default DefaultFaqcard;
