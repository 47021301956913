import React from 'react';
import { BsThreeDots } from "react-icons/bs";
import {Link} from "react-router-dom";

const ViewJobPostCard = () => {
  return (
    <>
      <div className='card profile-bg border-0'>
         <div className='card-body view-job-post-card'>
            <div class="d-flex view-card-img">
                <div class="job-view-image mr-4">
                   <img src="/static/media/dummy.7c118d410059de3c522d.jpg" alt="Title" class="j-view-image"/>
                </div>

                <div class="view-job-post-card-content">
                <h4> Front End Developer</h4>
                <p>  Designdot Technologies Pvt Ltd  Job locationNoida, Uttar Pradesh, India (Remote)</p>
                <p>Created 1 day ago</p>
                </div>
            </div>

            <div className='float-right d-flex view-card-btn'>
               <div className='job-btn'>
                <Link to="/profile" className='btn btn-blue-outline fa-mmd-14 mr-2'>View applicants</Link>
                <Link  className='btn btn-blue-outline fa-mmd-14 mr-2'>Repost job</Link>
               </div>
                <div className="three-dot three-ml">
                    <Link to="#"> <BsThreeDots className="gray fa-24 fa-mdd-20" /></Link>
                </div>
            </div>
         </div> 
      </div>
    </>
  )
}

export default ViewJobPostCard
