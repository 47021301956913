import React from "react";
import {Link} from "react-router-dom";
import './Pagination.css';

const Pagination = () => {
  return (
    <ul className="pagination">
      <li className="page-item">
        <Link className="page-link" >
          Previous
        </Link>
      </li>
      <li className="page-item">
        <Link className="page-link" >
          1
        </Link>
      </li>
      <li className="page-item active">
        <Link className="page-link" >
          2
        </Link>
      </li>
      <li className="page-item">
        <Link className="page-link" >
          3
        </Link>
      </li>
      <li className="page-item">
        <Link className="page-link" >
          Next
        </Link>
      </li>
    </ul>
  );
};

export default Pagination;
