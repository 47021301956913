import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFilePdf, FaFileWord, FaFile } from 'react-icons/fa';

const UploadResume = () => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const getFileExtension = (filename) => {
    return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
  };

  const getIconComponent = (extension) => {
    if (extension === 'pdf') {
      return <FaFilePdf className='pdf-icon' />;
    } else if (extension === 'doc' || extension === 'docx') {
      return <FaFileWord className='doc-icon' />;
    } else {
      return <FaFile className='doc-icon'/>;
    }
  };


  const handleDownloadFile = () => {
    const downloadLink = URL.createObjectURL(selectedFile);
    const a = document.createElement('a');
    a.href = downloadLink;
    a.download = selectedFile.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(downloadLink);
  };
  return (
    <div>
    
      {selectedFile && (
       
          <div className="file-icon">
          <p className='mb-0'  onClick={handleDownloadFile}>
              {getIconComponent(getFileExtension(selectedFile.name))} <span className='pl-2'> {selectedFile.name}</span>
          </p>
          </div>
        
      )}

      <Link className="btn btn-blue-outline mt-3" onClick={handleButtonClick}>
        Upload Resume
      </Link>
      <p className='fa-12 gray mt-2'>DOC, DOCX, PDF (2 MB)</p>
      <input
        type="file"
        ref={fileInputRef}
        accept=".pdf,.doc,.docx"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />  
    </div>
  );
};

export default UploadResume;
