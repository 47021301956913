import React, {useState} from "react";
import {Link} from 'react-router-dom';
import { AiOutlineEdit} from 'react-icons/ai';
import "./Modal.css";

const EditProfileSummary = () => {

    const [showSummary, SetshowSummary] = useState(false);
   
    const handleShowSummaryOpan =() =>{
        SetshowSummary(true);
    }

    const handleShowSummaryClose =() =>{
        SetshowSummary(false);
    }



  return (
    <>
    <Link
        data-toggle="modal"
        data-target="#editintro"
        onClick={handleShowSummaryOpan}
       >
       <AiOutlineEdit className='gary-text fa-28' />
    </Link>
    {showSummary &&(
      <div className="modal" style={{ display: "block" }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit about (summary)</h4>
              <button type="button" className="close" onClick={handleShowSummaryClose}>
                &times;
              </button>
            </div>
            <form action="">
              <div className="modal-body">
                <div className="form-container">
                  <div className="form-group">
                    <label htmlFor="summary">You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences.</label>
                    <textarea className="form-control" rows="10" id="summary" value="I am a Professional, Creative Web designer with 3 years of experience. I have experience in web designing, Html, CSS, Bootstrap, JavaScript, ReactJs, WordPress, Adobe Photoshop and Figma.

I am passionate about my work. I'm constantly looking to learn the latest design context and stretch my abilities.

I have enjoyed my professional career working with some esteemed companies which gave me an opportunity to work on projects in different sectors enhancing my skillset.

Thanks !"/>
                  </div>
                </div>
              </div>
              <div className="modal-footer  text-right">
                <button type="submit" className="btn blue-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
     )}
    </>
  );
};

export default EditProfileSummary;
