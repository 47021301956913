import React from 'react';
import './CareerInsight.css';


const CareerInsight = ({ workExperiences }) => {
  return (
    <>
      <div className='career-insight-container mt-3'>
        <div className='common-heading mt-4'>
          <h3>Career Insight</h3>
        </div>

        <div className='career-insight mt-3'>
          {workExperiences.map((experience, index) => (
            <div className='career-insight-inner' key={index}>
              <h4>{experience.title}</h4>
              <p className='fa-18 fa-mdd-16 fw-500 black'>{experience.years}</p>
              <p>{experience.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CareerInsight;
