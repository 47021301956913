import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineGoogle, AiFillLinkedin } from 'react-icons/ai';
import { GoEye, GoEyeClosed } from 'react-icons/go';


const displayNone = {
    display: 'none'
}

const displayInherit = {
    display: 'inherit'
}

export default function LoginForm({ formChangeHandler }) {

    const [showPassword, setShowPassword] = useState('password');
    const [eyeIconStyle, setEyeIconStyle] = useState([displayInherit, displayNone]);

    const showPasswordHandler = (action) => {
        if (action === 'show') {
            setShowPassword('text')
            setEyeIconStyle([displayNone, displayInherit])
        }

        if (action === 'hide') {
            setShowPassword('password')
            setEyeIconStyle([displayInherit, displayNone])
        }
    }

    return (
        <div className='login'>
            <div className='login-heading'>
               <h3>Log In</h3>
            </div>
            <div className='third-party-join-container d-flex justify-content-between p-4'>
                <Link to='#' className='google-btn'>
                    Google
                    <AiOutlineGoogle className='fa-24 fa-sm-20 ml-1' />
                </Link>
                
                <Link to='#' className='google-btn'>
                    Linkedln
                    <AiFillLinkedin className='fa-24 fa-sm-20 ml-1'/>
                   
                </Link>
            </div>

            <hr className='m-0'/>

            {/* form starts here */}
            <form action='/dashboard'>
                <div className='row form-style p-4'>
                    <div className='col-xl-12 col-md-12'>
                        <div className="form-group">
                            <label for="email">Email</label>
                            <input type="email" className="form-control" placeholder="Enter email" id="email" />
                        </div>
                    </div>

                    <div className='col-xl-12 col-md-12'>
                       <div className="form-group">
                         <label for="pwd">Password</label>
                        <div class="input-group mb-3">
                            <input type={showPassword} className="form-control border-right-0" placeholder="Enter password" id="pwd" />
                            <div class="input-group-prepend">
                              <span class="input-group-text border-left-0">
                              <GoEye
                                    style={eyeIconStyle[0]}
                                    onClick={(e) => showPasswordHandler('show')}
                                />
                                <GoEyeClosed
                                    style={eyeIconStyle[1]}
                                    onClick={(e) => showPasswordHandler('hide')}
                                />
                              </span>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className='col-xl-12 col-md-12'>
                        <div className='remember-me d-sm-flex d-block  justify-content-between my-2'>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck" name=""/>
                            <label class="custom-control-label label-text pt-1 pl-2" for="customCheck">Remember me</label>
                        </div>

                        <div className='forgot-password mt-sm-0 mt-1'>
                            <Link className='text-decoration-underline link-text' to='#' onClick={(e) => formChangeHandler('forgetPassword')}  >
                                Forgot your password?
                            </Link>
                        </div>
                    </div>

                    </div>
                    <div className='col-xl-12 col-md-12 text-center'>
                      <button type="submit" className="btn login-btn mt-3">Log In</button>
                    </div>

                </div>
            </form>
            {/* form ends here */}

            <div>
                <p className='label-text text-center mb-0'>Don't have an account? <Link to='#' class="link-text text-decoration-underline " onClick={(e) => formChangeHandler('signUp')}>Sign Up</Link></p>
            </div>
        </div>
    )
}
