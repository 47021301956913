import React from 'react';

const MessageList = ({ messageList }) => {
  return (
    <>
        <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Messages</h4>
            </div>
            <div className='card-body pt-0 pb-0'>
            <div className='message-list'>
                {messageList.map((message, index) => (
                    <div key={index} className='d-flex justify-content-between border-bottom-1 py-3'>
                    <div className='message-info w-80'>
                        <p className='fa-12'>{message.destinationtext}</p>
                        <h4>{message.participant_names}</h4>
                        <p className='light-black'>{message.msg_description}</p>
                    </div>
                    <div className='message-date'>
                        <p className='fa-12 gray'>{message.msgdate}</p>
                    </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
   </>
  );
};

export default MessageList;
