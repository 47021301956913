import React from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HelpQuestionCard = () => {
  const questions = [
    'How can I update/edit my Profile?',
    'How do I reset my password?',
    'What payment methods are accepted?',
    'How can I track my order?',
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
     
        {
          breakpoint: 1501,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
          },
        },
  
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
          },
        },
  
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
          },
        },
  
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
  
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
          },
        },
     
    ],
  };

  return (
    <div className="carousel-wrapper">
      <Slider {...settings}>
        {questions.map((question, index) => (
          <div className='border-right-1 text-left'>
            <div className="question" key={index}>
               <div className=""><Link to="/job-seeker-search-question" className='light-black'>{`Q. ${question}`}</Link></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HelpQuestionCard;
