import React from "react";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";


const PurchaseTable = () => {

  return (
    <>
      <div className="job-tracker-container table-responsive">
        <table className="table  w-100">
          <thead>
            <tr>
              <th>
              Date <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
               Invoice <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Type <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Package <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Description <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Total <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Download <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
            
            </tr>
          </thead>
          <tbody>
            <tr className="j-gray-bg align-items-center border-18">
              <td>
                  <p className="fa-14 fw-600 light-black mb-0">10 JULY, 2023</p>
              </td>
              <td>
                <Link to="#" className="light-black">
                INV #001 <br/>
                09874567
                </Link>
              </td>
              <td>
              Candidate
              </td>
              <td>
              3 Months
              </td>
             
              <td>
              Job Search & Apply Job
              </td>
              <td>
              Rs. 250
              </td>
              <td>
              <Link to="/invoice" className="bg-success px-3 py-1 border-radius-8 text-white">Invoice</Link>
              </td>
             
            </tr>
            <tr className="j-gray-bg align-items-center border-2">
              <td>
                  <p className="fa-14 fw-600 light-black mb-0">10 JULY, 2023</p>
              </td>
              <td>
                <Link to="#" className="light-black">
                INV #001 <br/>
                09874567
                </Link>
              </td>
              <td>
              Candidate
              </td>
              <td>
              3 Months
              </td>
             
              <td>
              Job Search & Apply Job
              </td>
              <td>
              Rs. 250
              </td>
              <td>
                <Link to="/invoice" className="bg-success px-3 py-1 border-radius-8 text-white">Invoice</Link>
              </td>
             
            </tr>
          </tbody>
        </table>
      </div>

      <div className="pagination-row float-right mt-3">
        <Pagination />
      </div>
    </>
  );
};

export default PurchaseTable;
