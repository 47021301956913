import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditBasicIntro from "./EditBasicIntro";
import "./Modal.css";

const ContactInfo = () => {
  const [showViewContactModal, setViewContactShowModal] = useState(false);

  const handleViewContactOpenModal = () => {
    setViewContactShowModal(true);
  };

  const handleViewContactCloseModal = () => {
    setViewContactShowModal(false);
  };

  return (
    <>
      <Link
        to=""
        className="ml-3 link-text"
        onClick={handleViewContactOpenModal}
      >
        Contact info
      </Link>

      {showViewContactModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h4 className="modal-title">Alex Jashua</h4>
                  <p className="fa-16 fw-400 gray mb-0">
                    Web Designer
                    <span className="fa-14"> (at service base)</span>
                  </p>
                </div>
                <span className="edit ml-2 mt-3"> <EditBasicIntro className="ml-0"/></span>

                <button
                  type="button"
                  className="close"
                  onClick={handleViewContactCloseModal}
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="form-container">
                  <div className="contact-group">
                    
                    <div className="mb-3">
                      <h4 className="fa-16 light-black fw-500 mb-1" >
                        Basic Details
                      </h4>

                      <p className="mb-0 light-black fa-14 fw-500">
                        Name :
                          <span className="gray fa-14 fw-400">
                            Alex Jashua
                          </span>
                      </p>
                      <p className="mb-0 light-black fa-14 fw-500">
                        Email :
                        <Link
                          to="email:shakti10@gmail.com"
                          className="gray ml-1 fw-400"
                        >
                          shakti10@gmail.com
                        </Link>
                      </p>

                      <p className="mb-0 light-black fa-14 fw-500">
                        Phone :
                        <span className="gray fa-14 fw-400">
                          +91 9918 568 401
                        </span>
                      </p>
                    </div>

                 

                    <div className="mb-3">
                      <h4 className="fa-16 light-black fw-500 mb-1">Job titles</h4>
                      <p className="mb-0 light-black fa-14 fw-400">
                        Web Designer , Frontend Developer , Javascript Developer
                        , HTML Developer
                      </p>
                    </div>

                    <div className="mb-3">
                      <h4 className="fa-16 light-black fw-500 mb-1">
                       Address
                      </h4>
                      <p className="mb-0 light-black fa-14 fw-400">
                       Dwarka, Delhi, India
                      </p>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactInfo;
