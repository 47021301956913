import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { AiOutlineGoogle, AiFillLinkedin } from "react-icons/ai";
import { GoEye, GoEyeClosed } from "react-icons/go";

const displayNone = {
  display: "none",
};

const displayInherit = {
  display: "inherit",
};

const SignUpForm = ({ formChangeHandler }) => {
  const [showPassword, setShowPassword] = useState("password");
  const [eyeIconStyle, setEyeIconStyle] = useState([
    displayInherit,
    displayNone,
  ]);

  const showPasswordHandler = (action) => {
    if (action === "show") {
      setShowPassword("text");
      setEyeIconStyle([displayNone, displayInherit]);
    }

    if (action === "hide") {
      setShowPassword("password");
      setEyeIconStyle([displayInherit, displayNone]);
    }
  };

  return (
    <div className="login">
      <div className="login-heading">
        <h3>Create an account</h3>
      </div>
      <div className="third-party-join-container d-flex justify-content-between p-4">
        <Link to="#" className="google-btn">
          Google
          <AiOutlineGoogle className="fa-24 ml-1" />
        </Link>
        <Link to="#" className="google-btn">
          Linkedln
          <AiFillLinkedin className="fa-24 ml-1" />
        </Link>
      </div>

      <hr className="m-0" />

      {/* form starts here */}
      <form>
        <div className="row form-style p-4">
          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                id="email"
              />
            </div>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label for="pwd">Password</label>
              <div class="input-group mb-3">
                <input
                  type={showPassword}
                  className="form-control border-right-0"
                  placeholder="Enter password"
                  id="pwd"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text border-left-0">
                    <GoEye
                      style={eyeIconStyle[0]}
                      onClick={(e) => showPasswordHandler("show")}
                    />
                    <GoEyeClosed
                      style={eyeIconStyle[1]}
                      onClick={(e) => showPasswordHandler("hide")}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 text-center">
            <button type="submit" className="btn login-btn mt-3">
              Create Account
            </button>
          </div>
        </div>
      </form>

      {/* form ends here */}
      <div>
        <p className="label-text text-center mb-0">
          Have an Account? 
          <Link
            to="#"
            class="label-text  text-decoration-underline"
            onClick={(e) => formChangeHandler("login")} >  Sign In
          </Link>
        </p>
      </div>
      <hr />

      <div className="term-condition text-center">
        <small>By creating an account, you are agreeing to our</small>
        <p>
          <Link to="#" className="text-decoration-underline">
            Terms & Conditions
          </Link>
          and
          <Link to="#" className="text-decoration-underline">
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpForm;
