import React from "react";

const JobpostInfo = () => {
  return (
    <>
      <div className="card">
        <div className="card-header bg-transparent border-bottom-0">
          <h3 className="card-title fw-500 light-black">Job description </h3>
        </div>
        <div className="card-body pt-0">
          <p className="gray fw-400 fa-16 fa-md-14">
            DesignDot introduces itself to you as a Global Creative Agency.
            DesignDot has emerged as a one-stop Design, Development and
            Technology services and solutions provider for many national and
            international corporate brands. We also work with Small Medium
            Enterprise companies and cater to their requirements with customized
            budgets.
          </p>

          <div className="post-job-info mb-3">
            <h4>Industry : </h4>
            <p>Information Technology & Services</p>
          </div>

          <div className="post-job-info mb-3">
            <h4>Employment Type : </h4>
            <p>Temporary</p>
          </div>

          <div className="post-job-info mb-3">
            <h4 className="mb-2">Our Services : </h4>
            <p>
              <b>EXPERIENCE </b>- Web Mobile Cloud App, UI & UX, IoT,
              E-Commerce, E-Learning, Etc.
            </p>
            <p>
              <b>BRANDING </b>- Brand Strategy, Signage Design, Product Design,
              Stationary Design Etc.
            </p>
            <p>
              <b>VISIBILITY </b>- Outdoor Advertising, Story Boarding, Digital
              Media, Commercial Media Etc.
            </p>

            <ul className="mt-3">
              <li>
               
                <b>Job Title : </b> Sales & Marketing Executive
              </li>
              <li>
               
                <b>Job Location : </b> REMOTE JOB
              </li>
              <li>
               
                <b>Experience : </b> 1-2yrs
              </li>
              <li>
               
                <b>Key Skill : </b> Be able to independently manage clients.
                Complete command Communication Skill along with little technical
                knowledge.
              </li>
              <li>
               
                <b>Job Profile : </b> Would be responsible for introducing our
                company and its services in the market and generating positive
                business leads. Should be able to drive sales of the services
                related to the company. Should make regular follow up with the
                clients.
              </li>
              <li>
               
                <b>Education : </b> BBA/MBA/BTECH
              </li>
              <li>
               
                <b>Functional area : </b> Lead Generation. Sales & marketing
              </li>
            </ul>
          </div>

          <div className="post-job-info mb-3">
            <h4 className="mb-2">Skills & Requirements : </h4>
            <ul className="mt-3">
              <li>Fluent in English (written and verbal)</li>
              <li>Team player with an enthusiastic personality </li>
              <li>Ability to work in a fast-paced environment</li>
              <li>
                A strong desire to expand existing services into the enterprise
              </li>
              <li>Experience working with brand agencies</li>
            </ul>
          </div>

          <div className="post-job-info mb-3">
            <h4 className="mb-2">Supplemental Pay : </h4>
            <ul className="mt-3">
              <li>Fluent in English (written and verbal)</li>
              <li>Team player with an enthusiastic personality </li>
              <li>Ability to work in a fast-paced environment</li>
              <li>
                A strong desire to expand existing services into the enterprise
              </li>
              <li>Experience working with brand agencies</li>
            </ul>
          </div>

          <div className="post-job-info mb-3">
            <h4 className="mb-2">Benefits : </h4>
            <ul className="mt-3">
              <li> Work from home </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobpostInfo;
