import React from 'react';
import './message.css';
import MessageList from  './MessageList'
import ChatBox from './ChatBox';

const Message = () => {

 const Messagedata = [
    
    {
        destinationtext:"Applied to Business Development in New Delhi , Delhi", 
        participant_names:"Soni Chiang", 
        msg_description:"Hi Soni Chiang , ary you still intrested in Business Development position", 
        msgdate:"June 17"
        
    },
    {
        destinationtext:"Applied to Web Deevloper in New Delhi , Delhi", 
        participant_names:"Arjun", 
        msg_description:"I have aready send the mail", 
        msgdate:"July 31"
        
    },

    {
        destinationtext:"Applied to Web designer in New Delhi , Delhi", 
        participant_names:"Diane Chiang", 
        msg_description:"I have aready send the mail", 
        msgdate:"Feb 17"
        
    },

 ];

  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-3-2-container">
            <div className='left-side grid-left'>
                <MessageList messageList={Messagedata}/>
            </div>

            <div className='main-content mt-smm-0 mt-3 grid-middle'>
              <ChatBox/>
            </div>
           
            <div className='right-side grid-right'>
            
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Message
