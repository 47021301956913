import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";

const MyJobTable = () => {
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});

  const handleItemMouseEnter = (item, event) => {
    setSelectedItem(item);
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const position = {
      top: rect.top + rect.height + scrollTop + 30,
      left: rect.left + scrollLeft,
    };
    setBoxPosition(position);
    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };


  const workplace = ["Remote", "On-site", "Hybrid"];
  const jobtype = ["Full Time", "Part Time", "Contarct", "Temporary", "Other"];
  const applied = ["N/A","Apply"];

  return (
    <>
      <div className="job-tracker-container table-responsive">
        <table className="table  w-100">
          <thead>
            <tr>
              <th>
                Job Position <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Res. match <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Skill match <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Skill <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Workplace <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Job Type <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Experience <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Location <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Max Salary <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Applied <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>


              <th className="text-right">
                <CiSliderVertical className="fa-20 black fw-700" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="j-gray-bg align-items-center border-18">
              <td style={{ width: "200px" }}>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("Comapny Name", e)}
                  onClick={() => handleItemClick("Comapny Name")}
                  className={selectedItem === "Comapny Name" ? "active" : ""}
                >
                  <p className="fa-14 fw-600 light-black mb-0">DesignDot Technology</p>
                  <p className="fa-12 fw-400 gray">Product Designer - Sample Job</p>
                </Link>
              </td>
              <td>
                <span className="mb-2">70%</span>
                <div className="progress" style={{ height: "15px", borderRadius: "14px" }}>
                  <div className="progress-bar bg-dark-blue" style={{ width: "70%", height: "15px" }}></div>
                </div>
              </td>
              <td>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                  onClick={(event) => handleItemClick("80%")}
                  className={`light-black ${selectedItem === "80%" ? "active" : ""}`}
                >
                  <span className="mb-2">80%</span>
                  <div className="progress" style={{ height: "15px", borderRadius: "14px" }}>
                    <div className="progress-bar bg-dark-blue" style={{ width: "80%", height: "15px" }}></div>
                  </div>
                </Link>
              </td>
              <td>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("skill", e)}
                  onClick={(event) => handleItemClick("skill")}
                  className={`gray ${selectedItem === "skill" ? "active" : ""}`}
                >
                  Skill
                </Link>
              </td>
              <td className="remotedata">
                   <select  className="form-control fa-14" >
                    {workplace.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
              </td>

              <td className="remotedata">
                  <select
                    className="form-control fa-14"
                  >
                    {jobtype.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
              </td>

              <td>1 Years</td>
              <td>Bangalore, India</td>
              <td>$0.00</td>
              <td>
                  <select
                    className="form-control fa-14"
                  >
                    {applied.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
              </td>
              <td>
                <div className="dropdown dropright float-right">
                  <button
                    type="button"
                    className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                    data-toggle="dropdown"
                  >
                    <BsThreeDots className="black fa-26" />
                  </button>
                  <div className="dropdown-menu transition-0">
                    <Link to="/job-search" className="dropdown-item fa-14 light-black fw-400">Job Details</Link>
                    <Link className="dropdown-item fa-14 light-black fw-400">Delete</Link>
                  </div>
                </div>
              </td>
            </tr>

            <tr className="j-gray-bg align-items-center border-18">
              <td style={{ width: "200px" }}>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("Comapny Name", e)}
                  onClick={() => handleItemClick("Comapny Name")}
                  className={selectedItem === "Comapny Name" ? "active" : ""}
                >
                  <p className="fa-14 fw-600 light-black mb-0">GMS Pvt. Ltd.</p>
                  <p className="fa-12 fw-400 gray">Product Designer - Sample Job</p>
                </Link>
              </td>
              <td>
                <span className="mb-2">70%</span>
                <div className="progress" style={{ height: "15px", borderRadius: "14px" }}>
                  <div className="progress-bar bg-dark-blue" style={{ width: "70%", height: "15px" }}></div>
                </div>
              </td>
              <td>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                  onClick={(event) => handleItemClick("80%")}
                  className={`light-black ${selectedItem === "80%" ? "active" : ""}`}
                >
                  <span className="mb-2">80%</span>
                  <div className="progress" style={{ height: "15px", borderRadius: "14px" }}>
                    <div className="progress-bar bg-dark-blue" style={{ width: "80%", height: "15px" }}></div>
                  </div>
                </Link>
              </td>
              <td>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("skill", e)}
                  onClick={(event) => handleItemClick("skill")}
                  className={`gray ${selectedItem === "skill" ? "active" : ""}`}
                >
                  Skill
                </Link>
              </td>
              <td className="remotedata">
                  <select
                    className="form-control fa-14"
                  >
                    {workplace.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
              </td>

              <td className="remotedata">
               
                  <select
                    className="form-control fa-14"
                  >
                    {jobtype.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
              </td>

              <td>3 Years</td>

              <td>Bangalore, India</td>
              <td>$0.00</td>
              <td>
                  <select
                    className="form-control fa-14"
                  >
                    {applied.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
              </td>
              <td>
                <div className="dropdown dropright float-right">
                  <button
                    type="button"
                    className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                    data-toggle="dropdown"
                  >
                    <BsThreeDots className="black fa-26" />
                  </button>
                  <div className="dropdown-menu transition-0">
                    <Link to="/job-search" className="dropdown-item fa-14 light-black fw-400">Job Details</Link>
                    <Link to="" className="dropdown-item fa-14 light-black fw-400">Delete</Link>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {showBox && (
        <div
          className={`box ${showBox ? "slide-down" : "slide-up"}`}
          style={{
            top: boxPosition.top,
            left: boxPosition.left,
          }}
        >
          {selectedItem === "Comapny Name" && (
            <div className="box-info">
              <p className="fa-14 gray fw-400 line-height-24">
                As a Marketing Professional my accentuation is on conversations, for which
                it is fundamental that we know our get-together of individuals well or
                potentially have the preparation to get some answers concerning them
                Pieces of information aren’t actually what examination are uncovering to
                us how ....
              </p>

              <Link to="/company" className="btn blue-btn mt-3">
                View Profile
              </Link>
            </div>
          )}

          {selectedItem === "80%" && (
            <div className="skill-content">
              <ul className="skill-content-list">
                <li>
                  <span>NODE JS</span> <span>90%</span>
                </li>
                <li>
                  <span>ANGULAR</span> <span>90%</span>
                </li>
                <li>
                  <span>ORACLE</span> <span>90%</span>
                </li>
                <li>
                  <span>PYTHON</span> <span>80%</span>
                </li>
              </ul>
            </div>
          )}

          {selectedItem === "skill" && (
            <div className="work-experience">
               <WorkSkills/>
            </div>
          )}

          <button className="box-close" onClick={handleBoxClose}>
            X
          </button>
        </div>
      )}

      <div className="pagination-row float-right">
        <Pagination />
      </div>
    </>
  );
};

export default MyJobTable;
