import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {AiOutlinePlus } from 'react-icons/ai';
import './InputTag.css';

const InputTag = ({title}) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
      setShowInput(false);
    }
  };

  const handleAddSkillClick = () => {
    setShowInput(true);
  };

  return (
    <>
      <div className="tag-list mt-2">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <button className="tag-remove" onClick={() => setTags(tags.filter((_, i) => i !== index))}>
              &#x2716;
            </button>
          </div>
        ))}
      </div>

      {!showInput && (
        <div className='outline-btn pt-2'>
          <Link to="" className='btn btn-blue-outline' onClick={handleAddSkillClick}><AiOutlinePlus/> {title}</Link>
        </div>
      )}

      {showInput && (
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Enter a Skill (ex: Project Management) and press Enter"
          autoFocus
          className='form-control'
        />
      )}
    </>
  );
};

export default InputTag;
