import React from 'react'

const SuggestedJobSearches = () => {
  return (
    <>
      <div className='card border-0 profile-bg mb-3'>
           <div className='card-header border-bottom-0 bg-transparent'>
              <h3 className='card-title border-bottom-2 pb-3 pb-lg-2'>Suggested job searches</h3>
           </div>
            <div className='card-body pt-2'>
              <div className='Suggested-job-list d-flex flex-wrap gap-5'>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Web Designer </span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Front End Developer</span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Senior Business Developer Manager</span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Accounting</span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '> BPO </span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Java Developer </span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Laravel Developer </span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Full Stack Developer </span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>UI Designer </span>
                <span className='fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 '>Content Writer </span>
              </div>
            </div>
      </div>
    </>
  )
}

export default SuggestedJobSearches
