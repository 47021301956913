import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./JobPost.css";
import {FaRegEdit} from 'react-icons/fa';
import {BsFillQuestionSquareFill} from 'react-icons/bs';


const JobPost = () => {
  useEffect(() => {
    document.body.classList.add("job-post-bg"); // Add the class to the body element
    return () => {
      document.body.classList.remove("job-post-bg"); // Remove the class when the component is unmounted
    };
  });
  return (
    <>
      <section className="pt-4 pb-4">
        <div className="container-fluid side-space">
          <div className="m-auto">
            <div className="row justify-content-center ">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12">
                <div className="job-post  border-radius-8">
                  <div className="job-heading pb-3">
                    <h3>Post a job now</h3>
                    <p>
                      <sub>Rated #1 in increasing quality of hire.</sub>1
                    </p>
                  </div>
                  <div className="job-role-form form-container">
                  <form>
                    <div className="form-group">
                      <label htmlFor="title">
                        Job Title
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add the title you are hiring for"
                        id="title"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="company">
                        Company
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name "
                        id="company
            le"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="workpalce-type">
                        Workplace Type<span className="text-danger">*</span>
                      </label>
                      <select className="form-control">
                        <option>On Site</option>
                        <option>Hybrid</option>
                        <option>Remote</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="location">
                        Job location <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add the title you are hiring for"
                        id="title
            le"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="job-type">
                        Job Type
                        <span className="text-danger">*</span>
                      </label>
                      <select className="form-control">
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Contarct</option>
                        <option>Temporary</option>
                        <option>Other</option>
                      </select>
                    </div>

                    <div className="text-center mt-4">
                      <Link
                       to="/job-post-summary"
                        className="btn blue-btn pl-5 pr-5 fa-20 fw-500"
                      >
                        Get Started
                      </Link>
                    </div>
                  </form>
                 </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div className="job-post  border-radius-8 mt-lg-0 mt-3">
                  <div className="pb-3">
                    <Link to="#" className="btn btn-blue-outline w-100 fw-600 py-2">Post a free job <FaRegEdit/></Link>
                    <p className="mt-4 para-text mb-2">
                     Save up to 35% by purchasing job posting budget in advance. 
                    </p>
                    <Link className="link-text fa-18x ">Get discount</Link>
                  </div>
                </div>
                <div className="help-blok mt-3">
                    <p className="para-text"><BsFillQuestionSquareFill/> Questions? <span className="ml-2"><Link to="#" className="link-text">Contact Support</Link></span> <span className="mx-2">|</span> <span> <Link to="#" className="link-text">Help Center </Link></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobPost;
