import React from 'react';

import TargetCross from '../../../../assets/target-cross.png';
import Calendar from '../../../../assets/calendar.png';
import Dollar from '../../../../assets/dollar.png';
import EditTargetGoal from '../Dashboard/EditTargetGoal';
import { AiOutlineEdit } from 'react-icons/ai';


  const goalItems = [
    {
      icon: TargetCross,
      label: 'Target Title',
      value:'Front End Developer',
    },
    {
      icon: Calendar,
      label: 'Target Date',
      value: 'Not Set',
    },
    {
      icon: Dollar,
      label: 'Target Salary Range',
      value: '$30,000 to $50,000',
    },
  ];


const TargetGoal = () => {
  return (
    <>
      <div className='summary-module-wrapper mb-3'>
        <div className='card'>
          <div className='card-header border-bottom-0 bg-transparent d-flex align-items-lg-start'>
            <h3 className='card-title'>Next Career Goal: Land a New Job</h3>  <EditTargetGoal icon={<AiOutlineEdit className='gray fa-24 ml-3'/>}/>
          </div>
          <div className='card-body'>
            <div className='row m-0'>
              {goalItems.map((item, index) => (
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 p-0' key={index}>
                  <div className='goal-item d-flex align-items-center align-custom-items-start'>
                    <div className='goal-item-icon'>
                      <img src={item.icon} className='img-fluid' alt={item.label} />
                    </div>
                    <div className='goal-item-content ml-2'>
                      <p className='goal-item-label'>{item.label}</p>
                      <h4 className='goal-item-value'>{item.value}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TargetGoal;

