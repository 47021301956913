import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/NavComponent";
import Footer from "./Footer/FooterComponent";

const Web = () => {
  return (
    <>
      {/* Header component */}
      <Header />

      {/*End header component */}

      {/* Main content */}
      <Outlet />
      {/* End main content */}

      {/* Footer component */}
      <Footer />
      {/* End footer component */}
    </>
  );
};

export default Web;
