import React from 'react';

const OurPrincipleCard = (props) => {
    const { title, imageSrc, description } = props;
  
    return (
      <div className='m-1'>
        <div className='our-principle-card flex-fill'>
          <h4>{title}</h4>
          <div className='our-principle-card-img'>
            <img src={imageSrc} className='img-fluid' alt={title} />
          </div>
          <p>{description}</p>
        </div>
      </div>
    );
  };

export default OurPrincipleCard
