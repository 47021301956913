import React, { useRef, useState } from "react";
import ProfileImg from "../../../../assets/nilotapl.jpg";
import { Link } from "react-router-dom";
import DefaultImage from "../.../../../../../assets/default_Image.png";

const AddProfilePhoto = () => {
  const [showAddProfilePhoto, setShowAddProfilePhoto] = useState(false);
  const handleShowAddProfilePhotoOpen = () => {
    setShowAddProfilePhoto(true);
  };

  const handleShowAddProfilePhotoClose = () => {
    setShowAddProfilePhoto(false);
  };

  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadButtonText, setUploadButtonText] = useState("Upload profile");

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUploadButtonText("Change Profile");
  };

  return (
    <>
      <Link onClick={handleShowAddProfilePhotoOpen}>
        <img src={ProfileImg} className="img-fluid" alt="profile" />
      </Link>

      {showAddProfilePhoto && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Profile photo</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleShowAddProfilePhotoClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                  <div className="form-container">
                    {selectedImage && (
                      <div className="selected-image text-center">
                        <h3 className="para-text gray-text text-center mb-3 fw-400 fa-24">
                          Profile photo uploaded
                        </h3>
                        <img
                          src={selectedImage}
                          alt="Preview"
                          className="img-fluid"
                        />
                      </div>
                    )}

                    {!selectedImage && (
                      <div className="banner-box text-center">
                        <h3 className="para-text gray-text text-center mb-3 fw-400 fa-24 fa-mdd-20 fa-sm-16 ">
                          No professional headshot needed! Just something that
                          represents you.
                        </h3>
                        <img
                          src={DefaultImage}
                          className="img-fluid w-100"
                          alt="banner"
                        />
                      </div>
                    )}

                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="modal-footer  text-right">
                  {selectedImage && (
                    <button  type="submit" className="btn btn-blue-outline mr-2 py-2" >
                      Save
                    </button>
                  )}
                  <Link
                    className="btn blue-btn ml-2"
                    onClick={handleButtonClick}
                  >
                    {uploadButtonText}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProfilePhoto;
