import React from 'react';
import ApplyJobsCard from './ApplyJobsCard';
import { BiNotepad } from 'react-icons/bi';

const RecentJobsPost = ({ title }) => {
  const jobs = [
    {
      title: title,
      items: [
        {
          jobTitle: 'Embedded Software Developer - Contract',
          location: 'UCLA Health, Hampshire, England',
          icon: <BiNotepad />,
          status: 'Be an early applicant',
          time: '1 Hour Ago',
        },
        {
          jobTitle: 'Embedded Software Developer',
          location: 'UCLA Health, Hampshire, England',
          icon: <BiNotepad />,
          status: 'Be an early applicant',
          time: '1 Hour Ago',
        },
        {
          jobTitle: 'Embedded Software Developer - Contract',
          location: 'UCLA Health, Hampshire, England',
          icon: <BiNotepad />,
          status: 'Be an early applicant',
          time: '1 Hour Ago',
        },
      ],
    },
  ];

  return (
    <>
      <ApplyJobsCard jobs={jobs} boxspace='mb-4' />
    </>
  );
};

export default RecentJobsPost;
