import React from "react";
import { Link } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";

const JobCardList = () => {
  return (
    <>
    <div style={{ margin: "0 10px" }}>
      <div className="job-card-list mb-6 mb-small-3">
        <h3>Embedded Software Developer - Contract</h3>

        <p>Berkshire, England,London (Central), England, Hampshire, England</p>

        <h4 className="pt-3">Contract</h4>
        <p>£55-£60 per hour plus hybrid working</p>

        <div className="linkbtn pt-3">
          <Link to="#">
            View All <ImArrowUpRight2 className="ml-3 arrow-icon" />
          </Link>
        </div>
      </div>
      </div>
    </>
  );
};

export default JobCardList;
