import React, {useState } from "react";
import UserImg from "../../../assets/user-img.jpg";
import { Link } from "react-router-dom";
import "../UserPanel/Profile/ProfileModal/Modal.css";
import {RiSuitcaseFill} from 'react-icons/ri'

const PreviewJobPost = () => {
  const [showPreviewJobPost, setShowPreviewJobPost] = useState(false);
  const handleShowPreviewJobPostOpen = () => {
    setShowPreviewJobPost(true);
  };

  const handleShowPreviewJobPostClose = () => {
    setShowPreviewJobPost(false);
  };




  return (
    <>
      <Link to="#" onClick={handleShowPreviewJobPostOpen} className="btn btn-blue-outline py-2 mr-2 w-sm-100">
        Preview
     </Link>

      {showPreviewJobPost && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Previe Job Post</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleShowPreviewJobPostClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                      <div className="custom-scrollbar">
                         <p className="fa-16 fa-sm-14 gary-text fw-400 text-left">This is a preview of what your job post will look like to job seekers, which includes details about your job such as company size and location, as well as information around when you created your LinkedIn profile. Candidates will answer screening questions when they apply.

</p>
                        <div className="job-description">
                            <h3 className="fw-500 fa-20  fa-sm-18">Tester</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray"> Designdot Technologies Pvt Ltd  Noida, Uttar Pradesh, India Remote</p>
                            <p className="fw-400 fa-16 fa-sm-14 gray mt-3"><RiSuitcaseFill className="black-shades-1 fa-24 mr-2"/>Temporary</p>
                           
                            <div className="apply-btn">
                               <Link className="btn btn-blue-outline mr-2">Easy Apply</Link> <Link className="btn blue-btn py-2">Save</Link>
                            </div>

                            <div className="Posted-by d-flex mt-4 mb-4">
                              <div className="hiring-profile img mr-2">
                                <img src={UserImg} alt="profile "/>
                              </div>
                              <div className="Posted-profile-detail">
                                  <h4 className="fa-18 black-shades-1 fw-500 mb-0">Shakti Yadav</h4>
                                  <p className="fw-400 fa-16 fa-sm-14 gray mb-0">Web Designer at DesignDot Technologies Pvt Ltd</p>
                              </div>
                            </div>

                            <p className="fw-400 fa-16 fa-sm-14 gray">Tips: Provide a summary of the role, what success in the position looks like, and how this role fits into the organization overall.</p>

                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Responsibilities</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">[Be specific when describing each of the responsibilities. Use gender-neutral, inclusive language.]</p>
                            <p className="fw-400 fa-16 fa-sm-14 gray">Example: Determine and develop user requirements for systems in production, to ensure maximum usability</p>

                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Qualifications</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">[Some qualifications you may want to include are Skills, Education, Experience, or Certifications.]</p>
                            <p className="fw-400 fa-16 fa-sm-14 gray">Example: Excellent verbal and written communication skills</p>

                        </div>
                      </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewJobPost;
