import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import Icon from "../../../../assets/icon.png";
import JobApply from "./JobApply";

const jobBasicDetail = () => {

  return (
    <>
      
        <div className="job-card p-0 border-0">
          <div className="job-card-icon">
            <img src={Icon} className="img-fluid cover-full" alt="job icon" style={{width:'55px'}} />
          </div>
          <div className="job-card-content">
            <h4 className="light-black fa-20 fa-mdd-16 sa-smm-14 ">Embedded Software  Developer - Contract</h4>
            <p className="light-black fa-18 fa-mdd-16 fa-smm-14">Technical Director | UCLA Health
Complete Address, Hampshire, England</p>
            <div className="job-content-inner d-flex align-items-center mt-2 justify-content-between">
              <p className="fa-14">1 hour ago  <AiOutlineClockCircle className="black fa-16"/>   Be among the first 25 applicants</p>
            </div>
          </div>
        </div>
     

      <div className="apply-btn paddingleft-6 mt-4">
        {/* apply now btn*/}
          <JobApply/>
        {/* apply now btn*/}

        <Link className="btn btn-gary fw-500 fa-16 py-2"> Save </Link>
      </div>
    </>
  );
};

export default jobBasicDetail;
