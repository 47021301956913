import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Modal.css";

const AwardsRecognition = ({ linkText, icon, className }) => {
  const [showAwardsRecognition, setShowAwardsRecognition] = useState(false);
  const [skillCount, setAwardsRecognitionCount] = useState(1);

  const handleAwardsRecognitionsOpen = () => {
    setShowAwardsRecognition(true);
  };

  const handleAwardsRecognitionsClose = () => {
    setShowAwardsRecognition(false);
  };

  const handleAddMoreAwardsRecognitions = () => {
    setAwardsRecognitionCount(skillCount + 1);
  };

  return (
    <>
      <Link to="" className={`add-new-label ${className}`} onClick={handleAwardsRecognitionsOpen}>
        {icon} {linkText}
      </Link>

      {showAwardsRecognition && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Awards & Recognition</h4>
                <button type="button" className="close" onClick={handleAwardsRecognitionsClose}>
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  {Array.from({ length: skillCount }).map((_, index) => (
                    <div className="form-container border-bottom-2 mb-3" key={index}>
                      <div className="form-group">
                        <label htmlFor={`project_title_${index}`}> Title <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Title" id={`project_title_${index}`} />
                      </div>

                      <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-0 mr-sm-3">
                        <label for="start_date">
                          From (Year )
                        </label>
                        <select className="form-control w-100" id="start_date">
                          <option>Please Select</option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                          <option>2020</option>
                          <option>2019</option>
                          <option>2018</option>
                        </select>
                      </div>

                      <div class="form-group flex-fill ml-0 ml-sm-3">
                        <label for="location">To (Year)</label>
                        <select class="form-control w-100" id="location">
                          <option>Please Select </option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>
                    </div>
                  ))}

                  <div className='add-more-group my-3'>
                     <button type="button" className="btn btn-blue-outline " onClick={handleAddMoreAwardsRecognitions}>Add More</button>
                  </div>

                </div>
                <div className="modal-footer text-right">
                  <button type="submit" className="btn blue-btn">Submit</button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AwardsRecognition;
