import React from 'react';
import './WorkSkills.css';

const WorkSkills = () => {
  const skills = ['NODE JS', 'HTML' , 'CSS3', 'Bootstrap', 'JavaScript', 'React'];

  return (
    <>
      <div className="d-flex flex-wrap gap-5">
        {skills.map((skill, index) => (
          <span key={index} className="skill-style">
            {skill}
          </span>
        ))}
      </div>
    </>
  );
};

export default WorkSkills;
