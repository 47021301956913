import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import InputTag from "../../CommonComponents/InputTag";
import PreviewJobPost from "./PreviewJobPost";

const JobPostSummary = () => {
    useEffect(() => {
        document.body.classList.add("job-post-bg"); // Add the class to the body element
        return () => {
          document.body.classList.remove("job-post-bg"); // Remove the class when the component is unmounted
        };
      });

  return (
    <>
      <section className="pt-4 pb-75">
        <div className="container-fluid side-space">
          <div className="job-post-summary col-xl-8 col-lg-10 col-md-12 col-sm-12 m-auto p-0">
            <div className="card">
              <div className="card-header bg-transparent">
                <h3 className="card-title">Tell us about the role</h3>
              </div>
              <div className="card-body">
                <div className="job-role-form form-container">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        >
                        About the company 
                      </label>
                      <textarea className="form-control"></textarea>
                    </div>


                    <div className="form-group">
                      <label
                        htmlFor="description"
                        >
                       Role and responsibility
                      </label>
                      <textarea className="form-control"></textarea>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="description"
                        >
                       Requirement
                      </label>
                      <textarea className="form-control"></textarea>
                    </div>

                    
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        
                      >
                        Skills
                      </label>
                      <p className="para-text">
                        Add skill keywords to make your job more visible to the
                        right candidates.
                      </p>
                      <InputTag title="Add Skill" />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="experiance">
                        Experiance
                      </label>
                      <input className="form-control" placeholder="Experiance" id="experiance"/>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="salary">
                        Salary
                      </label>
                      <input className="form-control" placeholder="Salary" id="salary"/>
                    </div>


                    <div className="form-group">
                      <label htmlFor="description" >
                        How did you hear about LinkedIn jobs?
                      </label>
                      <select className="form-control">
                        <option>In the mail</option>
                        <option>Podcast</option>
                        <option>Ad on LinkedIn.com</option>
                        <option>Online ad / Search engine</option>
                        <option>Streaming Audio (Spotify/Pandora/iHeart/Deezer)</option>
                        <option>Other</option>
                      </select>
                    </div>
                    <hr/>
                    <div className="d-sm-flex d-block justify-content-between ">
                      <div className="text-left mb-2 mb-sm-0">
                        <PreviewJobPost/>
                      </div>

                      <div className="text-right">
                        <Link
                          to="/job-post"
                          className="btn btn-outline-secondary border-radius-50 px-4 w-sm-100 py-2 border-width fw-500 mr-2 mb-2 mb-sm-0"
                        >
                          Back
                        </Link>
                        <Link to="/qualified-applicants" className="btn blue-btn ml-sm-2 ml-0 fw-500 w-sm-100">
                          Continue
                        </Link>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobPostSummary;
