import React from 'react'

const ViewScreeningquestion = () => {
  return (
    <>
      <div className='card mt-3'>
        <div className='card-header bg-transparent border-bottom-0'>
           <h3 className='card-title fw-500 light-black'>Screening question </h3>
        </div>
        <div className='card-body pt-0'>
          <h3 className='fw-600 fa-20 light-black fa-md-18'>Must-have qualifications </h3>

          <div className='post-job-info mb-3'>
            <h4>What is your level of proficiency in English?</h4>
            <p>Ideal answer: Conversational</p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>How many years of IT Services and IT Consulting experience do you currently have?</h4>
            <p>Ideal answer: 1</p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>What is your CTC?</h4>
            <p>Ideal answer: 1 </p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>What is your CTC?</h4>
            <p>Ideal answer: 1 </p>
          </div>

          <h3 className='fw-600 fa-20 light-black'>Preferred qualifications </h3>

          <div className='post-job-info mb-3'>
            <h4>What is your average deal size in USD (in thousands)?</h4>
            <p>Ideal answer: 1000 </p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>Have you completed the following level of education: Master of Business Administration?</h4>
            <p>Ideal answer: Yes </p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>Are you comfortable working in a remote setting? </h4>
            <p>Ideal answer: Yes</p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>What is your desired salary? </h4>
            <p>Ideal answer: 1</p>
          </div>

          <div className='post-job-info mb-3'>
            <h4>Are you willing to undergo a background check, in accordance with local law/regulations?  </h4>
            <p>Ideal answer: Yes</p>
          </div>

        </div>
      </div>
    </>
  )
}

export default ViewScreeningquestion
