import React from 'react';
import ApplyJobsCard from '../../../CommonComponents/ApplyJobsCard';
import './RecommendedJobs.css';
import { BiNotepad } from 'react-icons/bi';

const RecommendedJobs = () => {

  const RecommendedJobs = [
    {
        title: ' Recommended for you',
        subtitle: 'Based on your profile and search history',
        items: [
          {
            jobTitle: 'Embedded Software Developer - Contract',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant',
            time: '1 Hour Ago',
          },
          {
            jobTitle: 'Embedded Software Developer ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },
          {
            jobTitle: 'Embedded Software Developer - Contract ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },
          {
            jobTitle: 'Embedded Software Developer - Contract ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },
          {
            jobTitle: 'Embedded Software Developer - Contract ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },
          {
            jobTitle: 'Embedded Software Developer - Contract ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },
          {
            jobTitle: 'Embedded Software Developer - Contract ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },

          {
            jobTitle: 'Embedded Software Developer - Contract ',
            location: 'UCLA Health, Hampshire, England',
            icon: <BiNotepad />,
            status: 'Be an early applicant ',
            time: '1 Hour Ago ',
          },
        ],
      },
  ];

  const jobgride = "job-grid";
  const jobcardbg = "job-card-gredient";
  
  return (
    <>
            <div className='card'>
                <div className='card-body'>
                  <div className='recommended-job'>
                   <ApplyJobsCard jobs={RecommendedJobs} className={jobgride} classNameCard={jobcardbg} Classtitle="fa-20"/>
                  </div>
                </div>
            </div>
    </>
  )
}

export default RecommendedJobs
