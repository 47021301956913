import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({className, id}) => {
  return (
   
    <div className='on-off'>
      <div className={`custom-control custom-switch ${className}`}>
        <input type="checkbox" className="custom-control-input" id={`switch${id}`} />
        <label className="custom-control-label" htmlFor={`switch${id}`}>
        </label>
      </div> 
    </div>
   );
};

export default ToggleSwitch;
