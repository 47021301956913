import React from "react";
import { Link } from "react-router-dom";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import Comapnybanner from "../../../../assets/comapnybanner.jpg";
import RecentJobsPost from "../../../CommonComponents/RecentJobsPostData";
import officeimg from '../../../../assets/office-building.png';
import {LuExternalLink} from 'react-icons/lu';
import {FaDownload} from 'react-icons/fa';
import { AiOutlineEdit } from "react-icons/ai";

const ComapnyProfileView = () => {
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            <div className="company-content grid-middle mt-smm-0 mt-3">
              <div className="card border-0">
                {/*profile  banner */}
                <div className="profile-hero-banner position-relative">
                  <div className="profile-hero-banner-image profile-background-image--default">
                    <img
                      src={Comapnybanner}
                      className="img-fluid full-height full-width"
                      alt="Profile banner"
                    />
                  </div>
                </div>

                {/*end profile  banner */}

                {/* profile  info */}
                <div className="profile-card-photo-deatils position-relative">
                  <div className="profile-card-photo-wrapper">
                    <div className="profile-card-photo-edit">
                      <img  src={officeimg}  className="img-fluid"  alt="profile"
                      />
                    </div>
                  </div>

                  <div className="ptofile-card-photo-content mt-3">
                    <div className="ptofile-card-photo-content-wrapper d-xl-flex d-block justify-content-between">
                      <div className="profile-card-info">
                        <h4>DesignDot Technologies Pvt. Ltd.  <Link to="/company-edit"><AiOutlineEdit className="ml-2 fa-20 gray"/></Link></h4> 
                        <p>
                         
                          <small className="profile-role-text">
                            IT & Consultancy
                          </small>
                        </p>
                        <p>Dwarka, Delhi, India </p>
                      
                      </div>

                       <div className="visit-website mt-2 mt-xl-0">
                            <Link to="#" className="btn blue-btn py-2">Vist Website <LuExternalLink/></Link>
                        </div>
                    </div>
                    <hr/>

                    <div className="overview">
                      <div className="overview-card">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0">Overview</h2>
                        </div>

                        <div className="profile-summary-body mt-2">
                            <p>DesignDot has emerged as a one-stop Design Development Technology services and solutions provider for many national and international corporate brands. We also work with Micro Small Medium Enterprise companies and cater to their requirements with customised budgets</p>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">website</h2>
                        </div>
                        <div className="profile-summary-body mt-1">
                          <Link to="http://designdot.in/" target="_blank" className="link-text fa-16">http://designdot.in/</Link>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Phone</h2>
                        </div>
                        <div className="profile-summary-body mt-1">
                          <Link to="tel:8375945489" target="_blank" className="link-text fa-16">+91.8375945489</Link>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Industry</h2>
                        </div>
                        <div className="profile-summary-body mt-1">
                          <p>Information Technology & Services</p>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Company size
</h2>
                        </div>
                      
                        <div className="profile-summary-body mt-1">
                          <p>11-50 employees</p>
                          <p>1 on LinkedIn</p>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Company Type
</h2>
                        </div>
                      
                        <div className="profile-summary-body mt-1">
                          <p>Privately held</p>
                        </div>
                       </div>
                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Headquarters</h2>
                        </div>
                        <div className="profile-summary-body mt-1">
                          <p>NEW DELHI, Delhi</p>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Founded</h2>
                        </div>
                        <div className="profile-summary-body mt-1">
                          <p>2003</p>
                        </div>
                       </div>

                       <div className="overview-card mt-3">
                        <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Doccument</h2>
                        </div>
                        <div className="profile-summary-body mt-1 ">
                          <p>  <Link to="" className="link-text  p-1"><span className="gray">file-01.pdf</span> <FaDownload className="fa-20"/> </Link></p>
                        </div>
                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right-side grid-right">
                <RecentJobsPost title="Recent Post Job"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComapnyProfileView;
