import React from 'react';
import officeimg from '../../../../assets/office-building.png';
import {Link} from 'react-router-dom';



const Companyview = () => {
  return (
    <>
      <div className='company-view card bg-light-gray-shades-3'>
        <div className='card-body'>
            <div className='company-icon'>
             
                <img src={officeimg} className="img-fluid" alt="office img"/>
            </div>
            <div className='company-info mt-3'>
                <h3 className='mb-0'>DesignDot Technologies Pvt. Ltd. </h3>
                <p>IT & Consultancy</p>

                <div className='mt-2'>
                   <Link to="/company" className="btn blue-btn py-2 mr-2">
                     Preview Info 
                  </Link>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Companyview
