import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputTag from "../../../../CommonComponents/InputTag";
import "./Modal.css";

const AddNewExperience = ({ linkText, icon, className }) => {
  // add media Dropdown
  const [showNewPosition, setShowNewPosition] = useState(false); //for popup Modal

  // function for  position

  const handleNewPositionOpen = () => {
    setShowNewPosition(true);
  };

  const handleNewPositionClose = () => {
    setShowNewPosition(false);
  };

  return (
    <>
      <Link
        to=""
        className={`gray ${className}`}
        onClick={handleNewPositionOpen}
      >
        {icon} {linkText}
      </Link>

      {showNewPosition && (
        <div className="modal " style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add experience</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleNewPositionClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="form-container">
                  <div className="modal-body custom-scrollbar">
                    <div className="form-group">
                      <label htmlFor="title ">
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: Front End Developer "
                        id="Title"
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="employment">Employment type</label>
                      <select class="form-control" id="employment">
                        <option>Please select </option>
                        <option>Full-time </option>
                        <option>Part-time</option>
                        <option>Self-employed</option>
                        <option>Freelance</option>
                        <option>Internship</option>
                        <option>Trainee</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="company">
                        Company name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: Microsoft"
                        id="company"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="location">Location </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: London, United Kingdom"
                        id="location"
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="location">Location type </label>
                      <select class="form-control" id="location">
                        <option>Please Select </option>
                        <option>On-site</option>
                        <option>Hybrid</option>
                        <option>Remote</option>
                      </select>
                    </div>

                    <div className="form-group pt-2 pb-2">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck"
                          name="work_role"
                        />
                        <label
                          class="custom-control-label pl-3 -sm-2 pt-0"
                          htmlFor="customCheck"
                        >
                          I am currently working in this role
                        </label>
                      </div>
                    </div>

                    <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-sm-3 mr-0">
                        <label htmlFor="start_date">
                          Start  <span className="text-danger">*</span>
                        </label>
                        <select className="form-control w-100" id="start_date">
                          <option>Please Select</option>
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>

                      <div class="form-group flex-fill  ml-sm-3 ml-0">
                        <label htmlFor="location" className="hide">&nbsp;</label>
                        <select class="form-control w-100" id="location">
                          <option>Please Select </option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>

                    <div className="d-sm-flex">
                      <div class="form-group flex-fill  mr-sm-3 mr-0">
                        <label htmlFor="start_date">
                          End  <span className="text-danger">*</span>
                        </label>
                        <select className="form-control w-100" id="start_date">
                          <option>Please Select</option>
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>

                      <div class="form-group flex-fill ml-0 ml-sm-3">
                        <label htmlFor="location" className="hide">&nbsp;</label>
                        <select class="form-control w-100" id="location">
                          <option>Please Select </option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group pt-sm-2 pt-0 pb-2">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck"
                          name="work_role"
                        />
                        <label
                          class="custom-control-label pl-3 pt-sm-2 pt-0"
                          htmlFor="customCheck"
                        >
                          End current position as of now - Web Designer at
                          Designdot Technologies Pvt Ltd
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="industry">
                        Industry <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Information Technology & Services"
                        id="industry"
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="Description">Description:</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        id="Description"
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <label htmlFor="profile_headline">
                        Profile headline <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Web Designer at DesignDot Technologies Pvt Ltd"
                        id="profile_headline
  "
                      />
                      <p className="small-text pt-3">
                        Appears below your name at the top of the profile
                      </p>
                    </div>

                    <h4 className="form-title pt-2 pb-2">Skill </h4>
                    <p className="small-text">
                      We recommend adding your top 5 used in this role. They’ll
                      also appear in your Skills section.
                    </p>

                    {/* InputTag component */}

                    <InputTag title="Add Skill" />

                    {/* end InputTag component */}
                  </div>
                  <div className="modal-footer  text-right">
                    <button type="submit" className="btn blue-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewExperience;
