import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JobListTitle from './JobListTitle';
import './JobList.css';
import JobCardList from './JobCardList';



const JobList = () => {

    const SliderJobList = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
       
        responsive: [
            {
                breakpoint: 1299,
                settings: {
                  slidesToShow: 3,
                  autoplay: true,         
                  autoplaySpeed: 3000, 
                },
            },

            {
                breakpoint: 770,
                settings: {
                  slidesToShow: 2,
                  autoplay: true,         
                  autoplaySpeed: 3000, 
                },
            },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              autoplay: true,         
              autoplaySpeed: 3000, 
            },
          },
        ],
      };
    

  return (
    <>
    
        <div className='container-fluid side-space'>
            <div className='row'>
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-4'>
                    <JobListTitle number="1156" title="live jobs" subpara="Here are our latest >"/>
                   
                </div>

                <div className='col-xl-9 col-lg-9 col-md-8 col-sm-8 p-0'>
                   <div className='' >
                      <Slider {...SliderJobList}>
                        <JobCardList/>
                        <JobCardList/>
                        <JobCardList/>
                        <JobCardList/>
                    </Slider>
                   </div>

                </div>
            </div>

            <div className='row'>
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-4'>
                    <JobListTitle number="10K" title="active" subpara="Candidates"/>
                </div>

                <div className='col-xl-9 col-lg-9 col-md-8 col-sm-8 p-0'>
                   <div className='' >
                   <Slider {...SliderJobList}>
                        <JobCardList/>
                        <JobCardList/>
                        <JobCardList/>
                        <JobCardList/>
                    </Slider>
                   </div>

                </div>
            </div>
        </div>
  
      
    </>
  )
}

export default JobList
