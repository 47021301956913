import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { BiChevronDown ,BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import Education from "../../../CommonComponents/Education";


const JobTrackerTable = () => {
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});
  const [editableFields, setEditableFields] = useState({
    workplace: { value: "Remote", isEditing: false },
    status: { value: "Draft", isEditing: false },
    jobtype: { value: "Full Time", isEditing: false },
    maxSalary: { value: "$34560.00", isEditing: false },
    location: { value: "Bangalore, India", isEditing: false },
  });

  const handleItemMouseEnter = (item, event) => {
    setSelectedItem(item);
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const position = {
      top: rect.top + rect.height + scrollTop + 10, // Adjust this value
      left: rect.left + scrollLeft,
    };

    // Calculate horizontal center of the clicked element
    const centerX = rect.left + rect.width / 2;
    const boxWidth = '1000';
    const boxLeft = centerX - boxWidth / 2;

    setBoxPosition({
      ...position,
      left: boxLeft,
    });

    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };

  const handleFieldClick = (fieldName) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        isEditing: true,
      },
    }));
  };

  const handleFieldChange = (fieldName, e) => {
    const { value } = e.target;
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        value: value,
      },
    }));
  };

  const handleFieldBlur = (fieldName) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        isEditing: false,
      },
    }));
  };

  
 
  const workplace = ["Remote", "On-sight", "Hybrid"];
  const status = ["Draft", "Ongoing", "Paused", "Closed"];
  const jobtype = ["Full Time", "Part Time", "Contarct", "Temporary", "Other"];

  return (
    <>
    <div className="table-container">
      <div className="responsive-table ">
        <table className="table  mb-0">
          <thead>
            <tr>
              <th>
                Date <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Title <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Education <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Skill <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Workplace
                <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>

              <th>
                Job Type <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>

              <th>
                Location <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
             
              <th>
                Max Sal <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Status <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Spent <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Applicant
                <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th className="text-right">
                <CiSliderVertical className="fa-20 black fw-700" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="j-gray-bg align-items-center border-18">
              <td>
                21-08-2023
              </td>

              <td style={{ width: "150px" }}>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("Company Name", e)}
                  onClick={() => handleItemClick("Company Name")}
                  className={selectedItem === "Company Name" ? "active" : ""}
                >
                  <p className="fa-14 fw-600 light-black mb-0">Acme Corp</p>
                  <p className="fa-12 fw-400 gray">
                    Product Designer - Sample Job
                  </p>
                </Link>
              </td>

              <td className="remotedata">
              <Link
                  onMouseEnter={(e) => handleItemMouseEnter("education", e)}
                  onClick={(event) => handleItemClick("education")}
                  className={`gray ${
                    selectedItem === "education" ? "active" : ""
                  }`}
                >
                  Education
                </Link>
              </td>

              <td>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("skill", e)}
                  onClick={(event) => handleItemClick("skill")}
                  className={`gray ${
                    selectedItem === "skill" ? "active" : ""
                  }`}
                >
                  Skill
                </Link>
              </td>
              <td className="remotedata">
                {editableFields.workplace.isEditing ? (
                  <select
                    value={editableFields.workplace.value}
                    onChange={(e) => handleFieldChange("workplace", e)}
                    onBlur={() => handleFieldBlur("workplace")}
                    className="form-control fa-14"
                  >
                    {workplace.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span onClick={() => handleFieldClick("workplace")}>
                    {editableFields.workplace.value}
                    <BiChevronDown className="ml-1 fa-20 light-black" />
                  </span>
                )}
              </td>

              <td className="remotedata">
                {editableFields.jobtype.isEditing ? (
                  <select
                    value={editableFields.jobtype.value}
                    onChange={(e) => handleFieldChange("jobtype", e)}
                    onBlur={() => handleFieldBlur("jobtype")}
                    className="form-control fa-14"
                  >
                    {jobtype.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span onClick={() => handleFieldClick("jobtype")}>
                    {editableFields.jobtype.value}
                    <BiChevronDown className="ml-1 fa-20 light-black" />
                  </span>
                )}
              </td>

              <td>Bangalore, India</td>
              <td>
                {editableFields.maxSalary.isEditing ? (
                  <input
                    type="text"
                    value={editableFields.maxSalary.value}
                    onChange={(e) => handleFieldChange("maxSalary", e)}
                    onBlur={() => handleFieldBlur("maxSalary")}
                    className="form-control fa-14"
                  />
                ) : (
                  <span onClick={() => handleFieldClick("maxSalary")}>
                    {editableFields.maxSalary.value}
                    <BiEdit className="ml-1 fa-18 gray" />
                  </span>
                )}
              </td>
              <td>
                {editableFields.status.isEditing ? (
                  <select
                    value={editableFields.status.value}
                    onChange={(e) => handleFieldChange("status", e)}
                    onBlur={() => handleFieldBlur("status")}
                    className="form-control fa-14"
                  >
                    {status.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span onClick={() => handleFieldClick("status")}>
                    {editableFields.status.value}
                    <BiChevronDown className="ml-1 fa-20 light-black" />
                  </span>
                )}
              </td>
              <td>Rs. 250</td>
              <td>50 Applied</td>
              <td>
                <div className="dropdown dropright float-right">
                  <button
                    type="button"
                    className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                    data-toggle="dropdown"
                  >
                    <BsThreeDots className="black fa-26" />
                  </button>
                  <div className="dropdown-menu transition-0 j-gray-bg mb-0">
                    <Link to="/job-view" className="dropdown-item fa-14 light-black fw-400">
                      Manage Job
                    </Link>
                    <Link className="dropdown-item fa-14 light-black fw-400">
                      Repost Job
                    </Link>
                    
                    <Link to="/view-job-post" className="dropdown-item fa-14 light-black fw-400">
                      View Job Post
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
      <div className="pagination-row float-right mt-3">
        <Pagination />
      </div>

     <div className="position-relative ">
     {showBox && (
       
       <div
         className={`box ${showBox ? "slide-down" : "slide-up"}`}
         style={{
           top: boxPosition.top,
           left: boxPosition.left,
         }}
       >
         {selectedItem === "Company Name" && (
           <div className="box-info">
             <p className="fa-14 gray fw-400 line-height-24">
               As a Marketing Professional my accentuation is on conversations,
               for which it is fundamental that we know our get-together of
               individuals well or potentially have the preparation to get some
               answers concerning them Pieces of information aren’t actually
               what examination are uncovering to us how ....
             </p>

             <Link to="/company" className="btn blue-btn mt-3">
               View Profile
             </Link>
           </div>
         )}

         {selectedItem === "skill" && (
           <div className="work-experience">
             <WorkSkills/>
           </div>
         )}

         {selectedItem === "education" && (
           <div className="work-experience">
             <Education/>
           </div>
         )}
         <button className="box-close" onClick={handleBoxClose}>
           X
         </button>
       </div>
     
     )}

     </div>
      
     
    </>
  );
};

export default JobTrackerTable;
