import React from 'react';
import OurPrincipleCard from './OurPrincipleCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './OurPrinciples.css';
import Knowledgable from '../../../../assets/knowledge.png';
import Honest from '../../../../assets/honest.png';
import Specialist from '../../../../assets/specialist.png';

const OurPrinciples = () => {

  const sliderPrinciple = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          autoplay: true,         
          autoplaySpeed: 3000, 
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,         
          autoplaySpeed: 3000, 
        },
      },
    ],
  };

  return (
    <>
      
          <div className='container-fluid side-space'>
            <div className='row'>
                <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                    <div className='our-principle-title'>
                        <h3>Our Principles</h3>
                        <p className='mt-3'>We are a very committed team that takes great satisfaction in the results we
                             consistently produce for our clients and prospects.</p>
                          <p>To make sure we are setting the bar for candidate and client engagement, our principles are crucial.</p>
                    </div>
                </div>

                <div className='col-xl-9 col-lg-9 col-md-12 col-sm-12 psmall-0'>
                    <div className='mleft-lg-5'>
                    <Slider {...sliderPrinciple}>
                       <OurPrincipleCard
                        title="Knowledgable"
                        imageSrc={Knowledgable}
                        description="We do as we say, we give."
                        />

                         <OurPrincipleCard
                        title="Specialists"
                        imageSrc={Specialist}
                        description="We adapt to your requirements, 
                        we go beyond."
                        />

                         <OurPrincipleCard
                        title="Honest"
                        imageSrc={Honest}
                        description="We are a dependable and 
                        trustworthy partner."
                        />
                      </Slider>
                    </div>
                </div>
            </div>
            
          </div>
        
    </>
  )
}

export default OurPrinciples
