import React from "react";
import HeroContent from "./HeroSection/HeroContent";
import JobList from "./JobList/JobList";
import OurPrinciples from "./OurPrinciples/OurPrinciples";
import WhoAreLooking from "./WhoAreLooking/WhoAreLooking";
import PersonalRecruiter from "./PersonalRecruiter/PersonalRecruiter";
import JobSearchSteps from "./JobSeachingStep/JobSearchSteps";
import BeInTheBest from "./BeInTheBest/BeInTheBest";


const Home = () => {
  return (
    <>
      {/* HeroContent */}
      <section className='theme-bg'>
        <HeroContent/>
      </section>
      {/*end  HeroContent */}


       {/* joblisting */}
         <section className='pt-75 pb-3'>
           <JobList/>
         </section>
       {/*end  joblisting */}

        {/* WhoAreLooking */}
        <section className='ptb-75 black-bg'>
          <WhoAreLooking/>
        </section>
        {/*end  WhoAreLooking */}

         {/* OurPrinciples */}
         <section className='ptb-75'>
           <OurPrinciples/>
        </section>
         {/*end  OurPrinciples */}

         {/* PersonalRecruiter */}
         <section className='ptb-75 gray-bg'>
           <PersonalRecruiter/>
          </section>
         {/* end PersonalRecruiter */}

          {/* JobSearchSteps */}
          <section className='ptb-75'>
            <JobSearchSteps/>
          </section>
         {/* end JobSearchSteps */}

         {/* for horizontal line */}
            <hr className='m-0'/>
         {/* for horizontal line */}

         {/* BeInTheBest */}
         <section className='ptb-75'>
           <BeInTheBest/>
        </section>
        {/* end BeInTheBest */}

    </>
  );
};

export default Home;
