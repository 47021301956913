import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import {HiPhone} from "react-icons/hi2"
import {BsThreeDotsVertical} from "react-icons/bs"
import {HiUserCircle} from "react-icons/hi2"
import {IoMdSend} from "react-icons/io"


const ChatBox = () => {
const [isOpen, setIsOpen] = useState(false);
const msgtoggleDropdown = () => {
    setIsOpen(!isOpen);
};

  return (
    <>
     <>
        <div className='card'>
            <div className='card-header d-md-flex d-block justify-content-between align-items-center'>
                <div>
                    <h4 className='card-title'><Link to="#" className='black'>Arjun</Link> </h4>
                    <p className='black fa-12 mb-0'>Applied to Business Development in New Delhi , Delhi</p>
                </div>
                <div className='mt-md-0 mt-2'>
                    <div className="d-flex justify-content-between">
                        <Link to="#" className='btn btn-outline-dark mr-3'> <HiPhone className='black phone-icon'/> View Phone Details</Link>
                        <div className="msg-dropdown-container">
                        <div class="three-dot" onClick={msgtoggleDropdown}>
                            <BsThreeDotsVertical className='fa-18'/>
                        </div>
                        {isOpen && (
                            <div className="msg-dropdown-menu">
                              <ul>
                                <li><Link to="/profile">Go To Profile</Link></li>
                                <li><Link to="#">Delete</Link></li>
                              </ul>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body'>
              <div className="chat-box px-1">
              <div class="list__time-heading fa-12 gray"> <span className='mx-2'>Jun 28</span>  </div>
                <div className="chat-card">
                <div className="d-flex">
                  <div className='user-icon'>
                    <HiUserCircle className='fa-24 light-black mr-2'/> 
                  </div>
                    <p className='fa-16 fw-500 black mb-0'>You <span className='ml-2 gray fa-12'>7:00 PM</span></p>
                </div>
                <div className='chating-text'>
                   <p>Hello Arjun ,</p>

                    <div className='conversation-start mt-3'>
                     <p>Thank you for applying to the Web designer position at DesignDot Technologies Pvt Ltd.</p>
                     <p>I'm impressed with your experience and would like to set up a 30-minute phone interview to 
                        learn more about you and answer any questions you may have.</p>

                    <p>Will join google meet on Tuesday at 10.30am <Link to="#" className='black'>https://meet.google.com/cgw-epsp-axi</Link></p>
                    </div>
                </div>

                </div>

                <div className="chat-card">
                <div className="d-flex">
                   <div className='user-icon'>
                     <div className='applicant-icon-style mr-2'> A </div>  
                   </div>
                   <p className='fa-16 fw-500 black mb-0'>Arjun <span className='ml-2 light-black fa-12'>7:10 PM</span></p> 
                </div>
                <div className='chating-text'>
                   <p>Thank You Sir</p>
                </div>

                </div>
              </div>
            </div>

            <div className='card-footer bg-transparent d-flex align-items-end'>
                <textarea className='form-control' rows={5} placeholder/> <span className='send-icon ml-3'><Link to="#"><IoMdSend className='fa-20 white'/></Link></span>
            </div>
        </div>   
     </>
      
    </>
  )
}

export default ChatBox
