import React, { useState } from 'react';
import DropdownSearchFilterData from "./SearchFilterData";

const MobileSearchFilter = () => {
    const [showData, setShowData] = useState(false);

    const toggleData = () => {
      setShowData(!showData);
    };
  
    return (
      <div>
        <button className='btn btn-gary' onClick={toggleData}>All Fillter</button>
        {showData && <div className='no-of-data'>   <DropdownSearchFilterData/>  </div>}
      </div>
    );
}

export default MobileSearchFilter
