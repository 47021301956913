import React from "react";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";
import './search-condidate.css';

const Education = [
  { label: "B. Tech", value: "B. Tech" },
  { label: "M. Tech", value: "M. Tech" },
];

const Experience = [
  { label: "All", value: "All" },
  { label: "2 Year", value: "2 Years" },
  { label: "3 Year", value: "3 Years" },
  { label: "Delhi", value: "Delhi" },
];

const SkilledIn = [
  { label: "All", value: "All" },
  { label: "Remote", value: "Remote" },
  { label: "On-site", value: "On-site" },
  { label: "Hybrid", value: "hybrid" },
];

const Location = [
  { label: "All", value: "All" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Noida", value: "Noida" },
];

const Candidatesearchfillter = () => {

  const handleFilterChange = (id, selectedOptions) => {
    console.log(`Selected Options for ID:`, selectedOptions);
  };

  return (
    <>
      <div className="border-1 border-radius-14 p-4">
        <h3 className="job-card-title fa-20 mb-3">Search Candidate</h3>
        <div className="">
          <form>
            <div className="search-filter">
              <div className="search-item">
                <DropdownFilter marginclass="mt-4"
                title="Education"
                className="min-width-150 bg-light-gray-1"
                options={Education}
                onChange={(selectedOptions) => handleFilterChange("Relevance", selectedOptions)
                } name="All" />
              </div>

              <div className="search-item">
                <DropdownFilter marginclass="mt-4"
                    title="Yrs of Experience"
                    className="min-width-150 bg-light-gray-1"
                    options={Experience}
                    onChange={(selectedOptions) =>
                      handleFilterChange("Experience", selectedOptions)
                    }
                name="All"
              />
              </div>

              <div className="search-item">
              <DropdownFilter marginclass="mt-4"
               title="Skill"
                className="min-width-150 bg-light-gray-1"
                options={SkilledIn}
                onChange={(selectedOptions) =>
                  handleFilterChange("SkilledIn", selectedOptions)
                }
                name="All"
              />
              </div>


              <div className="search-item">
                <DropdownFilter marginclass="mt-4"
                title="Location"
                className="min-width-150 bg-light-gray-1"
                options={Location}
                onChange={(selectedOptions) =>
                  handleFilterChange("Location", selectedOptions)
                }
                name="All"
              />
              </div>

              <div className="search-item">
                <div className="form-group ">
                  <p htmlFor="amount" className="dropdown-title mb-1 pl-2">
                    CTC
                  </p>
                  <input
                    type="text"
                    className="form-control bg-light-gray-1 "
                    id="amount"
                    placeholder="Amount" style={{width:'fit-content'}}
                  />
                </div>
              </div>
            </div>

            <div className="search-item text-sm-left text-center">
                <button className="btn btn-gary fw-500 fa-16 py-2 gray" style={{width:'fit-content'}}>
                  Search
                </button>
            </div>
            
          </form>
        </div>
      </div>
      <div className="mt-3  d-flex justify-content-between fillter-desk">
        <h3 className="card-title">
          B. Tech with 3 Yrs. of Experience on programming{" "}
        </h3>
        <p className="fa-16 fw-00 light-black mb-0">
          Search result <b> 500 records</b>
        </p>
      </div>
    </>
  );
};

export default Candidatesearchfillter;
