import React , {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DefaultFaqcard from "./DefaultFaqcard";
import { ImUsers } from "react-icons/im";
import { BsSearch, BsFillSendCheckFill } from "react-icons/bs";
import { TbRefreshDot, TbDiscountCheck } from "react-icons/tb";
import { FaCogs } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const BrowserByCard = () => {
  const location = useLocation(); 
  const currentPath = location.pathname;

  const [activeCard, setActiveCard] = useState(currentPath);

  const slidersettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
     
      {
        breakpoint: 1501,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
        },
      },

      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },

      {
        breakpoint: 770,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleCardClick = (linkTo) => {
    setActiveCard(linkTo);
  };

  return (
    <>
      <div className="brwoser-by-card mt-2 mt-sm-4">
        <Slider {...slidersettings} >
          <DefaultFaqcard
            title="Create in job sight Profile"
            icon={<ImUsers />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "/job-seeker-search-question"}
            onClick={() => handleCardClick("/job-seeker-search-question")}
          />
          <DefaultFaqcard
            title="Search"
            icon={<BsSearch />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />

          <DefaultFaqcard
            title="Apply"
            icon={<BsFillSendCheckFill />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />

          <DefaultFaqcard
            title="Getting around in job sight"
            icon={<TbRefreshDot />}
            linkTo="/job-seeker-search-question" 
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />

          <DefaultFaqcard
            title="Settings"
            icon={<FaCogs />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />

          <DefaultFaqcard
            title="Security Advice"
            icon={<TbDiscountCheck />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />
           <DefaultFaqcard
            title="Security Advice"
            icon={<TbDiscountCheck />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />

           <DefaultFaqcard
            title="Security Advice"
            icon={<TbDiscountCheck />}
            linkTo="/job-seeker-search-question"
            isActive={activeCard === "#"}
            onClick={() => handleCardClick("#")}
          />
        </Slider>
      </div>
    </>
  );
};

export default BrowserByCard;
