import React from 'react'
import {Link} from 'react-router-dom'
import {FaShareSquare} from 'react-icons/fa'
import MessageModal from '../Message/MessageModal'

const ApplicantCard = () => {
  return (
    <>
      <div className='card'>
        <div className='card-body'>
            <div className='d-flex justify-content-between'>
                <div className='applicant-card'>
                    <div className='applicant-info view-job-post-card-content'>
                        <h4>Subadhra Chandrasekaran's application</h4>
                        <p>Business Development Executive</p>
                        <p>Coimbatore, Tamil Nadu, India</p>
                        <p className='gray fa-14'>Applied 5 days ago</p>
                    </div>
                    <div className='applicant-info-more d-flex gap-30 mt-4'>
                    <div className='applicant-info-fit'>
                            <select className='form-control border-0'>
                                <option selected>Good Fit</option>
                                <option>Non Fit</option>
                                <option>Average</option>
                            </select>
                    </div>
                    <div className='vertical-line'></div>
                    <div className='applicant-info-fit'>
                           <MessageModal/>
                            <Link t0="/message" className="btn btn-blue-outline ">More..</Link>
                    </div>
                    </div>
                </div>

                <div className='share-icon'>
                <FaShareSquare className='fa-20 gray'/>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default ApplicantCard
