import React, { useState } from 'react';
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import { BiMap, BiBarChartSquare } from 'react-icons/bi';
import { IoWalletOutline } from 'react-icons/io5';
import { GrGallery } from 'react-icons/gr';
import { LuSettings2 } from 'react-icons/lu';
import { FiEdit2 } from 'react-icons/fi';
import { BsLink45Deg ,BsEye } from 'react-icons/bs';
import WorkSkills from '../../../CommonComponents/WorkSkills';
import { Link } from 'react-router-dom';
import {BiEdit } from "react-icons/bi";

const JobDescription = () => {
  const [isOngoingEditable, setIsOngoingEditable] = useState(false);
  const [ongoingValue, setOngoingValue] = useState('On-Going');

  const handleOngoingInputChange = (e) => {
    setOngoingValue(e.target.value);
  };

  const handleOngoingEditClick = () => {
    setIsOngoingEditable(true);
  };

  const handleOngoingSaveClick = () => {
    setIsOngoingEditable(false);
  };

  const ongoingOptions = ['On-Going', 'Completed'];

  return (
    <>
      <div className="appliedjob">
        <div className="card light-blue-bg-1">
          <div className="card-header bg-transparent">
            <h3 className="card-title fa-16">Acme Corporation</h3>
          </div>
          <div className="card-body">
            <div className="job-details">
              <h3 className="card-title">Senior UI/UX Designer</h3>
              <p className="fa-16 fa-sm-14 fw-400 light-black mt-2">
                As a Marketing Professional my accentuation is on conversations,
                for which it is fundamental that we know our get-together of individuals well
                or potentially have the preparation to get
              </p>
              <div className="jobdescription-list">
                <ul>
                  <li>
                    <Link to="#">
                      <MdOutlineCurrencyRupee className="fa-20 mr-2" /> Max 12,00,000
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <BiMap className="fa-20 mr-2" /> Remote Job
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <IoWalletOutline className="fa-20 mr-2" /> Spent - 250 Rs.
                    </Link>
                  </li>
                  <li>
                  {isOngoingEditable ? (
                    <>
                      <select
                        value={ongoingValue}
                        onChange={handleOngoingInputChange}
                        onBlur={handleOngoingSaveClick}
                        className="form-control"
                      >
                        {ongoingOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <Link to="#" onClick={handleOngoingEditClick}>
                      <BiBarChartSquare className="fa-20 mr-2" /> {ongoingValue} <BiEdit/>
                    </Link>
                  )}
                  </li>
                  <li>
                    <Link to="/view-job-post">
                      <GrGallery className="fa-20 mr-2" /> View Job Post
                    </Link>
                  </li>

                  <li>
                    <Link to="/job-post">
                      <BsEye className="fa-20 mr-2" /> Edit Job Post
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <LuSettings2 className="fa-20 mr-2" /> Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <FiEdit2 className="fa-20 mr-2" /> Repost Job
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <BsLink45Deg className="fa-20 mr-2" /> Copy Link
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <hr />
            <div className="skill">
              <h3 className="card-title  mb-3">Skill & Expertise</h3>
              <div className="work-experiance">
                <WorkSkills />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDescription;
