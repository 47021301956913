import React from "react";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";


const Anytime = [
  { label: "Any Time", value: "Any Time" },
  { label: "Past Week", value: "Past Week" },
  { label: "Past 24 hours", value: "Past 24 hours" },
  { label: "Past month", value: "Past month" },
];

const Company = [
  {
    label: "Designdot Technologies Pvt Ltd",
    value: "designdot technologies pvt ltd",
  },
  { label: "Microsoft", value: "microsoft" },
  { label: "Amazon", value: "amazon" },
];

const Salary = [
  { label: "1LPA-2.3LPA", value: "1LPA-2.3LPA" },
  { label: "3LPA-5.3LPA", value: "3LPA-5.3LPA" },
  { label: "7LPA-12LPA", value: "7LPA-12LPA" },
];

const Style = [
  { label: "Remote", value: "Remote" },
  { label: "On-site", value: "On-site" },
  { label: "Hybrid", value: "hybrid" },
];

const SearchFilterData = () => {
  const handleFilterChange = (id, selectedOptions) => {
    console.log(`Selected Options for ID:`, selectedOptions);
  };

  return (
    <>
   
    <DropdownFilter
        className="min-width-185"
        options={Anytime}
        onChange={(selectedOptions) =>
          handleFilterChange("anytime", selectedOptions)
        }
        name="Any Time"
      />
      <DropdownFilter
        className="min-width-185"
        options={Company}
        onChange={(selectedOptions) =>
          handleFilterChange("company", selectedOptions)
        }
        name="Company"
      />

      <DropdownFilter
        className="min-width-185"
        options={Salary}
        onChange={(selectedOptions) =>
          handleFilterChange("company", selectedOptions)
        }
        name="Salary"
      />

      <DropdownFilter
        className="min-width-185"
        options={Style}
        onChange={(selectedOptions) =>
          handleFilterChange("company", selectedOptions)
        }
        name="Style"
      />
    </>
  );
};

export default SearchFilterData;
