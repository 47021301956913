import React from 'react'

const WorkDetailsCard = ({ title, paragraph }) => {
    
  
    return (
      <div className='work-details-card ml-0 ml-sm-3 ml-xl-5'>
        <h4 className='underline'>{title}</h4>
        <p className='pt-4' dangerouslySetInnerHTML={{ __html: paragraph }}>
        </p>
      </div>
    );
  };

export default WorkDetailsCard
