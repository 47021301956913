import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import './Profile.css';
import ProfileMainContent from './ProfileMainContent';
import UploadResume from '../../../CommonComponents/UploadResume';
import HighlightsData from '../../../CommonComponents/HighlightsData';
import CareerInsightData from './CareerInsightData';
import TopSkills from '../../../CommonComponents/TopSkills';

const Profile = () => {

  return (
    <>
     <section>
     {/* page-wrapper */}
        <div className='container-fluid side-space'>
             <div className='grid-container pt-4 pb-4'>
                  <div className='left-side grid-left'>
                     <LeftSidebar/>
                  </div>
                  <div className='main-content grid-middle mt-smm-0 mt-3'>
                   <ProfileMainContent/>
                  </div>
                  <div className='right-side grid-right '>
                   <UploadResume title='Upload Resume'/>
                   <CareerInsightData/>
                   <HighlightsData/>
                   <TopSkills/>
                  </div>
             </div>
        </div>
     </section>

    </>
  )
}

export default Profile
