import React from 'react'

const PurchaseFilter = () => {
  return (
    <>

       <div className="border-1 border-radius-14 p-3 p-sm-4 mt-3 mt-smm-0">
          <form>
            <div className="row align-items-start">
                <div className='col-xl-3 col-lg-4 col-md-5 col-sm-12'>
                   <h3 className="job-card-title fa-20">Purchase History</h3>
                </div>
              <div className='col-xl-5 col-lg-6  col-md-7 col-sm-12 mt-sm-0 mt-2'>
              <div className="">
                  <select className="form-control" id="skill">
                    <option>Today</option>
                    <option>Last 1 Months</option>
                    <option>Last 3 Months</option>
                  </select>
                </div>
              </div>
           
            </div>
          </form>
        </div>
    </>
  )
}

export default PurchaseFilter
