import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ResumeBuilder from '../ResumeBuilder/ResumeBuilder';
import JobAlert from '../JobAlert/JobAlert';
import AddProfile from '../Profile/AddProfilePhoto';
import {HiBars3BottomLeft } from 'react-icons/hi2';


const MenuList = ({ menuData , customClasses }) => {
  const [showModal, setShowModal] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileMenuCollapsed, setIsMobileMenuCollapsed] = useState(true);
  const location = useLocation();

  const handleModalClose = () => {
    setShowModal(null);
  };

  const handleItemClick = (menuItem) => {
    if (menuItem.modalContent) {
      setShowModal(menuItem.modalContent);
    }

    setActiveItem(menuItem);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuCollapsed(!isMobileMenuCollapsed);
  };

  return (
    <>
      {/* Profile Card */}
      <div className="profile-card profile-bg">
        <div className="p-24">
          <div className="profile-card-img text-center">
            <AddProfile />
          </div>
          <div className="profile-card-details text-center">
            <h4>Nilotpal Sinha</h4>
            <p>Generative AI / Data Science / Mathematics / Technology</p>
          </div>
        </div>

        <div className="profile-links menu-list text-center profile-card-for-mob" style={{ borderBottom: 'none' }}>
          <ul>
            <li
              onClick={() => handleItemClick('Profile')}
              className={`py-14px border-bottom-2 border-top-2 mb-0 ${activeItem === 'Profile' || location.pathname === '/profile' ? 'menu-active' : ''}`}
            >
              <Link to="/profile" className="fw-600">
                Profile
              </Link>
            </li>

            <li
              onClick={() => handleItemClick('Dashboard')}
              className={`py-14px ${activeItem === 'Dashboard' || location.pathname === '/dashboard' ? 'menu-active' : ''}`}
            >
              <Link to="/dashboard" className="fw-600">
                Dashboard
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* End of Profile Card */}

      {/* Profile Summary */}

      <div className="profile-bg mt-3 mb-1 mb-sm-0">
        <div>

          <button className="mobile-collapse-button" onClick={toggleMobileMenu}>
            {isMobileMenuCollapsed ? (<>Menu <HiBars3BottomLeft className='fa-28 fw-600'/></>): (<>Menu <HiBars3BottomLeft className='fa-28 fw-600'/></>)}
          </button>

          <div className="profile-summary">
          {/* <div className="profile-menu-heading text-hide-for-mob">
            <h4>{menuData.title}</h4>
          </div> */}
              {menuData.menuItems.map((menuItem, index) => (
              <div className={`left-menu ${index === menuData.menuItems.length - 1 ? ' last-menu' : ''}${isMobileMenuCollapsed ? '' : ' menu-expanded'}`} key={index}>
                
           
                <h4
                  className={`sidebar-menu-title ${customClasses[menuItem.title] || ''} ${location.pathname === menuItem.link ? 'menu-active' : ''}`}
                >
                  <Link className="black"  to={menuItem.link}>
                    {menuItem.title}
                  </Link>
                </h4>
                <div className="menu-list">
                  <ul>
                    {menuItem.items.map((item, itemIndex) => (
                      <li
                        key={itemIndex}
                        className={`${customClasses[menuItem.title]} ${activeItem === item || location.pathname === item.link ? 'menu-active' : ''}`}
                        onClick={() => handleItemClick(item)}
                      >
                        {item.modalContent ? (
                          <Link className="modal-link" to={item.link} onClick={() => handleItemClick(item)}>
                            {item.label}
                          </Link>
                        ) : (
                          <Link to={item.link}>{item.label}</Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End of Profile Summary */}

      {/* Render modals based on showModal */}
      {showModal === 'ResumeBuilder' && <ResumeBuilder handleClose={handleModalClose} />}
      {showModal === 'JobAlert' && <JobAlert handleClose={handleModalClose} />}
    </>
  );
};

export default MenuList;