import React from 'react';
import './WorkSkills.css';

const Education = () => {
  const education = ['B Tech', 'M Tech', 'O level'];

  return (
    <>
      <div className="d-flex flex-wrap gap-5">
        {education.map((education, index) => (
          <span key={index} className="skill-style">
            {education}
          </span>
        ))}
      </div>
    </>
  );
};

export default Education;
