import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputTag from "../../../../CommonComponents/InputTag";
import {} from 'react-icons/'
import "./Modal.css";

const ProfileTopic = ({icon, className }) => {
  const [showProfileTopic, setProfileTopic] = useState(false);

  const handleProfileTopicOpen = () => {
    setProfileTopic(true);
  };

  const handleProfileTopicClose = () => {
    setProfileTopic(false);
  };

  return (
    <>
       <Link
        className={`gary-text ml-3 fa-24 ${className}`}
        onClick={handleProfileTopicOpen}
      >
        {icon} 
      </Link>

      {showProfileTopic && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h4 className="modal-title">Add Profile Hash Topics</h4>
                </div>

                <button
                  type="button"
                  className="close"
                  onClick={handleProfileTopicClose}
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                  <div className="heading">
                    <p className="fa-14 black mt-1">
                      Your topics will be displayed at the top of your profile to show your audience what you talk about most.
                    </p>
                  </div>

                  <p className="gray fa-14 fw-400">You can add up to 5</p>
                    <InputTag title="Add Profile Tag"/>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileTopic;
