import React from "react";
import HelpContactCard from "./HelpContactCard";
import VerticalTab from "./VerticalTab";
import HelpSearch from "./HelpSearch";


const HelpQATabData = () => {
  const tabData = {
    question: "How do I search for jobs on Naukri?",
    tabs: [
      {
        id: 1,
        label: "Why should I register with In Job Sight?",
        content:
          "1-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },
      {
        id: 2,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "2-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },
      {
        id: 3,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "3-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },
      {
        id: 4,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "4-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },
      {
        id: 5,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "5-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },

      {
        id: 6,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "6-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },

      {
        id: 7,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "7-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },

      {
        id: 8,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "8-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },

      {
        id: 9,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "9-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },

      {
        id: 10,
        label: "Lorem ipsum dolor sit amet?",
        content:
          "10-In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate  the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
      },
    ],
  };
  return (
    <>
      <section className="pb-4 pt-3">
        <div className="fluid-container side-space">

            {/* search */}
          <div className="help-center mb-4 border-radius-14">
            <div className="help-search">
              <HelpSearch />
            </div>
          </div>

        {/* Browser card */}
        
          <div className="help-conatiner">
            <div>
              <div className="">
                <VerticalTab tabData={tabData} />
              </div>
            </div>

            <div>
              <HelpContactCard />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpQATabData;
