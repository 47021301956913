import React from 'react'
import MenuListData from './MenuListData';
import './LeftSidebar.css';


const profileLeftSidebar = () => {
  
  return (
    <>
      <MenuListData/>
    </>
  )
}

export default profileLeftSidebar
