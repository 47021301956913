import React from 'react';
import DropdownFilter from "../../../CommonComponents/DropdownFilter";

const Education = [
  { label: "B. Tech", value: "B. Tech" },
  { label: "M. Tech", value: "M. Tech" },
];

const Experience = [
  { label: "All", value: "All" },
  { label: "2 Year", value: "2 Years" },
  { label: "3 Year", value: "3 Years" },
];


const Location = [
  { label: "All", value: "All" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Noida", value: "Noida" },
];


const Ratings = [
  { label: "Unrated", value: "Unrated" },
  { label: "Good fit", value: "Good fit" },
  { label: "Maybe", value: "Maybe" },
  { label: "Not a fit", value: "Not a fit" },
];

const SkilledIn = [
  { label: "Marketing", value: "Marketing" },
  { label: "Sales", value: "Sales" },
  { label: "Lead Generation", value: "Lead Generation" },
  { label: "Sales and Marketing", value: "Sales and Marketing" },
  { label: "Business Development", value: "Business Development" },
  { label: "Microsoft Excel", value: "Microsoft Excel" },
];


const ResumeFilter = () => {

  const handleFilterChange = (id, selectedOptions) => {
    console.log(`Selected Options for ID:`, selectedOptions);
  };

  return (
    <>
       <div className="border-1 border-radius-14 p-4">
        <h3 className="job-card-title fa-20 mb-3">Saved Resume</h3>
        <div className="">
          <form>
            <div className="search-filter">
                <div className="form-group search-item">
                  <label htmlFor="name" >
                   Name
                  </label>
                  <input
                    type="text"
                    className="form-control bg-light-gray-1  md-w-fit"
                    id="name"
                    placeholder="Candidate Name" />
                </div>
             
                <div className="form-group search-item">
                  <DropdownFilter marginclass="mt-4"
                  title="Education"
                  className="min-width-150 bg-light-gray-1"
                  options={Education}
                  onChange={(selectedOptions) => handleFilterChange("Relevance", selectedOptions)
                  } name="All" />
                </div>
              
                <div className="form-group search-item">
                <DropdownFilter marginclass="mt-4"
                  title="Ratings"
                  className="min-width-150 bg-light-gray-1"
                  options={Ratings}
                  onChange={(selectedOptions) =>
                    handleFilterChange("Ratings", selectedOptions)
                  }
                  name="All"
                />
                </div>
           
                <div className="form-group search-item">
                   <DropdownFilter marginclass="mt-4"
                    title="Yrs of Experience"
                    className="min-width-150 bg-light-gray-1"
                    options={Experience}
                    onChange={(selectedOptions) =>
                      handleFilterChange("Experience", selectedOptions)
                    }
                name="All"
              />
              </div>
            
                <div className="form-group search-item">
                <DropdownFilter marginclass="mt-4"
                  title="Skill"
                  className="min-width-150 bg-light-gray-1"
                  options={SkilledIn}
                  onChange={(selectedOptions) =>
                    handleFilterChange("SkilledIn", selectedOptions)
                  }
                  name="All"
                />
                </div>
              
                <div className="form-group search-item">
                  <DropdownFilter marginclass="mt-4"
                    title="Location"
                    className="min-width-150 bg-light-gray-1"
                    options={Location}
                    onChange={(selectedOptions) =>
                      handleFilterChange("Location", selectedOptions)
                    }
                    name="All"
                  />
                </div>
             
                <div className="form-group search-item">
                  <label htmlFor="amount" className="">
                    CTC
                  </label>
                  <input
                    type="text"
                    className="form-control bg-light-gray-1 mtop-4  md-w-fit"
                    id="amount"
                    placeholder="Amount"
                  />
                </div>
           
            </div>            
            <div className='text-md-left text-center'>
               <button className="btn btn-gary fw-500 fa-16 py-2 white mt-2 px-5">
                 Search
               </button>
            </div>
           
          </form>
        </div>
      </div>
    </>
  )
}

export default ResumeFilter
