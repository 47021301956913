import React from 'react';
import Profilebanner from '../../../../assets/profilebanner.png';
import ProfileSummary from './ProfileSummary';
import EditBasicIntro from './ProfileModal/EditBasicIntro';
import ViewContactDeatils from './ProfileModal/ContactInfo';
import EditBanner from './ProfileModal/EditBanner';
import AddProfile from './AddProfilePhoto';
import ProfileHashTopic from './ProfileModal/ProfileHashTopic';
import { AiOutlineEdit} from 'react-icons/ai';



const ProfileMainContent = () => {
  return (
    <>
    <main>
        <div className='profile-main-wrapper bg-white'>
            <div className='profile-main-container'>

                {/*profile  banner */}
                <div className='profile-hero-banner position-relative'>
                    <div className='profile-hero-banner-image profile-background-image--default'>
                       <img src={Profilebanner} className="img-fluid full-height full-width" alt='Profile banner'/>
                    </div>
                    <div className='profile-hero-banner-edit-icon'>
                     <EditBanner/>
                    </div>
                </div>

                {/*end profile  banner */}

               {/* profile  info */}
                <div className='profile-card-photo-deatils position-relative'>
                    <div className='profile-card-photo-wrapper'>
                        <div className='profile-card-photo-edit'>
                          <AddProfile/>
                        </div>
                    </div>

                    <div className='ptofile-card-photo-content mt-3'>
                        <div className='ptofile-card-photo-content-wrapper'>
                            <div className='profile-card-info d-lg-flex d-block justify-content-between'>
                                <div>
                                    <h4>Nilotpal Sinha  <EditBasicIntro/> </h4>
                                    <p> <small className='profile-role-text'>Generative AI / Data Science / Mathematics / Technology</small></p>
                                    <p>Dubai, United Arab Emirates    <ViewContactDeatils/></p>
                                </div>
                               

                                <div className='profile-company-info text-lg-right text-left  mt-2 mt-lg-0'>
                                    <p className='fw-500 text-lg-right text-left'>DesignDot Technologies Pvt Ltd</p>
                                    <p className='text-lg-right text-left'>Since Sep 2022  Exp. 5 Yrs</p>
                                </div>
                            </div>

                            <div className='profile-card-topic mt-3'>
                                <h4 className='fa-20 fa-mdd-16'>Expertise <ProfileHashTopic icon={<AiOutlineEdit/>}/></h4>
                                <p className='mt-1 mb-0'>Talks about #tech, #marketing, #clientsuccess, 
    #qualityservice, and #businessanalyst</p>
                           </div>
                        

                            
                        </div>
                    </div>
                </div>

                 {/* end profile  info */}
              
            <div className='line mt-2'/>

              {/*  profile  details */}
             <ProfileSummary/>
             {/* end  profile  details */}
            </div>
        </div>
    </main>
      
    </>
  )
}

export default ProfileMainContent
