import React from "react";
import {Link} from 'react-router-dom';
import './PersonalRecruiter.css';
import {FaAngleRight}  from 'react-icons/fa';

const PersonalRecruiter = () => {
  return (
    <>
      
        <div className="container-fluid side-space">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div className="personal-recruiter-left">
                <h3>
                Think of us as your <br className="for-brake"/> personal recruiter
                </h3>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 border-left-3 d-flex align-items-center deafult-mob-height">
              <div className="personal-recruiter-list w-100">
                <div className="personal-recruiter-content ml-0 ml-sm-3 ml-xl-5 ">
                    <p>We're always looking for the best opportunities for you.
    With over 5 million active jobs refreshed daily, we keep a
    pulse on the market — so you don't have to. </p>
                </div>
                <div className="text-lg-right text-center ">
                   <Link to="" class="btn-theme  fa-20 default-padding">Try #inJobsight for Free <FaAngleRight/></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default PersonalRecruiter;
