import React from 'react'

const JobPostAsideCard = () => {
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='d-flex'>
           <div>
             <h4 className='card-title fw-500 light-black fa-26 '>30</h4>
             <p className='gray fw-400 fa-14'>Applicants</p>
           </div>
           <div>
             <h4 className='card-title fw-500 light-black fa-26'>449</h4>
             <p className='gray fw-400 fa-14'>Views</p>
           </div>
          </div>

          <div className='mt-2'>
            <h4 className='card-title fw-500 light-black fa-20'>₹ 300.00 Spent</h4>
            <p className='gray fw-400 fa-14'>₹ 150.00 daily buget</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobPostAsideCard
