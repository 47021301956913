import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import DashboardInfo from './DashboardInfo';
import AppliedJobsData from '../../../CommonComponents/AppliedJobsData';
import RecentJobsPostData from '../../../CommonComponents/RecentJobsPostData';



const Dashboard = () => {
  return (
    <>
     <section>
        <div className='container-fluid side-space'>
             <div className='grid-container pt-4 pb-4'>
                  <div className='left-side grid-left'>
                     <LeftSidebar/>
                  </div> 

                  <div className='main-content grid-middle mt-3 mt-smm-0 '>
                    <DashboardInfo/>
                  </div>

                  <div className='right-side  grid-right'>
                  <AppliedJobsData  title="Applied Jobs"/>
                  <RecentJobsPostData title="Recent Job Post"/>
                  </div>
             </div>
        </div>
    </section>
    </>
  )
}

export default Dashboard
