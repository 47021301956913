import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import BeInTheBest from "../../Homepage/BeInTheBest/BeInTheBest";
import ProfileHashTopic from './ProfileHashTopic';
//import Analytics from './Analytics';



const AnalyticsProfileTips = () => {
  return (
    <>
        <section className='pb-75 pt-4'>
           <div className='fluid-container side-space'>
            <div className='grid-2'>
                <div className='analytics'>
                    <LeftSidebar/>
                </div>

                <div className='analytics-content'>
                  {/* <Analytics className="mb-4"/> */}
                  <ProfileHashTopic/>
                </div>
                   
            </div>
        </div>
      </section>

      {/* BeInTheBest */}

      <section className="pt-4 pb-75">
        <BeInTheBest/>
      </section>
    </>
  )
}

export default AnalyticsProfileTips
