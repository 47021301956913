import React, { useState } from 'react';
import './GettingStarted.css';
import { Link } from 'react-router-dom';
import EditTargetGoal from './EditTargetGoal';
import ResumeBuilder from '../ResumeBuilder/ResumeBuilder';

const GettingStarted = () => {
  const progressPercentage = 17;
  const totalTasks = 1;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const tasks = [
    {
      title: 'Set My Career Goal',
      description: 'Help stay focused on track during your job search by setting and editing your career goals directly in your scandity',
      btnText: <EditTargetGoal className="text-white fa-sm-14" linkText='Edit Goal'/>
    },
    {
      title: 'Add Jobs to My Job Tracker',
      description: 'Bookmark jobs from your favorite job boards',
      link: '/job-search',
      btnText: 'Search Jobs'
    },
    {
      title: 'Rate Jobs',
      description: 'Review the keywords in the job description and add your excitement level to the bookmarked jobs in your Job Tracker.',
      link: '/job-tracker',
      btnText: 'Go to Job Tracker'
    },
    {
      title: 'Create a Resume',
      description: 'Upload an existing resume, or document your experience to create custom resumes for each application..',
      link: '',
      btnText: 'Create Resume',
      onClick: toggleModal
    },
    {
      title: 'Create Company Profile',
      description: 'Create a profile for your business to post jobs and receive recruiting advantages.',
      link: '/profile',
      btnText: 'Create Profile'
    },
    {
      title: 'Your Application Setting',
      description: 'Note*: You can set up your telephone number, delete your profile, active or inactive employment profiles, etc.',
      link: '',
      btnText: 'Go To Setting'
    },
  ];

  return (
    <>
      <div className='summary-module-wrapper'>
        <div className='card'>
          <div className='card-header bg-transparent'>
            <h3 className='card-title'>Getting Started</h3>
          </div>
          <div className='card-body'>
            <div className='task-progress'>
              <div className='progress-lable d-flex justify-content-between'>
                <h3>{progressPercentage}% Complete</h3>
                <h3>{totalTasks}/{tasks.length} Task</h3>
              </div>
              <div className='progress' style={{ height: '10px' }}>
                <div
                  className='progress-bar'
                  style={{ width: `${progressPercentage}%`, height: '10px' }}
                ></div>
              </div>
            </div>

            <div className='line mt-3'></div>

            {tasks.map((task, index) => (
              <div className='task-item' key={index}>
                <div className='task-card'>
                  <div className='tast-content'>
                    <h3>{task.title}</h3>
                    <p>{task.description}</p>
                  </div>

                  <div className='task-action'>
                    <div className='text-left text-lg-right mt-md-0 mt-mdd-2'>
                      {task.link ? (
                        <Link to={task.link} className='btn blue-btn padding_ fa-mdd-14'>
                          {task.btnText}
                        </Link>
                      ) : (
                        <button className='btn blue-btn padding_ fa-mdd-14' onClick={task.onClick}>
                          {task.btnText}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isModalOpen && <ResumeBuilder handleClose={toggleModal} />}
    </>
  );
};

export default GettingStarted;
