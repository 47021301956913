import React from 'react';
//mport DatePicker from '../../../../assets/date-picker.svg';
import ToggleSwitch from '../../../CommonComponents/ToggleSwitch';
import { Link } from "react-router-dom";
import './AutoApplied.css';
//import {FcCalendar} from 'react-icons/fc';

const AutoAapplied = () => {
  //const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
//const [selectedDate, setSelectedDate] = useState('15 Jun, 2023');

  // const handleDateSelect = () => {
  //   setIsDatePickerOpen(false);
  // };

  return (
    <>
      {/* Do you want the position to be auto-applied for? */}
      <div className='summary-module-wrapper mb-3'>
        <div className='card'>
          <div className='card-body'>
            <div className='row m-0 align-items-center'>
              <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 pl-0'>
                <div className='apply-content'>
                  <h3 className='card-title mb-2'>Enable automatic job application</h3>
                  <div className='d-block'>
                    <div className='apply-date'>
                      <p className='mb-0'><b>From</b> 15 Jun, 2023 <b>To</b> 14 Jul, 2023</p>
                    </div>
                    <div className='apply-lable'>
                      <p className='mb-0'><b>Note*</b> free for first 1 Month Only</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 pl-0'>
                <div className='d-flex justify-content-lg-end justify-content-start gap-30 ml-0 ml-lg-2 mt-2 ml-lg-0'>
                  {/* <div className='datepicker align-items-center' onClick={() => setIsDatePickerOpen(true)}>
                    <div className='calendar-icon'>
                      <FcCalendar className='fa-36'/>
                    </div>
                    {isDatePickerOpen ? (
                      <div>
                        <h4>Date</h4>
                        <input type="date"  className="date-style" value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value); handleDateSelect(); }} />
                      </div>
                    ) : (
                      <div>
                        <h4> Date</h4>
                        <p>{selectedDate}</p>
                      </div>
                    )}
                  </div> */}
                  <div className='text-right'>
                    <ToggleSwitch/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Your resume will be available to recruiters */}
      
      <div className='summary-module-wrapper mb-3'>
        <div className='card'>
          <div className='card-body'>
            <div className='row m-0 align-items-center'>
              <div className='col-xl-8 col-lg-7 col-md-12 col-sm-12 p-0'>
                <div className='apply-content'>
                  <h3 className='card-title mb-2'>Your profile will be visible to recruiters</h3>
                    <div className='apply-lable'>
                      <p className='mb-0'><b>Note*</b> You won't be able to receive post notice after this time frame.</p>
                    </div>
                    <div className='apply-date'>
                      <p className='mb-0'><b>From</b> 15 Jun, 2023 <b>To</b> 14 Jul, 2023</p>
                    </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-5 col-md-12 col-sm-12 p-0'>
                <div className='text-smm-left text-mdd-right mt-lg-0 mt-2 '>
                  <Link to="" className="btn blue-btn fa-mdd-14">Change Setting</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recent applied jobs */}
      <div className='summary-module-wrapper mb-3'>
        <div className='card'>
          <div className='card-body pt-0 pb-0'>
            <div className='row m-0 align-items-center'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0'>
                <div className='apply-content pr-0 py-lg-3 py-2 pr-mdd-4 border-right-side'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='apply-lable'>
                      <h3 className='card-title'>Recently applied jobs</h3>
                    </div>
                    <div className='recent-apply d-flex'>
                      <h3 className='recent-apply-value black-text fw-500'>3</h3>
                      <h3 className='recent-apply-lable light-gray-text fw-500 ml-1'>Jobs</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0'>
                <div className='apply-content pl-0 pl-mdd-4 py-lg-3 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='apply-lable'>
                      <h3 className='card-title'>Recent Job Post</h3>
                    </div>
                    <div className='recent-apply d-flex'>
                      <h3 className='recent-apply-value black-text fw-500'>10 </h3>
                      <h3 className='recent-apply-lable light-gray-text fw-500 ml-1'> Post</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AutoAapplied;
