import React from "react";

const SearchForm = ({className, inputclass}) => {
  return (
    <>
     <div className="search-form">
        <form>
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div className="form-group">
                <label htmlFor="what" className={`${className}`}>What?</label>
                <input
                  type="text"
                  className={`form-control ${inputclass}`}
                  id="what"
                  placeholder="Profession, Keyword or Company"
                />
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div className="form-group">
                <label htmlFor="experience" className={`${className}`}>Experience</label>
                <input
                  type="text"
                  className={`form-control ${inputclass}`}
                  id="experience"
                  placeholder="Yrs. Of Exp."
                />
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div className="form-group">
                <label htmlFor="city" className={`${className}`}>Where?</label>
                <input
                  type="text"
                  className={`form-control ${inputclass}`}
                  id="city"
                  placeholder="City of Region"
                />
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 btn-center">
              <button type="submit" className="btn-theme mt-3">
                SEARCH NOW
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchForm;
