import React from 'react';
import CareerInsight from './CareerInsight';

const CareerInsightData = () => {
    const workExperiences = [
        {
          title: 'You Have',
          years: '3 Years - 6 Month',
          description: 'Years of work experience',
        },
        {
          title: 'You’ve worked at',
          years: '1 Years',
          description: 'Years of work experience',
        },
        {
          title: 'You’ve had',
          years: 2,
          description: 'Roles in your career',
        },
    
        {
          title: 'You’ve averaged',
          years: 3,
          description: 'Years percompany',
        },
        
      ];
  return (
    <>
       <CareerInsight workExperiences={workExperiences} />
    </>
  )
}

export default CareerInsightData
