import React from "react";
import  JobALertImg from '../../../../assets/jobalert.png';
import ToggleSwitch from "../../../CommonComponents/ToggleSwitch";
import {Link} from 'react-router-dom';

const ResumeBuilder = ({ handleClose }) => {
  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content border-4">
          <div className="pt-4 px-4">
            <button type="button" className="close" onClick={handleClose}>
              &times;
            </button>
            <h4 className="modal-title text-center border-bottom-2 pb-3 fw-600">
              JOB ALERT
            </h4>
          </div>
          <div className="modal-body">
            <div className="m-auto job-alert pr-3 custom-scrollbar overflow-x-hide">
              <div className="job-alert-img m-auto text-center">
                <img src={JobALertImg} alt="job alert" className="img-fluid"/>
              </div>
              <div className="jobalert-title mt-4">
                <h3 className="fa-28 fa-md-24 fa-sm-18 text-center black fw-500 mb-0">You have no job alerts yet</h3>
                <p className="fa-14 text-center gray fw-400">Flip the switch on a job search page to create a new alert.</p>
              </div>
              <div className="mt-3 mb-5 text-center">
                <Link to="/job-search" className="btn blue-btn px-4 fw-500">
                  Search For Job
                </Link>
              </div>

              <div className="border-bottom-2 border-top-2 d-sm-flex d-block py-2 justify-content-between align-items-center" >
                <div className="jobrecomended-alert">
                  <h4 className="fa-20 black fw-600 mt-3">Job recommendations </h4>
                  <p className="fa-16 black fw-400 ">We’ll suggest jobs for you based on your profile, job preferences, and activity on InJobSearch. Based on your job alerts, activity, and profile.
</p>
                </div>

                <div className="job-alert-toggle w-20 text-right pr-3">
                    <ToggleSwitch/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeBuilder;
