import React from "react";
import BrowserByCard from "./BrowserByCardSlide";
import "./help.css";
import HelpQuestionCard from "./HelpQuestionCard";
import HelpSearch from "./HelpSearch";



const Help = () => {

  return (
    <>
      <section >
        <div className="fluid-container">
          
            <div>
              <div className="">
                <div className="card-body p-0">
                  <div className="help-center">
                    <div className="help-search">
                      <h3 className="pb-2 fa-24 fa-sm-20 light-black fw-500 text-center">
                        Hi, how can we help you?
                      </h3>
                      <HelpSearch />
                    </div>
                  </div>

                  <div className="help-question-conatiner">
                    <div className="help-question text-center py-3">
                      <h3 className="fa-24 fa-sm-20 fw-500">QUICK SOLUTION</h3>
                      <p className="fa-16 mb-0 fw-400">
                        as per the recent search trend
                      </p>

                      <div className="mt-3">
                        <HelpQuestionCard />
                      </div>
                    </div>
                  </div>

                  <div className="brwoser-by-container py-3 side-space">
                    <h3 className="fa-24 fa-sm-20 fw-500 text-center">
                      BROWSE BY TOPIC
                    </h3>

                    <BrowserByCard/>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default Help;
