import React from 'react';
import JobPostAsideCard from './JobPostAsideCard';
import './viewjobpost.css';
import ViewJobPostCard from './ViewJobPostCard';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import ViewJobPostDescription from './ViewJobPostDescription';
import BillingCard from './BillingCard';
import HiringRoleCard from './HiringRoleCard';

const ViewJobPost = () => {
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-post-view grid-3-1-container">
              <div className="left-side grid-left">
                <LeftSidebar />
              </div>

            <div className='job-content grid-middle'>
                <div className='job-post-card mb-1'>
                  <ViewJobPostCard/>
                </div>

                <div className='job-post-deatils'>
                   <ViewJobPostDescription/>
                </div>
            </div>

              <div className='job-info-card grid-right'>
                  <JobPostAsideCard/>
                  <BillingCard/>
                  <HiringRoleCard/>
              </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default ViewJobPost
