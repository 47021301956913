import React from "react";
import { Link } from "react-router-dom";
import "./FooterComponent.css";
import {FaFacebookF} from 'react-icons/fa';
import {FaLinkedinIn} from 'react-icons/fa';
import {ImInstagram} from 'react-icons/im';

function FooterComponent() {
  return (

    <footer className="side-space-margin pb-4">
      <div className="container-fluid light-blue-bg border-radius-31 p-40">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <div className="footer-list">
                  <h6>Find Jobs</h6>
                  <ul className="footer-links mt-sm-3 mt-2 mb-sm-0 mb-2">
                    <li>
                      <Link to="#">
                        Popular categories
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        Jobs in demand
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                      Jobs by location
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                      More Jobs
                      </Link>
                    </li>
                  </ul>
              </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <div className="footer-list">
                  <h6>For Company</h6>
                  <ul className="footer-links mt-sm-3 mt-2 mb-sm-0 mb-2">
                    <li>
                      <Link to="#">
                        Why Jobs Sight
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                       Post a free job
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                      Knowledge base
                      </Link>
                    </li>
                   
                    <li>
                      <Link to="#">
                      Login
                      </Link>
                    </li>
                  </ul>
              </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <div className="footer-list">
                  <h6>For Company
</h6>
                  <ul className="footer-links mt-sm-3 mt-2 mb-sm-0 mb-2">
                    <li>
                      <Link to="/help-center">
                        Support And Help
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                       Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                      Pricing
                      </Link>
                    </li>
                  </ul>
              </div>
          </div>

          <div className="col-xl-6 col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div className="footer-right float-lg-right float-left mt-4 mt-lg-0">
                <p className="copyright">© 2022 in Jobs Sight Inc.</p>
                <ul className="social-icons mt-0 mt-lg-3 mb-4 mb-lg-0">
                <li>
                  <Link to="#" target="_blank">
                     <FaFacebookF/>
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <FaLinkedinIn/>
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <ImInstagram/>
                  </Link>
                </li>
              </ul>
                <p className="designby position-absolute bottom-0">made with designdot</p>
              </div>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
