import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import JobPipeline from '../MyJob/JobPipeline';
import SavedResumeTable from './SavedResumeTable';
import ResumeFilter from './ResumeFilter';


const SavedResume = () => {
      // JobPipeline
      const jobsPiplineData = [
        { bgClass: 'light-blue-bg', title: 'BOOKMARKED', count: '1' },
        { bgClass: 'light-gray-bg', title: 'APPLIED', count: '-' },
        { bgClass: 'light-gray-bg', title: 'INTERVIEWING', count: '-' },
        { bgClass: 'light-gray-bg', title: 'NEGOTIATING', count: '-' },
        { bgClass: 'light-gray-bg', title: 'ACCEPTED', count: '-' },
      ];
      
  return (
    <>
      <section className='pb-4 pt-4'>
        <div className='fluid-container side-space'>
            <div className='grid-2-container'>
                <div className='left-side'>
                    <LeftSidebar/>
                </div>
                  <div className='mt-smm-0 mt-3'>
                    <ResumeFilter/>
                    <hr/>
                    <div className='d-flex flex-wrap gap-30'>
                      <JobPipeline jobs={jobsPiplineData} />
                    </div>
                    <div className='mt-3'>
                    <SavedResumeTable/>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default SavedResume
