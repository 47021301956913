import React from "react";

import InputTag from "../../../CommonComponents/InputTag";

const ProfileHashTopic = ({ className}) => {

  return (
    <>
      <div className={`card ${className}`}>
        <div className="card-body">
          <div className="heading">
            <h3 className="job-card-title fa-20">Profile Topics</h3>
            <p className="fa-14 black mt-1">
              Your topics will be displayed at the top of your profile to show your audience what you talk about most.
            </p>
          </div>

          <p className="gray fa-14 fw-400">You can add up to 5</p>
            <InputTag title="Add Profile Tag"/>
        </div>
      </div>
    </>
  );
};

export default ProfileHashTopic;
