import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MessageModal = ({ className }) => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  const handleMessageModalsOpen = () => {
    setShowMessageModal(true);
  };

  const handleMessageModalsClose = () => {
    setShowMessageModal(false);
  };

  return (
    <>
    
      <Link t0="/message" className="btn blue-btn py-2 mr-2" onClick={handleMessageModalsOpen}>Message</Link>

      {showMessageModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">New message</h4>
                <button type="button" className="close" onClick={handleMessageModalsClose}>
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                    <div className="form-container mb-3">
                      <div className="form-group">
                        <label htmlFor="message">Message </label>
                        <textarea type="text" className="form-control" placeholder="Write a message"  />
                      </div>
                    </div>
                </div>
                <div className="modal-footer text-right">
                  <button type="submit" className="btn blue-btn">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageModal;
