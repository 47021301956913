import React from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";

import SearchCadidatefillter from "./SearchCadidatefillter";
import SearchCandidateList from "./SearchCandidateList";
import CustomerSupport from "./CustomerSupport";

const SearchCandidate = () => {
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-tracker-section grid-2-container">
            <div className=''>
              <LeftSidebar />
            </div>
            <div className="candidate-explor">
              <div className="grid-2-reverse gap-30 margin-top-1 ">
                <div>
                  <SearchCadidatefillter />
                </div>
                <div className="light-blue-bg  border-radius-14 text-center py-4 px-2" style={{height:'fit-content'}}>
                  <CustomerSupport />
                </div>
              </div>

             

              <SearchCandidateList />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchCandidate;
