import React from 'react';
import Highlights from './Highlights';

const HighlightsData = () => {
  const highlight = [
    {
      heading: 'Longest employer',
      longestEmployer: 'Add companies and dates to find out',
    },
    {
      heading: 'Longest role',
      longestEmployer: 'Add roles to find out',
    },
    {
        heading: 'Average role tenure',
        longestEmployer: 'Add dates to your roles to find out',
      },
  ];

  return (
    <div>
      <Highlights highlight={highlight} />
    </div>
  );
};

export default HighlightsData;
