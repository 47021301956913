import React from 'react';
import AutoAapplied from './AutoAapplied';
import './DashboardInfo.css';
import GettingTask from './GettingStarted';
import TargetGoal from './TargetGoal';
import Analytics from './Analytics'


  const DashboardInfo = () => {
    return (
      <>

       {/* TargetGoal component*/}
         <TargetGoal/>
       {/*end  TargetGoal component*/}

       {/* AutoAapplied component*/}
       <AutoAapplied/>
       {/*end  AutoAapplied component*/}

         <Analytics className="mb-3"/>
        <GettingTask/>
      </>
    )
  }
   export default DashboardInfo;
