import React from 'react';
import ApplyJobsCard from './ApplyJobsCard';
import { BiNotepad } from 'react-icons/bi';

const AppliedJobsData = ({title}) => {

    const AppliedjobsData = [
        {
          title:title,
          items: [
            {
              jobTitle: 'Embedded Software Developer - Contract',
              location: 'UCLA Health, Hampshire, England',
              icon: <BiNotepad />,
              status: 'Be an early applicant',
              time: '1 Hour Ago',
            },
            {
              jobTitle: 'Embedded Software Developer',
              location: 'UCLA Health, Hampshire, England',
              icon: <BiNotepad />,
              status: 'Be an early applicant',
              time: '1 Hour Ago',
            },
            {
              jobTitle: 'Embedded Software Developer - Contract',
              location: 'UCLA Health, Hampshire, England',
              icon: <BiNotepad />,
              status: 'Be an early applicant',
              time: '1 Hour Ago',
            },
          ],
        },
      ];

    
  return (
    <>
       <ApplyJobsCard jobs={AppliedjobsData} boxspace='mb-4' />
    </>
  )
}

export default AppliedJobsData

