import React, { useState } from 'react';

const VerticalTab = ({ tabData }) => {
  const [activeTab, setActiveTab] = useState(tabData.tabs[0]?.id);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <div className="search-question pb-4">
        <h3>{tabData.question}</h3>
      </div>
      <div className="vertical-tab-container">
        <div className="vertical-tab-menu">
          <div className="custom-scrollbar">
            {tabData.tabs.map(({ id, label }) => (
              <div
                key={id}
                className={`tab-item ${activeTab === id ? 'active' : ''}`}
                onClick={() => handleTabClick(id)}
              >
                {label}
              </div>
            ))}
          </div>
        </div>

        <div className="vertical-tab-content">
          {tabData.tabs.map(({ id, content }) => (
            <div
              key={id}
              className={`tab-content-detail ${activeTab === id ? 'active' : ''}`}
            >
              <p>{content}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VerticalTab;
