import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Logo from "../../../assets/jobsight-logo.png";
import user from "../../../assets/user-img.jpg";
import "./NavComponent.css";

function NavComponent() {
  const [isJobOpen, setJobOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const JobdMouseEnter = () => {
    setJobOpen(true);
  };

  const JobMouseLeave = () => {
    setJobOpen(false);
  };


    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);


  // for scroll
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsSticky(scrollTop > 250);
  };


  // Close the navbar when the user navigates to another page
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <>
       <header className={isSticky ? "sticky" : ""}>
        <div className="navigation-wrap theme-bg">
          <div className="container-fluid side-space">
            <nav className="navbar navbar-expand-lg align-items-start align-items-lg-center pl-0 pr-0 d-flex">
              <div className="logo">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} alt="in Job sight Logo" />
                </Link>
              </div>
              <div className="menu-container text-right w-md-50">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={handleMenuToggle} 
                >
                  <div className="d-flex align-items-center">
                    <span className="navbar-toggler-icon"></span>
                    <div className="profile-icon ml-2">
                      <img src={user} alt="user profile" />
                    </div>
                  </div>
                </button>
                <div
                  className={`collapse navbar-collapse justify-content-lg-between justify-content-end align-items-center bg-white-collapse text-left text-lg-right p-2 p-lg-0 ${
                    isMenuOpen ? "show" : ""
                  }`}
                >
                  {/* Close button */}
                  {isMenuOpen && (
                    <button className="btn closebtn" onClick={handleMenuToggle}>
                      &times;
                    </button>
                  )}

                  <div className="left-header-menu">
                    <div className="login-details pl-3 d-desk-none">
                      <div className="d-flex align-items-center">
                        <div className="profile-icon">
                          <img src={user} alt="user profile" />
                        </div>
                        <p className="mb-0 text-white ml-3">Alex Jashua </p>
                      </div>
                    </div>

                    <ul className="navbar-nav mt-4 mt-lg-0">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                         Find Jobs
                        </Link>
                      </li>

                      <li
                        className="nav-item show d-flex align-items-center justify-content-between "
                        onMouseEnter={JobdMouseEnter}
                        onMouseLeave={JobMouseLeave}
                      >
                        <Link
                          className="nav-link"
                          to="#"
                          aria-haspopup="true"
                          aria-expanded={isJobOpen}
                        >
                           For Company
                        </Link>
                        <span className="d-lg-none ml-2">
                          {isJobOpen ? (
                            <FaChevronUp className="white" />
                          ) : (
                            <FaChevronDown className="white" />
                          )}
                        </span>

                        {isJobOpen && (
                          <div
                            className="dropdown-menu"
                            onMouseEnter={JobdMouseEnter}
                            onMouseLeave={JobMouseLeave}
                          >
                            <div className="mega-box">
                              <div className="mega-menu">
                                <ul className="inner-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Why In Job Sight
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Knowledge base
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                      <li className="nav-item">
                        <Link to="/profile" className="nav-link">Profile</Link>
                      </li>

                      <li className="nav-item">
                      <Link to="/dashboard" className="nav-link">Dashboard</Link>
                      </li>

                      <li className="nav-item">
                      <Link to="/notification" className="nav-link">Notifications</Link>
                      </li>
                      <li className="nav-item"><Link to="/message" className="nav-link">Messages </Link></li>
                      
                      <li className="nav-item d-desk-none">
                        <Link to="/job-post" className="nav-link">
                          Post Your Job 
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="right-header-menu text-left text-lg-right w-md-100 p-top d-mob-none">
                    <div className="login-conatiner">
                      <Link to="/job-post">Post Your Job</Link>
                      <span className="vertical-divider d-mob-none"></span>
                      <Link to="/login" className="d-mob-none">
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default NavComponent;
