import React from 'react';
import './WhoAreLooking.css';
import WorkDetailsCard from './WorkDetailsCard';

const WhoAreLooking = () => {
    
  return (
    <>
     
        <div className='container-fluid side-space'>
            <div className='row align-items-center'>
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12'>
                   <div className='work-left'>
                      <h3>We are putting the power in the hands of those who are looking for work.</h3>
                   </div>
                </div>
                <div className='col-xl-9 col-lg-9 col-md-8 col-sm-12 border-left-3 d-flex align-items-center'>
                   <div className='work-card-list' >
                    <WorkDetailsCard
                        title="Candidate"
                        paragraph="We don't only show you sponsored job postings,
                         but new opportunities that are relevant to you every day. <b>While our AI applies to your daily top matches</b>, you may sit back and unwind."
                        />

                      <WorkDetailsCard
                        title="Employer"
                        paragraph="We don't only show you sponsored job postings, but new opportunities that are relevant to you every day. <b>While our AI applies to your daily top matches</b>  you may sit back and unwind."
                        />
                   </div>

                </div>
            </div>
        </div>
       
    </>
  )
}

export default WhoAreLooking
