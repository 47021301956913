import React from "react";
import { Link } from "react-router-dom";

const Analytics = ({ className }) => {
  const analyticsData = [
    {
      value: 164,
      label: "Profile View",
      link: "#",
      timePeriod: "Past 90 Days",
    },
    {
      value: 11,
      label: "Search appearances",
      link: "#",
      timePeriod: "Previous Week",
    },
  ];

  return (
    <>
      <div className='summary-module-wrapper mb-3'>
        <div className='card'>
          <div className='card-body p-0'>
              <div className='apply-content d-flex justify-content-between align-items-center'>
                  {analyticsData.map((item) => (
                    <div className='d-block d-lg-flex justify-content-between align-items-center analytics-border w-100 py-lg-3 p-3'>
                      <div className='apply-lable'>
                      <h4 className="fa-24 black fw-500 fa-mdd-20 mb-0">{item.value}</h4>
                      </div>
                      <div className='recent-apply d-flex'>
                      <p className="fa-14 black fw-400 mb-0">
                        <Link className="black" to={item.link}>
                          {item.label} {item.timePeriod}
                        </Link>
                      </p>
                      </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
