import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const AddSkills = ({ linkText, icon, className }) => {
  const [showAddSkills, setShowAddSkills] = useState(false);
  const [skillCount, setAddSkillsCount] = useState(1);

  const handleAddSkillssOpen = () => {
    setShowAddSkills(true);
  };

  const handleAddSkillssClose = () => {
    setShowAddSkills(false);
  };

  const handleAddMoreAddSkillss = () => {
    setAddSkillsCount(skillCount + 1);
  };

  return (
    <>
      <Link to="" className={`add-new-label ${className}`} onClick={handleAddSkillssOpen}>
        {icon} {linkText}
      </Link>

      {showAddSkills && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add  Skills</h4>
                <button type="button" className="close" onClick={handleAddSkillssClose}>
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  {Array.from({ length: skillCount }).map((_, index) => (
                    <div className="form-container border-bottom-2 mb-3" key={index}>
                      <div className="form-group">
                        <label htmlFor={`project_title_${index}`}>Skill title </label>
                        <input type="text" className="form-control" placeholder="Title" id={`project_title_${index}`} />
                      </div>

                      <div className="form-group">
                        <label htmlFor={`experiance_${index}`}>Experiance</label>
                        <input type="text" className="form-control" placeholder="Ex. 5 Yrs." id={`experiance_${index}`} />
                      </div>


                    </div>
                  ))}

                  <div className='add-more-group my-3'>
                     <button type="button" className="btn btn-blue-outline " onClick={handleAddMoreAddSkillss}>Add More Skills</button>
                  </div>

                </div>
                <div className="modal-footer text-right">
                  <button type="submit" className="btn blue-btn">Submit</button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddSkills;
