import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Modal.css";

const AddInterest = ({ linkText, icon, className }) => {
  const [showInterest, setShowInterest] = useState(false);
  const [skillCount, setInterestCount] = useState(1);

  const handleInterestsOpen = () => {
    setShowInterest(true);
  };

  const handleInterestsClose = () => {
    setShowInterest(false);
  };

  const handleAddMoreInterests = () => {
    setInterestCount(skillCount + 1);
  };

  return (
    <>
      <Link to="" className={`add-new-label ${className}`} onClick={handleInterestsOpen}>
        {icon} {linkText}
      </Link>

      {showInterest && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Interest</h4>
                <button type="button" className="close" onClick={handleInterestsClose}>
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  {Array.from({ length: skillCount }).map((_, index) => (
                    <div className="form-container border-bottom-2 mb-3" key={index}>
                      <div className="form-group">
                        <label htmlFor={`project_title_${index}`}> Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Interest title" id={`project_title_${index}`} />
                      </div>
                    </div>
                  ))}

                  <div className='add-more-group my-3'>
                     <button type="button" className="btn btn-blue-outline " onClick={handleAddMoreInterests}>Add More Interest</button>
                  </div>

                </div>
                <div className="modal-footer text-right">
                  <button type="submit" className="btn blue-btn">Submit</button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddInterest;
