import React from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import JobDescriptionList from "./JobDescriptionList";
import "./ManageJob.css";
import Candidatefillter from "./Candidatefillter";
import ManageJobList from "./ManageJobList";

const Candidate = () => {
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-3-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            <div className="grid-middle mt-smm-0 mt-3">
              <JobDescriptionList />
            </div>

            <div className="grid-right">
              <Candidatefillter />
              <hr />
              <ManageJobList />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Candidate;
