import React from 'react';
import { Link } from "react-router-dom";
import { BsFillQuestionSquareFill ,BsCalendar2Day} from "react-icons/bs";
import {MdOutlineCall} from "react-icons/md";
import {BiTimeFive} from "react-icons/bi";

const HelpContactCard = () => {
  return (
    <>
            <div className="card">
              <div className="card-body">
                <div className="border-radius-8">
                  <div className="">
                    <h4 className="fa-20 light-black fw-500 mb-2">Other ways we can help</h4>
                     <p className="para-text mb-2">
                       <MdOutlineCall className="fa-18 light-black fw-600"/> : 1800-102-5557 , 1800-572-5557
                    </p>
                    <p className=" para-text mb-2">
                      <BiTimeFive className="fa-18 light-black fw-600"/> : 9.30 AM to 6.30 PM
                    </p>
                    <p className="para-text mb-2">
                     <BsCalendar2Day className="fa-18 light-black fw-600"/> : (Monday to Saturday)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="help-blok mt-3">
              <p className="para-text">
                <BsFillQuestionSquareFill /> Questions?
                <span className="ml-2">
                  <Link to="#" className="link-text">
                    Contact Support
                  </Link>
                </span>
                <span className="mx-2">|</span>
                <span>
                  
                  <Link to="#" className="link-text">
                    Help Center
                  </Link>
                </span>
              </p>
            </div>
    </>
  )
}

export default HelpContactCard
