import React from "react";

const PackageCard = ({ title, featurestitle, features, isActive, icon, discountprice, originalPrice}) => {
  return (
    <>
      <div className="card border-radius-14">
        <div className="card-body p-0 bg-light-gray-shades-3 border-radius-14">
          <div className="position-relative">
            <div className="p-2 p-xl-3">
              <h3 className="card-title blue fw-600">{title}</h3>
              <div className="packagelist mt-2">
                <h4>{featurestitle}</h4>
                <ul>
                  {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center bg-blue px-2 px-xl-3 py-1 rounded-8 package-bottom">
              <div className="">
                <div className="d-flex align-items-center">
                  {discountprice ? (
                    <p className="fa-12 white fw-400 mb-0">
                      <span className="fa-24 white fw-500">{discountprice}</span>
                    </p>
                  ) : (
                    <p className="fa-12 white fw-400 mb-0 py-2">first month free</p>
                  )}
                    <span className="fa-16  white fw-400 line-through ml-2">{originalPrice}</span>
                </div>
              </div>
              <div className="">
                <p className="fa-14 white fw-400 mb-0 fa-mdd-12">
                  {icon} {isActive}
                </p>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default PackageCard;
