import React from 'react';
import './HeroContent.css';
import "./JobSearch.css";
import SearchForm from "../../../CommonComponents/SearchForm";

const HeroContent = () => {
  return (
    <>
      
        <div className='container-fluid side-space'>
            <div className='pt-5 pb-5'>
                <div className='hero-heading'>
                    <h1> <span className='yellow-text'>Automatic </span> Applying to jobs is stressful effortlessly</h1>
                    <p>Our passion is to research and select talents and qualified personnel for our Clients and to find the job you are passionate about.</p>
                </div>
                
                <div className='job-search'>
                    <div className="search-conatiner">
                        <div className="search-inner">
                        <div className="search-left">
                            <div className="search-text">
                            <p>Find the best <b>career opportunity</b> for you</p>
                            </div>
                        </div>

                        <div className="search-right">
                            <SearchForm/>
                        </div>
                      </div>
                    </div>
                </div>

                <div className='job-list'>
                    <ul>
                        <li>Remote</li>
                        <li>mNC</li>
                        <li>Information <br/>technology</li>
                        <li>KPO</li>
                        <li>BPO</li>
                        <li>Accounting</li>
                    </ul>
                </div>

            </div> 
        </div>
      
    </>
  )
}

export default HeroContent
