import React from "react";

const JobDescription = () => {
  return (
    <>
      <div className="job-content">
        <h4>Job Description</h4>
        <p>This position is eligible for a $3,000 recruitment bonus.</p>
        <p>
          The Iowa Corrections System is making significant strides to implement
          evidence-based practices system-wide that are proven to reduce
          victimization, reduce recidivism and enhance community safety.
        </p>
        <p>
          We are a nationally recognized leader in corrections for innovation
          and implementation of evidence-based practices. Being on the forefront
          of innovation and practice, we continue our commitment to be the best
          corrections system in the country.
        </p>

        <p>
          In order to accomplish our mission and strategic priorities we will
          embed practices and policies that are supported by research and ensure
          that system-wide change sustains over time through leadership,
          data-driven decision making, continuous quality improvement, input
          from and investment in staff, and collaboration with other state
          agencies and community partners.
        </p>

        <p>
          The Iowa Medical and Classification Center (IMCC) is a multi-faceted
          correctional institution that serves as the male reception center for
          the Iowa Department of Corrections as well as housing a forensic
          psychiatric hospital, short term ambulatory/long term ambulatory
          (STA/LTA), special needs unit and a hospice unit to name a few.
          Correctional Officers are a vital part of IMCC's operations and
          provide safety and security to the prison population and staff. Please
          note that after training, it is likely new hires are scheduled for 2nd
          or 3rd shift and weekends and holidays are required.
        </p>

        <h4>Minimum Qualification Requirements</h4>
        <p>Graduation from high school, GED, or equivalency.</p>

        <h4>Additional Qualification Requirements</h4>
        <p>All applicants must be able to successfully pass the following:</p>
        <ul className="light-black pl-4 fa-16 fw-400">
          <li> Psychological tests</li>
          <li>A background investigation check.</li>
          <li>
            A post-offer health screen administered by an institutional
            physician.
          </li>
          <li>A urinalysis screen for drugs.</li>
          <li>
            All new employee training during the probationary period, including
            local orientation, new employee orientation, and emergency
            preparedness training.
          </li>
          <li>
            Designated positions in this job class require applicants to obtain
            a Commercial Driver's License and endorsements within a period of
            time as determined by the appointing authority at the time of hire.
          </li>
        </ul>
      </div>
    </>
  );
};

export default JobDescription;
