import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import NotificationCard from './NotificationCard';
import './notification.css';


const Notofication = () => {
  return (
    <>
        <section className='pb-4 pt-4'>
          <div className='fluid-container side-space'>
            <div className='grid-3-1-container'>
                <div className='left-side grid-left'>
                    <LeftSidebar/>
                </div>

                <div className='main-content grid-middle mt-smm-0 mt-3 '>
                  <div className='card'>
                        <div className='card-body'>
                            <NotificationCard/>
                            <NotificationCard/>
                            <NotificationCard/>
                        </div>
                  </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Notofication
