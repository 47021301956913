import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Modal.css";

const EditBasicIntro = ({className}) => {
  const [showBasicIntro, setShowBasicIntro] = useState(false);

  const handleBasicIntroOpen = () => {
    setShowBasicIntro(true);
  };

  const handleBasicIntroClose = () => {
    setShowBasicIntro(false);
  };

  return (
    <>
      <Link
        data-toggle="modal"
        data-target="#editintro"
        onClick={handleBasicIntroOpen}
      >
        <AiOutlineEdit className={`gary-text ml-3 fa-24 ${className}`} />
      </Link>

      {showBasicIntro && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit intro</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleBasicIntroClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  <div className="form-container">
                    <div className="form-group">
                      <label htmlFor="first_name">
                        First name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        id="first_name"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="last_name">
                        Last name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        id="last_name"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="additional_name">Additional Name </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Additional name"
                      />
                    </div>

                    <p class="mb-2 small-text">
                      <BsInfoCircle /> This can only be added using our mobile
                      app
                    </p>

                    <div class="form-group">
                      <label for="sel1">Pronouns</label>
                      <select class="form-control" id="sel1">
                        <option>She / Her </option>
                        <option>He / Him</option>
                        <option>They / Them</option>
                        <option>Custom</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="headline">Headline</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Headline"
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="current_position"
                      >
                        Current position
                      </label>
                      <select class="form-control" id="sel1">
                        <option>Please select </option>
                        <option>Web Designer at Designdot Technologies Pvt Ltd</option>
                      </select>
                    </div>

                    
                    <div className="d-sm-flex">
                      <div className="form-group flex-fill mr-3">
                        <label htmlFor="phonenumber">Phone number <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Ex. xxx xxx xxxx" id="phonenumber" />
                      </div>

                      <div className="form-group flex-fill">
                        <label htmlFor="email">Email <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Ex. example@gmail.com" id="email" />
                      </div>
                    </div>
                    
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <textarea className="form-control"  id="address" />
                  </div>

                  <div className="form-group flex-fill">
                      <label htmlFor="birthday">Birthday </label>
                      <input type="date" className="form-control" placeholder="Ex. 29-02-1996" id="birthday" />
                  </div>

                  
                </div>
                  </div>
                
                <div className="modal-footer  text-right">
                  <button type="submit" className="btn blue-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBasicIntro;
