import React from 'react';
import {Link} from 'react-router-dom';
import {BiDownload} from 'react-icons/bi';
import Resume  from '../../../../assets/Resume.pdf'

const ApplicantResumeView = () => {
  return (
    <>
       <div className="card mt-3">
        <div className="card-header bg-transparent border-bottom-0 d-flex justify-content-between">
          <h3 className="card-title fw-500 light-black">
           Resume
          </h3>
          <Link to="" className="link-text fa-16 fw-500"> <BiDownload className='fa-20'/> Download </Link>

        </div>
        <div className="card-body pt-0 pb-0">
          <div className='resume-view'>
             <iframe src={Resume} width="100%" height="400" title="resume"/>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicantResumeView
