import React from "react";
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import PurchaseFilter from './PurchaseFilter';
import PurchaseTable from "./PurchaseTable";

const PurchaseHistory = () => {
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-tracker-section grid-2-container">
           <div className='left-side grid-left'>
              <LeftSidebar />
            </div>
            <div className="candidate-explor  grid-middle">
              <PurchaseFilter />
              <hr />
             
              <div className="mt-3">
              <PurchaseTable/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PurchaseHistory;
