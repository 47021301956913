import React from 'react';
import './BeInTheBest.css';
import BeInTheBestCard from './BeInTheBestCard';

const BeInTheBest = () => {
  return (
    <>
   
        <div className="container-fluid side-space">
            <div className="in-the-best">
                    <h3>Be in the best place you can be. Always</h3>
                    <div className='row mt-5 justify-content-center justify-content-md-start '>
                       <BeInTheBestCard title="Refer a friend" linkUrl="#"/>
                       <BeInTheBestCard title="Looking for job?" linkUrl="#"/>
                       <BeInTheBestCard title="I am hiring?" linkUrl="#"/>
                    </div>
            </div>
        </div>
    
    </>
  )
}

export default BeInTheBest
