import React from "react";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";

const Relevance = [
  { label: "Relevance", value: "Relevance" },
  { label: "Date applied", value: "Date applied" },
  { label: "First name", value: "First name" },
  { label: "Last name", value: "Last name" },
];

const Education = [
  { label: "B. Tech", value: "B. Tech" },
  { label: "M. Tech", value: "M. Tech" },
];


const Ratings = [
  { label: "Unrated", value: "Unrated" },
  { label: "Good fit", value: "Good fit" },
  { label: "Maybe", value: "Maybe" },
  { label: "Not a fit", value: "Not a fit" },
];

const Location = [
  { label: "All", value: "All" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Noida", value: "Noida" },
];

const Experience = [
  { label: "All", value: "All" },
  { label: "2 Year", value: "2 Years" },
  { label: "3 Year", value: "3 Years" },
];

const SkilledIn = [
  { label: "Marketing", value: "Marketing" },
  { label: "Sales", value: "Sales" },
  { label: "Lead Generation", value: "Lead Generation" },
  { label: "Sales and Marketing", value: "Sales and Marketing" },
  { label: "Business Development", value: "Business Development" },
  { label: "Microsoft Excel", value: "Microsoft Excel" },
];

const Candidatefillter = () => {
  const handleFilterChange = (id, selectedOptions) => {
    console.log(`Selected Options for ID:`, selectedOptions);
  };

  return (
    <>
        
          <div className="mt-lg-0 mt-3">
            <h3 className="card-title fw-600 mb-2">
              50  Candidate
            </h3>
          </div>

          <div className="candidate-fillter">
            <div className="">
              <DropdownFilter marginclass="mt-4"
                title="Sort by relevance"
                className="min-width-150 bg-light-gray-1"
                options={Relevance}
                onChange={(selectedOptions) =>
                  handleFilterChange("Relevance", selectedOptions)
                }
                name="All"
              />
            </div>
            
            <div className="">
            <DropdownFilter marginclass="mt-4"
              title="Education"
              className="min-width-150 bg-light-gray-1"
              options={Education}
              onChange={(selectedOptions) =>
                handleFilterChange("Relevance", selectedOptions)
              }
              name="All"
            />
            </div>

            <div className="">
            <DropdownFilter marginclass="mt-4"
              title="Ratings"
              className="min-width-150 bg-light-gray-1"
              options={Ratings}
              onChange={(selectedOptions) =>
                handleFilterChange("Ratings", selectedOptions)
              }
              name="All"
            />
            </div>

            <div className="">
            <DropdownFilter marginclass="mt-4"
              title="Location"
              className="min-width-150 bg-light-gray-1"
              options={Location}
              onChange={(selectedOptions) =>
                handleFilterChange("Location", selectedOptions)
              }
              name="All"
            />
            </div>

            <div className="">
            <DropdownFilter marginclass="mt-4"
              title="Yrs of Experience"
              className="min-width-150 bg-light-gray-1"
              options={Experience}
              onChange={(selectedOptions) =>
                handleFilterChange("Experience", selectedOptions)
              }
              name="All"
            />
            </div>

            <div className="">
              <DropdownFilter marginclass="mt-4"
                title="Skilled In"
                className="min-width-150 bg-light-gray-1"
                options={SkilledIn}
                onChange={(selectedOptions) =>
                  handleFilterChange("SkilledIn", selectedOptions)
                }
                name="All"
              />
            </div>
          </div>
      
    </>
  );
};

export default Candidatefillter;
