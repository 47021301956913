import React from 'react';
import {BiPlusCircle} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import MyJobTable from './MyJobTable';



const MyJobTrackerList = () => {
  return (
    <>

    <div className='d-flex justify-content-between'>
        <div>
            <h4 className='fa-18 black fw-400'>You have <b>5 Records</b></h4>
        </div>
        <div>
           <Link to="/job-search" className="btn blue-btn fw-400 fa-16 rounded-lg"><BiPlusCircle className='fa-24 mr-1'/> Search Job</Link> 
        </div>
    </div>

    <div className='tracker-list mt-4'>
      <MyJobTable/>
    </div>
    </>
  )
}

export default MyJobTrackerList

