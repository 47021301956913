import React, { useRef, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import "./Modal.css";
import bannerImage from '../.../../../../../../assets/bannerbg.webp';

const EditBanner = () => {
  const [showEditBanner, setShowEditBanner] = useState(false);
  const handleShowEditBannerOpen = () => {
    setShowEditBanner(true);
  };

  const handleShowEditBannerClose = () => {
    setShowEditBanner(false);
  };

  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  return (
    <>
      <Link to="#" onClick={handleShowEditBannerOpen}>
        <AiOutlineEdit />
      </Link>

      {showEditBanner && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add background photo</h4>
                <button type="button" className="close" onClick={handleShowEditBannerClose}>
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                  <div className="form-container ">
                    {selectedImage && (
                      <div className='banner-img'>
                        <img src={selectedImage} alt="Preview"  className='img-fluid' style={{maxHeight:'100%'}}/>
                      </div>
                    )}

                    {!selectedImage && (
                      <div className='banner-box'>
                        <img src={bannerImage} className='img-fluid' alt='banner'/>
                        <p className='para-text text-center fa-18x mb-1'>Showcase your personality, interests, team moments or notable milestones</p>
                        <small className='text-center gary-text fa-14'>A good background photo will help you stand out. </small>
                      </div>
                    )}

                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="modal-footer  text-right">
                  <Link className='btn blue-btn' onClick={handleButtonClick}>Edit profile background</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBanner;
