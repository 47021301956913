import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/Style.css";
import "./assets/ColorPalette.css";
import Web from "./components/Layout/Web";
import Home from "./components/Webpage/Homepage/Home";
import Profile from "./components/Webpage/UserPanel/Profile/Profile";
import Login from "./components/Webpage/Login/Login";
import Dashboard from "./components/Webpage/UserPanel/Dashboard/Dashboard";
import PostYourJob from "./components/Webpage/PostYourJob/JobPost";
import JobPostSummary from "./components/Webpage/PostYourJob/JobPostSummary";
import RecommendedJob from "./components/Webpage/UserPanel/RecommendedJob/MyRecommendedJob";
import JobSearchResult from "./components/Webpage/UserPanel/JobSearchResult/JobSearchResult";
import MyJob from "./components/Webpage/UserPanel/MyJob/MyJob";
import AnalyticsProfileTips from "./components/Webpage/UserPanel/Analytics&ProfileTips/Analytics&ProfileTips";
import CompanyEdit from "./components/Webpage/UserPanel/Company/CompanyEdit";
import ManageJobPost from "./components/Webpage/UserPanel/MangeJobPost/ManageJobPost";
import ManageJobView from "./components/Webpage/UserPanel/MangeJobPost/ManageJobView";
import ViewJobPost from "./components/Webpage/UserPanel/MangeJobPost/ViewJobPost";
import ReceiveQualifiedApplicants from "./components/Webpage/PostYourJob/ReceiveQualifiedApplicants";
import SearchCandidate from "./components/Webpage/UserPanel/Candidate/SearchCandidate";
import SavedResume from "./components/Webpage/UserPanel/SavedResume/SavedResume";
import PurchaseHistory from "./components/Webpage/UserPanel/PurchaseHistory/PurchaseHistory";
import Packages from "./components/Webpage/UserPanel/Packages/Packages";
import ComapnyProfileView from "./components/Webpage/UserPanel/Company/ComapnyProfile";
import Notification from "./components/Webpage/UserPanel/Notification/Notofication";
import ViewApplicant from "./components/Webpage/UserPanel/MangeJobPost/ViewApplicant";
import Message from "./components/Webpage/UserPanel/Message/Message";
import PrivacyPolicy from "./components/Webpage/Pages/PrivacyPolicy";
import Help from "./components/Webpage/UserPanel/Settings/Help";
import HelpQATabData from "./components/Webpage/UserPanel/Settings/HelpQATabData";
import Invoice from "./components/Webpage/UserPanel/PurchaseHistory/Invoice/Invoice";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Web />}>
          <Route index element={<Home />} />
          <Route  path="/profile" element={<Profile />} />
          <Route  path="/login" element={<Login />} />
          <Route  path="/dashboard" element={<Dashboard />} />
          <Route  path="/job-post" element={<PostYourJob />} />
          <Route  path="/job-post-summary" element={<JobPostSummary/>} />
          <Route  path="/qualified-applicants" element={<ReceiveQualifiedApplicants/>} />
          <Route  path="/my-recommendedJob-jobs" element={<RecommendedJob />} />
          <Route  path="/job-search" element={<JobSearchResult />} />
          <Route  path="/my-job" element={<MyJob/>} />
          <Route  path="/analytics" element={<AnalyticsProfileTips/>} />
          <Route  path="/company-edit" element={<CompanyEdit/>} />
          <Route  path="/company" element={<ComapnyProfileView/> } />
          <Route  path="/manage-job-post" element={<ManageJobPost/>} />
          <Route  path="/job-view" element={<ManageJobView/>} />
          <Route  path="/view-applicant" element={<ViewApplicant/>} />
          <Route  path="/view-job-post" element={<ViewJobPost/>} />
          <Route  path="/search-candidate" element={<SearchCandidate/>} />
          <Route  path="/save-resume" element={<SavedResume/>} />
          <Route  path="/purchase-history" element={<PurchaseHistory/>} />
          <Route  path="/packages" element={<Packages/> } />
          <Route  path="/notification" element={<Notification/>  } />
          <Route  path="/message" element={<Message/>  } />
          <Route  path="/privacy-policy" element={<PrivacyPolicy/> } />
          <Route  path="/help-center" element={<Help/> } />
          <Route  path="/job-seeker-search-question" element={<HelpQATabData/> } />
          <Route  path="/invoice" element={<Invoice/> } />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
