import './UploadResume.css';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsUpload } from 'react-icons/bs';

const UploadResume = ({title, className}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/docx/pdf*', // Specify the file types to accept
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
    },
  });

  const renderHeader = () => {
    if (selectedFiles.length > 0) {
      return (
        <p>
          {selectedFiles.map((file) => (
            <span key={file.name}>{file.name}</span>
          ))}
        </p>
      );
    } else {
      return <p className='drop-drag-text'>Drag & Drop your resume here or click to select a file (.doc ot pdf)</p>;
    }
  };

  return (
    <div className='dropzone-conatiner'>
      <div className={`common-heading ${className}`}>
         <h3>{title}</h3>
      </div>
     
    <div {...getRootProps()} className={`drag-area mt-3 ${isDragActive ? 'active' : ''}`}>
      <div className="icon">
        <BsUpload />
      </div>
      {renderHeader()}
      <input {...getInputProps()} />
      </div>
    </div>
  );
};

export default UploadResume;
