import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddMediaDropdown from './AddMediaDropdown';
import InputTag from '../../../../CommonComponents/InputTag';
import "./Modal.css";


const AddNewEducation = ({ linkText, icon ,className }) => {

  const [showNewEducation, setShowNewEducation] = useState(false);   //for popup Modal

    // function for  education
    const handleOpenNewEducation = () => {
        setShowNewEducation(true);
    };

    const handleCloseNewEducation = () => {
        setShowNewEducation(false);
    };


  return (
    <>

    
    <Link to="" className={`add-new-label ${className}`} onClick={handleOpenNewEducation}>
      {icon} {linkText}
    </Link>

     {showNewEducation && (
        <div className="modal " style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg opne">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add education</h4>
                <button type="button" className="close" onClick={handleCloseNewEducation}>
                  &times;
                </button>
              </div>
              <form action="">
               <div className="form-container">
                  <div className="modal-body custom-scrollbar">
                    <div className="form-group">
                      <label htmlFor="school ">School <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Ex: Boston University" id="school" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="degree ">Degree <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Ex: Bachelor’s" id="degree" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="study "> Field of study </label>
                      <input type="text" className="form-control" placeholder="Ex: Business" id="study" />
                    </div>

                      <div className='d-sm-flex'>
                        <div class="form-group flex-fill mr-0 mr-sm-3 ">
                          <label for="start_date">Start  </label>
                              <select className="form-control w-100" id="start_date">
                                <option>Please Select</option>
                                <option>January</option>
                                <option>February</option>
                                <option>March</option>
                                <option>April</option>
                                <option>May</option>
                                <option>June</option>
                                <option>July</option>
                                <option>August</option>
                                <option>September</option>
                                <option>October</option>
                                <option>November</option>
                                <option>December</option>
                              </select>
                        </div>

                        <div class="form-group flex-fill ml-0 ml-sm-3">
                          <label for="location">&nbsp;</label>
                          <select class="form-control w-100" id="location">
                            <option>Please Select </option>
                            <option>2023</option>
                            <option>2022</option>
                            <option>2021</option>
                          </select>
                        </div>
                      </div>

                      <div className='d-sm-flex'>
                        <div class="form-group flex-fill mr-0 mr-sm-3 ">
                          <label for="start_date">End  (or expected)</label>
                              <select className="form-control w-100" id="start_date">
                                <option>Please Select</option>
                                <option>January</option>
                                <option>February</option>
                                <option>March</option>
                                <option>April</option>
                                <option>May</option>
                                <option>June</option>
                                <option>July</option>
                                <option>August</option>
                                <option>September</option>
                                <option>October</option>
                                <option>November</option>
                                <option>December</option>
                              </select>
                        </div>

                        <div class="form-group flex-fill ml-0 ml-sm-3">
                          <label for="location" className='hide'>&nbsp;</label>
                          <select class="form-control w-100" id="location">
                            <option>Please Select </option>
                            <option>2023</option>
                            <option>2022</option>
                            <option>2021</option>
                          </select>
                        </div>
                      </div>

                      <div className='form-group pt-0 pt-sm-2  pb-2'>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customCheck" name="work_role"/>
                          <label class="custom-control-label pl-3 pt-0 pt-sm-2" for="customCheck">End current position as of now - Web Designer at Designdot Technologies Pvt Ltd</label>
                        </div>
                      </div>

                        <div className="form-group">
                          <label htmlFor="grade">Grade </label>
                          <input type="text" className="form-control" placeholder="Grade" id="grade" />
                        </div>

                        <div class="form-group">
                          <label htmlFor="activities_and_societies">Activities and societies </label>
                          <textarea className="form-control" rows="5" id="activities_and_societies" placeholder="Ex: Alpha Phi Omega, Marching Band, Volleyball"/>
                        </div>

                        <div class="form-group">
                          <label htmlFor="description">Description </label>
                          <textarea className="form-control" rows="5" id="description" placeholder="Description"/>
                        </div>


                        <h4 className="form-title pt-2 pb-2">Skill </h4>
                        <p className='small-text'>We recommend adding your top 5 used in this role. They’ll also appear in your Skills section.</p>
                      
                        {/* InputTag component */}

                        <InputTag title="Add Skill"/>

                        {/* end InputTag component */}

                        <h4 className="form-title pt-4 pb-2">Media </h4>
                        <p className='small-text'>Add media like images, documents, sites or presentations. Learn more about <Link to="#" className='fa-14 link-text'>media file types supported</Link></p>
                        
                      {/* Media dropdown component*/}

                      <AddMediaDropdown/>

                    {/* end Media dropdown component*/}
                  
                  </div>
                  <div className="modal-footer  text-right">
                      <button type="submit" className="btn blue-btn">Submit</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default AddNewEducation;

