import React, { useState } from "react";
import { BiPlus, BiCheck, BiX } from "react-icons/bi";


const ScreeningTags = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [responseType, setresponsType] = useState("select");

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleRemoveCard = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };


  const onchangeResposneType =(event) => {
    setresponsType(event.target.value);
  }

  const getInputElement = () => {
    if (responseType === 'select') {
      return (
        <select className="form-control">
          <option>Yes</option>
          <option>No</option>
        </select>
      );
    } else if (responseType === 'numeric') {
      return (
        <input className="form-control" type="number" placeholder="Enter a numeric response" />
      );
    }
  };


  const tagData = [
    { value: "Background Check", label: "Background Check" },
    { value: "Hybrid Work", label: "Hybrid Work" },
    { value: "Work Experience", label: "Work Experience" },
    { value: "Education", label: "Education" },
    { value: "Language", label: "Language" },
    { value: "Custom Question", label: "Custom Question" },
  ];

  const cardData = {
    "Background Check": {
      title:
        "Are you willing to undergo a background check, in accordance with local law/regulations?",
      lableforideal: "IdealAnswer",
      lableforinput: "",
      IdealAnswer: "Yes",
    },

    "Hybrid Work": {
      title: "Are you comfortable working in a hybrid setting?",
      lableforideal: "IdealAnswer",
      IdealAnswer: "Yes",
    },
    "Work Experience": {
      title:
        "How many years of [Job Function] experience do you currently have?",
      lableforinput: "Job Function :",
      lableforideal: "Ideal answer (minimum) :",
      inputField: (
        <input
          className="form-control"
          type="text"
          placeholder="Job Function"
        />
      ),
      IdealAnswer: (
        <input
          className="form-control"
          type="number"
          placeholder="Ideal answer"
        />
      ),
    },
    Education: {
      title: "Have you completed the following level of education: [Degree]?",
      lableforinput: "Degree :",
      lableforideal: "Ideal Answer :",
      inputField: (
        <input className="form-control" type="text" placeholder="Degree " />
      ),
      IdealAnswer: "Yes",
    },

    Language: {
      title: "What is your level of proficiency in [Language]?",
      lableforinput: "Language :",
      lableforideal: "Ideal Answer :",
      inputField: (
        <input className="form-control" type="text" placeholder="Language " />
      ),
      IdealAnswer: (
        <select className="form-control">
          <option>None</option>
          <option>Conversational</option>
          <option>Professional</option>
          <option>Native or bilingual</option>
        </select>
      ),
    },

    "Custom Question": {
      title: "Write a custom screening question.",
      lableforcustome: "Help keep LinkedIn respectful and professional. Learn about our custom question guidelines.",
      lableforinput: "Resposne :",
      lableforideal: "Ideal Answer :",
      CustomeField: (
        <textarea
          className="form-control"
          type="text"
          placeholder="Try asking a question like, “Will you be able to bring your own device?” "
        />
      ),
      inputField: (
          <select className="form-control" value={responseType} onChange={onchangeResposneType}>
            <option value="select" defaultValue>Yes / No</option>
            <option value="numeric">Numeric</option>
          </select>
      ),
      IdealAnswer: (
        <>
        {getInputElement()} 
        </>
      ),
    },
  };

  return (
    <div className="screening-block">
      <div className="screening-list d-flex flex-wrap justify-content-start align-items-center mb-3">
        {tagData.map((tag) => (
          <div className="screening-tag" key={tag.value}>
            <button
              className={`btn ${
                selectedTags.includes(tag.value) ? "disabled" : ""
              }`}
              onClick={() => handleTagClick(tag.value)}
              disabled={selectedTags.includes(tag.value)}
            >
              {selectedTags.includes(tag.value) ? (
                <>
                  <BiCheck className="mr-1 fa-20" />
                  <span>{tag.label}</span>
                </>
              ) : (
                <>
                  <BiPlus className="mr-1 fa-20" />
                  <span>{tag.label}</span>
                </>
              )}
            </button>
          </div>
        ))}
      </div>
      {selectedTags.map((tag, index) => (
        <div key={index} className="card  mb-3">
          <div className="card-header d-flex justify-content-between align-items-sm-center align-items-start">
            <h3 className="color-292D32 fw-500 fa-16 lineheight-28 w-90">
              {cardData[tag].title}
              <br className="br-for-sm"/>
              <span className="bg-success text-white py-1 px-2 border-radius-14 fa-14 fw-400 ml-sm-2 ml-0">
                Recommended
              </span>
            </h3>
            <button
              className="remove-btn btn btn-outline-danger p-small-0"
              onClick={() => handleRemoveCard(tag)}
            >
              <BiX className="fa-20" />
            </button>
          </div>
          <div className="card-body">
            <div className="screening-details">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-30">
                {Object.keys(cardData[tag]).map((key) => {
                  if (key === "inputField") {
                    return (
                      <div key={key} className="flex-fill">
                        <div className="form-group">
                          <label>{cardData[tag].lableforinput}</label>
                          {cardData[tag][key]}
                        </div>
                      </div>
                    );
                  } else if (key === "CustomeField") {
                    return (
                      <div key={key} className="flex-fill w-100">
                        <div className="form-group">
                          <label>{cardData[tag].lableforcustome}</label>
                          {cardData[tag][key]}
                        </div>
                      </div>
                    );
                  } else if (key === "IdealAnswer") {
                    return (
                      <div key={key} className="flex-fill">
                        <div className="form-group">
                          <label>{cardData[tag].lableforideal}</label>
                          <p className="para-text light-black fa-18x mb-0">
                            {cardData[tag][key]}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}

                <div className="">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input mr-2"
                        id={`customCheck${index}`}
                        name="qualification"
                      />
                      <label
                        className="custom-control-label pt-1 pl-2"
                        htmlFor={`customCheck${index}`}
                      >
                        Must–have qualification
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScreeningTags;
