
import React from 'react';
import './Highlights.css';

const Highlights = ({ highlight }) => {
  return (
    <div className='high-lights-container mt-4'>
      <div className='common-heading'>
        <h3>Highlights</h3>
      </div>
      {highlight.map((highlight, index) => (
        <div className='High-lights mt-3' key={index}>
          <h4>{highlight.heading}</h4>
          <p>{highlight.longestEmployer}</p>
        </div>
      ))}
    </div>
  );
};




export default Highlights;