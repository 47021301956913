import React from 'react';

import {AiOutlineEdit , AiOutlinePlusCircle} from 'react-icons/ai';
import AddSkills from '../CommonComponents/Modal/AddSkills';
import './TopSkills.css';


const TopSkills = () => {
  return (
    <>
       <div className='profile-summary-container'>
            <div className='profile-summary-header line-height-0 d-flex'>
                <div className='profile-summary-heading'>
                    <h2>Top skills</h2>
                </div>

                <div className='profile-summary-icon'>
                 <AddSkills icon={<AiOutlinePlusCircle className='gray fa-28'/>} className="icon-deafult"  />
                 <AddSkills  icon={<AiOutlineEdit className='gary-text ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
                </div>
            </div>
            <div className='profile-summary-body mt-3'>
                <ul className='profile-summary-list'>
                    <li>
                        <div className='profile-summary-inner-container d-flex justify-content-between'>
                        <div className='profile-view-skill'>
                          <p>Node js </p>  
                        </div>
                        <div className='profile-view-skill-years '>
                            <p>3 Years</p>
                        </div>
                        </div>
                    </li>
                    <li>
                        <div className='profile-summary-inner-container d-flex justify-content-between'>
                        <div className='profile-view-skill'>
                          <p>Python</p>  
                        </div>
                        <div className='profile-view-skill-years '>
                            <p>2 Years</p>
                        </div>
                        </div>
                    </li>

                    <li>
                        <div className='profile-summary-inner-container d-flex justify-content-between'>
                        <div className='profile-view-skill'>
                          <p>React JS </p>  
                        </div>
                        <div className='profile-view-skill-years '>
                            <p>5 Years</p>
                        </div>
                        </div>
                    </li>

                    <li>
                        <div className='profile-summary-inner-container d-flex justify-content-between'>
                        <div className='profile-view-skill'>
                          <p>Java </p>  
                        </div>
                        <div className='profile-view-skill-years '>
                            <p>4 Years</p>
                        </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </>
  )
}

export default TopSkills
