import React from "react";
import "./privacy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="pb-75 pt-4">
        <div className="fluid-container side-space">
          <div class="heading pb-4">
            <h3 className="heading-title mb-0">Privacy Policy </h3>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="privecy-setting">

                <h4 className="fa-16 fw-500 light-black">Introduction</h4>
                <p>
                  We, at Info Edge (India) Limited and our affiliated companies
                  worldwide (hereinafter collectively referred to as "IEIL"),
                  are committed to respecting your online privacy and recognize
                  the need for appropriate protection and management of any
                  personally identifiable information you share with us. This
                  Privacy Policy ("Policy") describes how IEIL collects, uses,
                  discloses and transfers personal information of users through
                  its websites and applications, including through
                  www.naukri.com, mobile applications and online services
                  (collectively, the "Platform"). This policy applies to those
                  who visit the Platform, or whose information IEIL otherwise
                  receives in connection with its services (such as contact
                  information of individuals associated with IEIL including
                  partners) (hereinafter collectively referred to as "Users").
                  For the purposes of the Privacy Policy, "You" or "Your" shall
                  mean the person who is accessing the Platform.
                </p>

                <h4>Types of Personal Information collected by IEIL</h4>
                <p>
                  "Personal information" (PI) - means any information relating
                  to an identified or identifiable natural person including
                  common identifiers such as a name, an identification number,
                  location data, an online identifier or one or more factors
                  specific to the physical, physiological, genetic, mental,
                  economic, cultural or social identity of that natural person
                  and any other information that is so categorized by applicable
                  laws. We collect information about you and/or your usage to
                  provide better services and offerings. The Personal
                  Information that we collect, and how we collect it, depends
                  upon how you interact with us. We collect the following
                  categories of Personal Information in the following ways:
                </p>

                <p>
                  name, email address, password, country, city, contact number
                  and company/organization that you are associated with, when
                  the you sign up for alerts on the Platform; information that
                  one would usually include in a resume, including name, contact
                  details including e-mail address and mobile number, work
                  experience, educational qualifications, data relating to your
                  current and past remuneration or salary, a copy of your
                  resume, etc. when you register on the Platform; information
                  about the services that you use and how you use them,
                  including log information and location information, when you
                  are a user of the services through the Platform; we may
                  collect your Personal Information such as name, age, contact
                  details, preferences, etc. through surveys and forms, when you
                  choose to participate in these surveys etc.; we may also
                  collect information relating to your caste and information
                  about whether you are eligible for any affirmative action
                  programmes or policies, if you opt to provide such
                  information; when you communicate with IEIL or use the
                  Platform to communicate with other Users (such as partners),
                  we collect information about your communication and any
                  information you choose to provide; when you visit the
                  Platform, we use cookies to automatically collect, store and
                  use technical information about your system and interaction
                  with our Platform; when you use a device to access the
                  Platform, IEIL may collect technical or other related
                  information from the device as well as the device location; To
                  the extent permitted by law, IEIL may record and monitor your
                  communications with us to ensure compliance with our legal and
                  regulatory obligations and our internal policies. This may
                  include the recording of telephone conversations; If you
                  choose to sign in with your social media account to access the
                  Platform, or otherwise connect your social media account with
                  the services of the Platform, you consent to our collection,
                  storage, and use, in accordance with this Privacy Policy, of
                  the information that you make available to us through the
                  social media interface. Please see your social media
                  provider's privacy policy and help centre for more information
                  about how they share information when you choose to connect
                  your account.
                </p>

                <h4>Cookies and Other Tracking Technologies</h4>
                <p>
                  Some of our web pages utilize "cookies" and other tracking
                  technologies. A "cookie" is a small text file that may be
                  used, for example, to collect information about web-site
                  activity. Some cookies and other technologies may serve to
                  recall Personal Information previously indicated by a user.
                  Most browsers allow you to control cookies, including whether
                  or not to accept them and how to remove them. You may set most
                  browsers to notify you if you receive a cookie, or you may
                  choose to block cookies with your browser, but please note
                  that if you choose to erase or block your cookies, you will
                  need to re-enter your original user ID and password to gain
                  access to certain parts of the Platform. Tracking technologies
                  may record information such as Internet domain and host names;
                  Internet protocol (IP) addresses; browser software and
                  operating system types; clickstream patterns; and dates and
                  times that our site is accessed. Our use of cookies and other
                  tracking technologies allows us to improve our Platform and
                  the overall website experience. We may also analyse
                  information that does not contain Personal Information for
                  trends and statistics.
                </p>

                <h4>The Basis/ Grounds which we rely on for collection and processing of your Personal Information</h4>
                <p>
                Your Personal Information is collected and processed by IEIL based on the
                 following legal grounds depending upon the nature of Personal Information and 
                 the purposes for which it is processed.
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
