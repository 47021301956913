import React from "react";
import DummyImage from "../../../../assets/dummy.jpg";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";

const NotificationCard = () => {
  return (
    <>
      <div className="notification-card d-flex justify-content-between align-items-center">
        <div className="notification-img">
          <img src={DummyImage} alt="notification" className="img-fluid" />
          <p className="mb-0 ml-3">
            <Link to="#">
              Sarthak Shrivastava posted: Still my top content is @FastAPI I
              love FastAPI and how easy it is Have you tried it, if not check
              out this video Still my top content is @FastAPI I love FastAPI and
            </Link>
          </p>
        </div>

        <div>
          <span className="trashicon">
            <Link to="#">
              <BsTrash />
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
