import React from 'react';
import {Link} from 'react-router-dom';
import BrifcaseIcon from '../../../../assets/brifcase.png';


const HiringRoleCard = () => {
  return (
    <>
      <div className='card mt-3'>
        <div className='card-body'>
            <div className='hiring-role post-job-info d-flex'>
                <div className='hiring-icon mr-3'>
                   <img src={BrifcaseIcon}  alt="Icon" />
                </div>
                <div>
                <h4 className='mb-2'>Hiring for more roles?</h4>
                <Link to="/job-post" className="btn btn-blue-outline">Post new job</Link>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default HiringRoleCard
