import React from 'react';
import {AiOutlineCheck} from 'react-icons/ai';

const MembershipDetails = () => {
  return (
    <>
       <div>
            <h4 className='fa-18 black fw-500 line-height-24'>Upgrade your Membership today and land a job you love sooner!</h4>
            <ul className='packages-plan mt-3'>
                <li><span><AiOutlineCheck className='mt-1 fa-18 mr-2'/></span><span>Get more interviews with unlimited resume analysis</span></li>
                <li><span><AiOutlineCheck className='mt-1 fa-18 mr-2'/></span><span> Unlimited AI-generated Resume Content & Cover Letters</span></li>
                <li><span><AiOutlineCheck className='mt-1 fa-18 mr-2'/></span><span>Unlimited auto-submission your resume as per your skill, experience etc</span></li>
                <li><span><AiOutlineCheck className='mt-1 fa-18 mr-2'/></span><span>Unilimted Save / Down Resume</span></li>
                <li><span><AiOutlineCheck className='mt-1 fa-18 mr-2'/></span><span>Highlight your qualifications with job-specific resume recommendations</span></li>
            </ul>
        </div>
    </>
  )
}

export default MembershipDetails
