import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import JobPipeline from './JobPipeline';
import './MyJob.css';
import JobTrackerList from './MyJobTrackerList';


const JobTracker = () => {

    // JobPipeline

    const jobsPiplineData = [
        { bgClass: 'light-blue-bg',  title: 'BOOKMARKED', count: '1' },
        { bgClass: 'light-gray-bg', title: 'APPLIED', count: '-' },
        { bgClass: 'light-gray-bg', title: 'INTERVIEWING', count: '-' },
        { bgClass: 'light-gray-bg', title: 'NEGOTIATING', count: '-' },
        { bgClass: 'light-gray-bg', title: 'ACCEPTED', count: '-' },
      ];
      


  return (
    <>
      <section className='pb-75 pt-4'>
        <div className='fluid-container side-space'>
            <div className='grid-2-container'>
                <div className='job-tacker-left'>
                <LeftSidebar/>
                </div>
                <div className='job-tacker-right flex-fill'>
                    <div className='job-pipeline-container d-flex flex-wrap gap-30'>
                      <JobPipeline jobs={jobsPiplineData} />
                    </div>

                    <div className='border-bottom-2 my-4'/>
                     
                     <JobTrackerList/>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default JobTracker
