import React from 'react';

const CompanyForm = () => {
   
const handleFileChange = (event) => {
    const fileName = event.target.value.split("\\").pop();
    event.target.nextSibling.classList.add("selected");
    event.target.nextSibling.innerHTML = fileName;
    };

  return (
    <>
      <div className="form-container">
        <form action="">
          <div className="form-group">
            <label htmlFor="name "> Name</label>
            <input type="text"  className="form-control" placeholder="Name"  id="name" />
          </div>

          <div className="form-group">
            <label htmlFor="customFile"> Company Logo </label>
                <div class="custom-file">
                    <input type="file" className="custom-file-input"  id="customFile"   onChange={handleFileChange} />
                    <label className="custom-file-label lineheight-28" htmlFor="customFile">
                        Choose file
                    </label>
                </div>
          </div>

          <div className="form-group">
            <label htmlFor="banner"> Banner </label>
                <div class="custom-file">
                    <input type="file" className="custom-file-input"  id="banner"   onChange={handleFileChange} />
                    <label className="custom-file-label lineheight-28" htmlFor="customFile">
                        Choose file
                    </label>
                </div>
          </div>

          <div className="form-group">
            <label htmlFor="tagline ">Tag Line</label>
            <input type="text"  className="form-control" placeholder="Tag Line"  id="namtaglinee" />
          </div>

          <div class="form-group flex-fill">
            <label htmlFor="industry">Industry</label>
            <select class="form-control w-100" id="industry">
              <option>Please Select </option>
              <option>Accounting</option>
              <option>IT</option>
              <option>Computer Networking</option>
            </select>
          </div>

          <div class="form-group flex-fill">
            <label htmlFor="company-size">Company Size</label>
            <select class="form-control w-100" id="company-size">
              <option>Please Select </option>
              <option>0–5 employees</option>
              <option>5–10 employees</option>
              <option>10–20 employees</option>
              <option>20–50 employees</option>
            </select>
          </div>

          <div class="form-group flex-fill">
            <label htmlFor="company-type">Company Type</label>
            <select class="form-control w-100" id="company-type">
              <option>Please Select </option>
              <option>Public company</option>
              <option>Privately held</option>
              <option>Partnership</option>
              <option>Government agency</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="location ">Location</label>
            <input
              type="text"
              className="form-control"
              placeholder="Location"
              id="location"
            />
          </div>

          <div className="form-group">
            <label htmlFor="website "> Website </label>
            <input
              type="text"
              className="form-control"
              placeholder="Website"
              id="website"
            />
          </div>

          <div className="form-group">
            <label htmlFor="linkedIn "> LinkedIn </label>
            <input
              type="text"
              className="form-control"
              placeholder="LinkedIn"
              id="linkedIn"
            />
          </div>

          <div className="form-group">
            <label htmlFor="yearfounded "> Year Founded </label>
            <input
              type="date"
              className="form-control"
              placeholder="Year Founded"
              id="yearfounded"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description "> Description</label>
            <textarea className="form-control" placeholder="Description..." />
          </div>

          <div className="form-group">
            <label htmlFor="doccument"> Doccument </label>
                <div class="custom-file">
                    <input type="file" className="custom-file-input"  id="doccument"   onChange={handleFileChange} />
                    <label className="custom-file-label lineheight-28" htmlFor="customFile">
                        Choose file
                    </label>
                </div>
          </div>

          <div className="text-right mt-4">
            <button type="submit" className="btn blue-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CompanyForm;
