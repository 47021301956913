import React from 'react';
import { Link } from 'react-router-dom';
import { MdArrowBackIos } from 'react-icons/md'

export default function ForgetPassword({ formChangeHandler }) {
  return (
    <div className="login">
    <div className="p-4 text-center">
      <Link
          to='#'   onClick={(e) => formChangeHandler('login')}   className='btn back-btn'  >
          <MdArrowBackIos  />
            Back to Login
        </Link>
    </div>

    <hr className='m-0'/>

    <div className="login-heading pt-4">
      <h3>Forget Password</h3>
      <p className='label-text text-center mt-3 mb-0'> Forget Your Password?</p>
      <p className='label-text text-center w-60 m-auto'>We'll send you instructions on    how to reset it</p>
    </div>

    {/* form starts here */}
    <form>
        <div className="row form-style p-4">
          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                id="email"
              />
            </div>
          </div>

          <div className="col-xl-12 col-md-12 text-center">
            <button type="submit" className="btn login-btn mt-3">
              Reset
            </button>
          </div>
        </div>
      </form>

      {/* form ends here */}
    </div>
  )
}
