import React, { useState, useEffect, useRef } from 'react';
import './DropdownFilter.css';
import { AiFillCaretDown } from 'react-icons/ai';

const DropdownFilter = ({title, name, options, onChange, className, marginclass }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionToggle = (value) => {
    let updatedOptions;

    if (selectedOptions.includes(value)) {
      updatedOptions = selectedOptions.filter((option) => option !== value);
    } else {
      updatedOptions = [...selectedOptions, value];
    }

    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
   <>
   
    <div ref={dropdownRef} className="dropdown">
    <p className="dropdown-title mb-1 pl-2">{title} </p>
      <button
        type="button"
        className={`btn btn-mute gray fw-400 rounded-30 dropdown-toggle ${className}`}
        onClick={handleDropdownToggle}
      >
        {name} <AiFillCaretDown className="ml-2" />
      </button>
      {isDropdownOpen && (
        <div className={`dropdown-custome show mt-2 ${marginclass}`}>
          {options.map((option) => (
            <label className="dropdown-custome-item" key={option.value}>
              <input
                type="checkbox"
                className="checkbox-input"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOptionToggle(option.value)}
              />
              <span className="custom-checkbox-style">
                {selectedOptions.includes(option.value) && <span className="checkmark-style">&#10003;</span>}
              </span>
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
    </>
  );
};

export default DropdownFilter;
