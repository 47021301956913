import React from "react";
import "./JobSearchResult.css";
import RecentJobsPostData from "../../../CommonComponents/RecentJobsPostData";
import JobSearch from "../../../CommonComponents/SearchForm";
import ToggleSwitch from "../../../CommonComponents/ToggleSwitch";
import DropdownSearchFilterData from "./SearchFilterData";
import JobBasicDetail from "./JobBasicDetail";
import { BsThreeDots } from "react-icons/bs";
import JobDescription from "./JobDescription";
import MobileSearchFilter from "./MobileSearchFilter";

const JobSearchResult = () => {
  return (
    <>
      <section className="pt-4 pb-3">
        <div className="container-fluid side-space">
          {/* search form */}
          <div className="search-result">
            <div className="row">
              <div className="col-xl-10 col-xl-10 col-md-12 col-sm-12 m-auto">
                <JobSearch className="fw-600 black" inputclass="input-bg"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end search form */}

      {/* search result */}
      <section className="py-4 search-filter-container">
        <div className="container-fluid side-space">
          <div className="search-filter-grid">
            <div className="saerc-filter-text w-100">
              <h4 className="fa-16 blue fw-600 fa-sm-14 mb-0">
                Senior Business Deveoper
              </h4>
            </div>

            <div className="search-filter text-right">
              <div className="d-flex gap-30 justify-content-lg-end none-mobile">
                <DropdownSearchFilterData />
              </div>

              <div className="d-flex flex-wrap w-100 none-desktop justify-content-end">
                <MobileSearchFilter/>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*end  search result */}

      <section className="py-4" >
        <div className="container-fluid side-space">
          <div className="grid-2-1-container">
            <div className="search-filter-left grid-left">
              <div className="search-filter-result d-flex justify-content-between">
                <div className="search-counting">
                  <h4 className="fa-14 black fw-600 mb-0">Jobs in India </h4>
                  <p className="fa-14 gray fw-300">350,668 results</p>
                </div>

                <div className="d-flex pr-3">
                  <span className="mr-3 mt-1">Set Job Alert</span>
                  <ToggleSwitch />
                </div>
              </div>

              <div className="recent-job">
                <RecentJobsPostData />
              </div>
            </div>

            <div className="search-filter-right grid-middle">
              <div className="card search-filter-container border-0">
                <div className="card-body">
                  <div className="job-basic-info d-flex justify-content-between">
                    <div>
                      <JobBasicDetail classNameCard="border-0" />
                    </div>
                    <div className="float-right">
                      <div className="three-dot">
                        <BsThreeDots className="gray fa-24" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job-description">
                <div className="card border-0  pt-4">
                  <div className="card-body pl-0">
                   <JobDescription/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobSearchResult;
