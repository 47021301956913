import React from 'react';
import UploadResume from '../../../CommonComponents/UploadResume';

const ResumeBuilder = ({ handleClose }) => {
  return (
    <div className="modal" style={{ display: 'block' }}>
      <div className="modal-dialog modal-md">
        <div className="modal-content border-4">
          <div className="border-bottom-0 text-right pr-3 pt-3">
            <button type="button" className="close" onClick={handleClose}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className='m-auto w-85 w-sm-100'>
            <form>
                <UploadResume title='RESUME BUILDER' className='text-center mb-5'/>
                    <div className='upload-btn mt-5 mb-4 pt-2 text-center'>
                        <button type='submit' className='btn blue-btn rounded-0 px-5 fw-500' value="UPLOAD NOW">UPLOAD NOW</button>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeBuilder;
