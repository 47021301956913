import React from 'react'
import ToggleSwitch from '../../../CommonComponents/ToggleSwitch';
import {Link} from 'react-router-dom' 

const JobPostSettings = () => {
  return (
    <>
       <div className="card">
        <div className="card-header bg-transparent border-bottom-0">
          <h3 className="card-title fw-500 light-black">Save time by automating your hiring workflow </h3>
        </div>
        <div className="card-body pt-0">
          <div className="post-job-info mb-3">
            <h4>Good fit automations </h4>
            <div className='d-sm-flex d-block justify-content-between align-items-center border-bottom-1 py-4'>
                <div>
                 <p>Rate applicants you messaged as “Good fit”  </p>
                 <p className='fa-14'>When you message an unrated applicant using LinkedIn messaging, LinkedIn will auto-rate them as a “Good fit” on your behalf.
</p>
                </div>
                <div>
                 <ToggleSwitch className="mr-3" id="1"/>
                </div>

            </div>
           
          </div>

          <div className="post-job-info mb-3">
            <h4>Not a fit automations </h4>
            <div className='d-sm-flex d-block justify-content-between align-items-center border-bottom-1 py-4'>
                <div>
                 <p>Send rejection emails to applicants you rated as “Not a fit”
</p>
                </div>
                <div>
                 <ToggleSwitch className="mr-3" id="2"/>
                </div>

            </div>

            <div className='d-sm-flex d-block  justify-content-between align-items-center border-bottom-1 py-4'>
                <div>
                 <p>Rate applicants who are out-of-country as “Not a fit”</p>
                </div>
                <div>
                 <ToggleSwitch className="mr-3" id="3"/>
                </div>

            </div>

            <div className='d-sm-flex d-block justify-content-between align-items-center border-bottom-1 py-4'>
                <div>
                 <p>Rate applicants who don’t meet required screening criteria as “Not a fit”
</p>
                </div>
                <div>
                 <ToggleSwitch className="mr-3" id="4"/>
                </div>

            </div>

            <div className="post-job-info pt-4">
            <h4>Rejection email preview </h4>
            <p className='fa-14 line-height-18 mt-3'>Applicants will receive this message from LinkedIn 3 
            calendar days after rated as “Not a fit”. Should you change your mind,
             change their rating before the message is sent <Link to="" className="link-text">Learn More</Link></p>

             <p className='mt-3 light-black'>Thank you for your interest in the Tester position at Designdot Technologies Pvt Ltd in Noida,
                 Uttar Pradesh, India. Unfortunately, we will not be moving forward with your
                  application, but we appreciate your time and interest in Designdot Technologies Pvt Ltd.</p>

            <div className='mt-3'>
                <p className='text-left light-black'>
                    Regards,<br/>
                    Designdot Technologies Pvt Ltd
                </p>
            </div>
          </div>
           
          </div>
        </div>
      </div>
    </>
  )
}

export default JobPostSettings
