import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../Webpage/UserPanel/Profile/ProfileModal/Modal.css";
import { ImArrowUpRight2 } from "react-icons/im";
import UserImg from "../../../../assets/user-img.jpg";
import UploadResume from "./UploadResume";
import { FaFilePdf, FaFileWord } from "react-icons/fa";

const JobApply = ({ className }) => {
  const [showJobApply, setShowJobApply] = useState(false); //for popup Modal
  const [step, setStep] = useState(1); //for multistep form

  //for popup Modal function
  const handleJobApplyOpen = () => {
    setShowJobApply(true);
  };

  const handleJobApplyClose = () => {
    setShowJobApply(false);
  };

  //for multistep form function
  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const progress = ((step - 1) / 2) * 100; // Calculate the progress percentage

  return (
    <>
      <Link
        className="btn blue-btn fw-500 fa-16 fa-mdd-14 py-2 mr-3"
        onClick={handleJobApplyOpen}
      >
        Apply Now <ImArrowUpRight2 />
      </Link>

      {showJobApply && (
        <div className="modal " style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Apply To Crossever</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleJobApplyClose}
                >
                  &times;
                </button>
              </div>

              <div className="form-container">
                <div className="modal-body">
                  <div className="progress" style={{ height: "10px" }}>
                    <div
                      className="progress-bar bg-success rounded-0"
                      style={{ width: `${progress}%`, height: "10px" }}
                    />
                  </div>

                  {step === 1 && (
                    <div className="pt-3">
                      <form onSubmit={handleNext}>
                        <div className="form-container pr-3 custom-scrollbar ">
                          <div className="profile-intro">
                            <h4 className="fa-18 fa-sm-16 fw-600 light-black">
                              Contact info
                            </h4>
                            <div className="Posted-by d-flex mt-4 mb-4">
                              <div className="hiring-profile img mr-2">
                                <img src={UserImg} alt="profile " />
                              </div>
                              <div className="Posted-profile-detail">
                                <h4 className="fa-18 fa-mdd-16 fa-sm-14 black-shades-1 fw-500 mb-0">
                                  Shakti Yadav
                                </h4>
                                <p className="fw-400 fa-16 fa-mdd-14 fa-sm-12 light-black mb-0">
                                  Web Designer at DesignDot Technologies Pvt Ltd
                                </p>
                                <p className="fw-400 fa-14 fa-sm-12 gray mb-0">
                                  Noida, Uttar Pradesh, India
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="firstname ">
                              First Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              id="firstname"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="lastname ">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              id="lastname"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="lastname ">
                              Phone Country Code
                            </label>
                            <select className="form-control">
                              <option>Select An Option</option>
                              <option selected>India (+91)</option>
                              <option>Thailand (+66)</option>
                              <option>Singapor (+65)</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="phone ">
                              Mobile phone number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="9918569201"
                              id="phone"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="title ">
                              Email address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Designdot@gmail.com"
                              id="Email"
                            />
                          </div>

                          <p className="small-text gray">
                            Submitting this application won’t change your
                            profile.
                          </p>
                        </div>

                        <div className="pt-4 text-right">
                          <button type="submit" className="btn blue-btn">
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {step === 2 && (
                    <div className="pt-3">
                      <h4 className="fa-18 fw-600  fa-sm-16 light-black">Resume</h4>
                      <p className="fa-14 fw-400 gray">
                        Be sure to include an updated resume
                      </p>
                      <form onSubmit={handleNext}>
                        {/* upload resume */}
                        <UploadResume />
                        {/* upload resume */}

                        <div className="pt-4 text-right">
                          <button
                            type="button"
                            className="btn btn-gary text-black mr-2 px-4 fw-500 "
                            onClick={handlePrevious}
                          >
                            Back
                          </button>
                          <button type="submit" className="btn blue-btn">
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {step === 3 && (
                    <div className="pt-3 ">
                      <div className="form-container custom-scrollbar  pr-3">
                        <h4 className="fa-18  fa-sm-16 fw-600 light-black">
                          Review your application
                        </h4>
                        <p className="fa-14 fw-400 gray">
                          The employer will also receive a copy of your profile.
                        </p>
                        <hr />

                        <div className="review">
                          <div className="Posted-by d-flex mt-4 mb-4">
                            <div className="hiring-profile img mr-2">
                              <img src={UserImg} alt="profile " />
                            </div>
                            <div className="Posted-profile-detail">
                              <h4 className="fa-18 fa-mdd-14 black-shades-1 fw-500 mb-0">
                                Shakti Yadav
                              </h4>
                              <p className="fw-400 fa-16 fa-mdd-14 fa-sm-12 light-black mb-0">
                                Web Designer at DesignDot Technologies Pvt Ltd
                              </p>
                              <p className="fw-400 fa-14 fa-sm gray mb-0">
                                Noida, Uttar Pradesh, India
                              </p>
                            </div>
                          </div>

                          <div className="form-group">
                            <span className="fa-14 gray fw-300">
                              Email address *
                            </span>
                            <p className="fa-16 light-black fw-300">
                              shaktisunny10@gmail.com
                            </p>
                          </div>

                          <div className="form-group">
                            <span className="fa-14 gray fw-300">
                              Mobile phone number *
                            </span>
                            <p className="fa-16 light-black fw-300">
                              +91-9918568312
                            </p>
                          </div>
                        </div>

                        <hr />

                        <div className="review">
                          <h4 className="fa-18 fa-sm-16 fw-600 light-black">Resume</h4>
                          <span className="fa-14 gray fw-300">
                            Be sure to include an updated resume *
                          </span>

                          <div class="file-icon my-3">
                            <p class="mb-0">
                              <FaFilePdf className="pdf-icon" />
                              <span class="pl-2 fw-500"> ddntodelhi.pdf</span>
                            </p>
                          </div>

                          <div class="file-icon my-3">
                            <p class="mb-0">
                              <FaFileWord className="doc-icon" />
                              <span class="pl-2 fw-500"> ddntodelhi.doc</span>
                            </p>
                          </div>
                        </div>

                        <div className="pt-4 text-right">
                          <button
                            className="btn btn-gary text-dark mr-2 px-4 fw-500 mb-sm-0 mb-1"
                            type="button"
                            onClick={handlePrevious}
                          >
                            Back
                          </button>
                          <button className="btn blue-btn" type="submit">
                            Submit Application
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobApply;
