import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../Profile/ProfileModal/Modal.css";


const EditTargetGoal = ({ linkText, icon ,className }) => {

  const [showNewEducation, setShowNewEducation] = useState(false);   //for popup Modal

    // function for  education
    const handleOpenNewEducation = () => {
        setShowNewEducation(true);
    };

    const handleCloseNewEducation = () => {
        setShowNewEducation(false);
    };


  return (
    <>

    
    <Link to="" className={`${className}`} onClick={handleOpenNewEducation}>
      {icon} {linkText}
    </Link>

     {showNewEducation && (

        <div className="modal " style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg opne">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Goals </h4>
                <button type="button" className="close" onClick={handleCloseNewEducation}>
                  &times;
                </button>
              </div>
              <form action="">
               <div className="form-container text-left">
                  <div className="modal-body custom-scrollbar">
                    <div className="form-group">
                      <label htmlFor="title ">Target Title</label>
                      <input type="text" className="form-control" placeholder="Target Title" id="title" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="date ">Target Date </label>
                      <input type="date" className="form-control" placeholder="Target Date" id="date" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="salary "> Salary Min </label>
                      <input type="number" className="form-control" placeholder="Salary Min" id="salary" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="salary "> Salary Max </label>
                      <input type="number" className="form-control" placeholder="Salary Max" id="salary" />
                    </div>

                    <div class="form-group flex-fill">
                        <label htmlFor="currency">Currency</label>
                        <select class="form-control w-100" id="currency">
                        <option>Please Select </option>
                        <option>Indian Rupee</option>
                        <option>US Dollar</option>
                        </select>
                    </div>
                  </div>

                  <div className="modal-footer  text-right">
                      <button type="submit" className="btn blue-btn">Submit</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default EditTargetGoal;

