import React from 'react';
import {Link} from 'react-router-dom';


const BeInTheBestCard = (props) => {
  const { title, linkUrl } = props;

  return (
    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6'>
      <div className='in-the-best-card '>
        <Link to={linkUrl} className="btn light-blue-btn">{title}</Link>
      </div>
    </div>
  );
};

export default BeInTheBestCard;
