import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import UserImage from "../../../assets/user-img.jpg";
import AddScreeningQuestions from "./AddScreeningQuestions";
import PreviewJobPost from "./PreviewJobPost";

const ReceiveQualifiedApplicants = () => {
  useEffect(() => {
    document.body.classList.add("job-post-bg"); // Add the class to the body element
    return () => {
      document.body.classList.remove("job-post-bg"); // Remove the class when the component is unmounted
    };
  });

  //for onchange  for receive applicants
  const [SelectedOption, SetSeletedOption] = useState("By email");

  const handelSelectOption = (event) => {
    SetSeletedOption(event.target.value);
  };

  return (
    <>
      <section className="pt-4 pb-75">
        <div className="container-fluid side-space">
          <div className="job-post-summary col-xl-8 col-lg-8 col-md-12 col-sm-12 m-auto p-0">
            <div className="card">
              <div className="card-header bg-transparent">
                <h3 className="card-title">Receive qualified applicants</h3>
              </div>
              <div className="card-body">
                <div className="job-role-form form-container">
                  <form>
                    <h3 className="form-title mb-3 ">Applicant collection </h3>
                    <div className="d-flex flex-md-nowrap flex-wrap justify-content-between">
                      <div className="w-100 mr-sm-2">
                        <div className="form-group">
                          <label htmlFor="description">
                            Receive applicants
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            value={SelectedOption}
                            onChange={handelSelectOption}
                          >
                            <option value="By email">By email</option>
                            <option value="At an external website">
                              At an external website
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="w-100 ml-sm-2">
                        <div className="form-group">
                          <label htmlFor="email">
                            Email address
                            <span className="text-danger">*</span>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="example@gmail.com"
                            id="email"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Conditional rendering based on the selected option */}

                    {SelectedOption === "By email" ? (
                      <div className="mt-3">
                        <h3 className="form-title m2-3 ">
                          Screening questions
                        </h3>
                        <p className="para-text">
                          We recommend adding 3 or more questions. Applicants
                          must answer each question.
                        </p>

                        <p className="para-text mt-4">
                          Add screening questions:
                        </p>

                        {/* Add Screening Questions */}

                        <AddScreeningQuestions/>

                        {/* Add Screening Questions */}
                      </div>
                    ) : (
                      <div className="mt-3">
                        <p className="para-text">
                          <b>
                            Receive applicants by email to use screening
                            questions.
                          </b>
                          Screening questions can’t be collected from applicants
                          when they apply on an external site. If you would like
                          to collect answers to screening questions, please
                          choose to receive applicants by email.
                        </p>
                      </div>
                    )}

                    <hr />

                    <div className="hiring-block-detail d-flex align-items-center">
                      <div className="hiring-profile mr-2">
                        <img src={UserImage} alt="Hiring Profile" />
                      </div>

                      <p className="para-text mb-0">
                        The <b>#Hiring photo frame</b> will be added to your
                        profile. We’ll notify your network that you’re hiring.
                      </p>
                    </div>

                    <p className="para-text mt-4">
                      By continuing, you agree to In job sight
                      <Link to="" className="link-text">
                        Jobs Terms and Conditions
                      </Link>
                      including our
                      <Link to="" className="link-text">
                        policies prohibiting discriminatory job posts
                      </Link>
                      .
                    </p>

                    <hr />

                    <div className="d-flex d-for-mob-block justify-content-between ">
                      <div className="text-left mb-2 mb-sm-0">
                        <PreviewJobPost/>
                      </div>

                      <div className="text-right">
                        <Link
                          to="/job-post-summary"
                          className="btn btn-outline-secondary border-radius-50 w-sm-100 px-4 py-2 border-width fw-500 mr-2 mb-2 mb-sm-0"
                        >
                          Back
                        </Link>
                        <Link to="#" className="btn blue-btn ml-sm-2 ml-0 fw-500 w-sm-100 mb-2 mb-sm-0">
                          Continue
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReceiveQualifiedApplicants;
