import React from "react";
import Icon from "../../assets/icon.png";
import "./ApplyJobsCard.css";


const ApplyJobsCard = ({ jobs, className , classNameCard, Classtitle , boxspace , iocn, headingstyle}) => {
  return (
    <>
      <div className={`${boxspace}`} >
        {jobs.map((job, index) => (
          <div key={index}>
            <div className="heading">
              <h3 className={`job-card-title ${Classtitle}`}>{job.title}</h3>
              <p className="fa-14 black mt-1">{job.subtitle}</p>
            </div>
            <div className={`${className}`}>
              {job.items.map((item, itemIndex) => (
                <div className={`job-card ${classNameCard}`} key={itemIndex}>
                  <div className="job-card-icon">
                    <img src={Icon} className="img-fluid" alt="job icon" />
                  </div>
                  <div className="job-card-content">
                    <h4 className={`${headingstyle}`}>{item.jobTitle}</h4>
                    <p>{item.location}</p>
                    <div className="job-content-inner d-sm-flex d-block align-items-center mt-2 justify-content-between">
                      <div className="job-content-inner-icon d-flex  align-items-center">
                       {item.icon}  <p className="pl-1">{item.status}</p>
                      </div>
                      <div className="job-content-inner-content d-flex justify-content-start justify-content-sm-end mt-sm-0 mt-2">
                        <p>{item.time}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ApplyJobsCard;
