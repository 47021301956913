import React  from 'react';
import MenuList from './MenuList';

const MenuListData = () => {
  const menuData = {
    menuItems: [

      {
        title: 'Profile', 
        link: '/profile',
        items: [ 
          
        ],
      },
      {
        title: 'Dashboard', 
        link: '/dashboard',
       
        items: [
        ],
      },
      {
        title: 'My Job', 
        link: '/my-job',
        items: [
          { label: 'Recommended Job', link: '/my-recommendedJob-jobs' },
          { label: 'Resume Builder', modalContent: 'ResumeBuilder' },
          { label: 'Jobs Alert', modalContent: 'JobAlert' },
          { label: 'Refer a friend', link: '/#'  },
        
        ],
      },
      {
        title: 'Employer',
        items: [
          { label: 'Company Page', link: '/company' },
          { label: 'Manage Job Post', link: '/manage-job-post' },
          { label: 'Search Candidate', link: '/search-candidate' },
          { label: 'Saved Resume', link: '/save-resume' },
        ],
      },
      {
        title: 'Purchase History',
        items: [],
        link: '/purchase-history',
      },
      {
        title: 'Packages',
        items: [],
        link: '/packages',
        
      }, 
      {
        title: 'Misc',
        items: [
          { label: 'Messages', link: '/message' },
          { label: 'Notifcation', link: '/notification' },
        ],
      },
      {
        title: 'Settings',
        items: [
          // { label: 'Privacy Setting', link: '/privacy-setting' },
          { label: 'Help', link: '/help-center' },
          { label: 'Log out', link: '/Log out' },
        ],
      },
    ],
  };

  const customClasses = {
    Dashboard: 'hide-text-for-desc  sub-text-hide', 
    Profile: 'hide-text-for-desc sub-text-hide',   
  };

    const isMobile = window.innerWidth <= 768; 
   

  return (
    <div>
      {/* Other components */}
      <MenuList
        menuData={{
          ...menuData,
          menuItems: menuData.menuItems.filter(
            item => (isMobile || (item.title !== 'Profile' && item.title !== 'Dashboard'))  ),
        }}
        customClasses={customClasses}
      />
      {/* Other components */}
    </div>
  );
};

export default MenuListData;
