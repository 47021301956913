import React from 'react';
import { Link } from 'react-router-dom';
import './MyJob.css';

const JobPipeline = ({ jobs }) => {
  return (
    <>
      {jobs.map((job, index) => (
        <div className={`job-pipline-box flex-fill ${job.bgClass}`} key={index}>
          <Link to="#" className="black">
            <span className="fa-24 fa-mdd-20 black fw-600">{job.count}</span>
            <p>{job.title}</p>
          </Link>
        </div>
      ))}
    </>
  );
};

export default JobPipeline;
