import React from "react";
import dummyImage from "../../../../assets/dummy.jpg";
import {Link} from 'react-router-dom';
import ApplicantResumeView from "./ApplicantResumeView";
import ApplicantScreeningQuestionRespons from "./ApplicantScreeningQuestionRespons";

const InsightFromProfile = () => {
  return (
    <>
      <div className="card mt-3">
        <div className="card-header bg-transparent border-bottom-0">
          <h3 className="card-title fw-500 light-black">
            Insights from profile
          </h3>
        </div>
        <div className="card-body pt-0 pb-0">
          <div className="post-job-info mb-3">
            <h4>Experience </h4>
            <div className="mt-3">
              <div className="applicant-icon d-flex">
                <img src={dummyImage} alt="applicant" className="mr-3" />
                <div>
                  <p>Business Development Executive</p>
                  <p className="fa-14 gray">Chain-Sys Corporation</p>
                  <p className="fa-14 gray"> 2023-Present</p>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="applicant-icon d-flex">
                <img src={dummyImage} alt="applicant" className="mr-3" />
                <div>
                  <p>Sales Consultant</p>
                  <p className="fa-14 gray">ODINT Consulting</p>
                  <p className="fa-14 gray"> 2022-2023</p>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className="post-job-info mb-3">
            <h4>Education </h4>
            <div className="mt-3">
              <div className="applicant-icon d-flex">
                <img src={dummyImage} alt="applicant" className="mr-3" />
                <div>
                  <p>Great Lakes Institute of Management</p>
                  <p className="fa-14 gray">Postgraduate Degree, Data Science and Business Analytics</p>
                  <p className="fa-14 gray"> 2022-2023</p>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="applicant-icon d-flex">
                <img src={dummyImage} alt="applicant" className="mr-3" />
                <div>
                  <p>Mary Wanamaker Girls Intermediate College </p>
                  <p className="fa-14 gray">Intermediate</p>
                  <p className="fa-14 gray"> 2014-2016</p>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-center bg-transparent">
             <Link to="" className="link-text fw-500 fa-18 text-center" >See full profile</Link>
          </div>
        </div>
      </div>
      <ApplicantResumeView/>
      <ApplicantScreeningQuestionRespons/>
    </>
  );
};

export default InsightFromProfile;
