import React from "react";
import JobpostInfo from "./JobpostInfo";
import JobPostSettings from "./JobPostSettings";
import ViewScreeningquestion from './ViewScreeningquestion';

const ViewJobPostDescription = () => {

  return (
    <>
      <div className="job-info-tab">
        <ul className="nav nav-pills">
          <li className="job-info-custme-item">
            <a
              className="job-info-custme-link active"
              data-toggle="pill"
              href="#jobinfo"
            >
              Job Info
            </a>
          </li>

          <li className="job-info-custme-item">
            <a
              className="job-info-custme-link"
              data-toggle="pill"
              href="#setting"
            >
              Settings
            </a>
          </li>
        </ul>
      </div>

      <div class="tab-content mt-3">
        <div class="tab-pane active" id="jobinfo">
         <JobpostInfo/>
         <ViewScreeningquestion/>
        </div>

        <div class="tab-pane" id="setting">
            <JobPostSettings />
        </div>
      </div>
    </>
  );
};

export default ViewJobPostDescription;
