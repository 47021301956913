import React from "react";
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import MembershipDetails from "./MembershipDetails";
import PackageCard from "./PackageCard";
import { AiOutlineCheck } from "react-icons/ai";
import   './Packages.css';



const Packages = () => {

    const packageCardData1 = {
        title: "Free",
        featurestitle:'Limited formats in lower resolution.',
        features: [
          "Limited formats in lower resolution.",
          "Unlimited Job Search",
          "Auto apply your resume etc."
        ],
        isActive: 'Active',
        icon: <AiOutlineCheck className="mt-1 fa-18 mr-1" />,
        discountprice: "",
        originalPrice: ""
      };
    
      const packageCardData2 = {
        title: "1 Month",
        featurestitle:'Limited formats in lower resolution.',
        features: [
          "Limited formats in lower resolution.",
          "Unlimited Job Search",
          "Auto apply your resume etc."
        ],
        isActive: 'Subscription',
        icon: '',
        discountprice: "$15",
        originalPrice: "$20"
      };

      const packageCardData3 = {
        title: " 3 Month",
        featurestitle:'Limited formats in lower resolution.',
        features: [
          "Limited formats in lower resolution.",
          "Unlimited Job Search",
          "Auto apply your resume etc."
        ],
        isActive: 'Subscription',
        icon: '',
        discountprice: "$35",
        originalPrice: "$40"
      };
    

      const packageCardData4 = {
        title: "Free",
        featurestitle:'Limited formats in lower resolution.',
        features: [
          "Unlimited Candidate Search",
          "Unlimited save resume",
          "1 Job Post"
        ],
        isActive: 'Active',
        icon: <AiOutlineCheck className="mt-1 fa-18 mr-1" />,
        discountprice: "",
        originalPrice: ""
      };
    
      const packageCardData5 = {
        title: "Pay Per Job Post",
        featurestitle:'Limited formats in lower resolution.',
        features: [
          "Unlimited Candidate Search",
          "Unlimited save resume",
          "1 Job Post"
        ],
        isActive: 'Subscription',
        icon: '',
        discountprice: "$100",
        originalPrice: "$150"
      };

      const packageCardData6 = {
        title: "1 Year Plan",
        featurestitle:'Limited formats in lower resolution.',
        features: [
          "Unlimited Candidate Search",
          "Unlimited save resume",
          "1 Job Post"
        ],
        isActive: 'Subscription',
        icon: '',
        discountprice: "$735",
        originalPrice: "$100"
      };
    


  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-tracker-section grid-2-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>
            <div className="main-content grid-middle">
                <div className="card border-0 p-0">
                    <div className="card-header bg-transparent border-0  px-0">
                        <h3 className="card-title">Card Title</h3>
                        <p className="fa-16 light-black fw-400 mb-0">All paid plans allow for the use of assets with time limit. Each account is for one user only. If you are a team, please contact us.</p>
                    </div>
                    <div className="card-body mt-3 mt-xl-4 px-0">
                        <div className="packages-grid w-100">
                            <div>
                               <MembershipDetails/>
                            </div>

                            <div className="package-listed">
                                <div>
                                    <div className="package-heading mb-4">
                                        <h3 className="fa-24 fw-600 black py-1">Candidate</h3>
                                        <div className="blue-line pl-4"></div>
                                    </div>
                                    
                                    <div className="display-grid">
                                        <PackageCard {...packageCardData1} />
                                        <PackageCard {...packageCardData2} />
                                        <PackageCard {...packageCardData3} />
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <div className="package-heading mb-4">
                                        <h3 className="fa-24 fw-600 black border-bottom-blue py-1">Recruiter / Company</h3>
                                        <div className="blue-line w-70 ml-2"></div>
                                    </div>
                                    <div className="display-grid ">
                                        <PackageCard {...packageCardData4} />
                                        <PackageCard {...packageCardData5} />
                                        <PackageCard {...packageCardData6} />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            
              
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Packages;
