import React from 'react';
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import JobPipeline from '../MyJob/JobPipeline';
import JobPostList from './JobPostList';
import '../../../../assets/global.css'


const JobTracker = () => {

    // JobPipeline

    const jobsPiplineData = [
        { bgClass: 'light-blue-bg',  title: 'DRAFT', count: '1' },
        { bgClass: 'light-gray-bg', title: 'ONGOING', count: '-' },
        { bgClass: 'light-gray-bg', title: 'PAUSED', count: '-' },
        { bgClass: 'light-gray-bg', title: 'CLOSED', count: '-' },
      ];
      


  return (
    <>
      <section className='pb-4 pt-4'>
        <div className='fluid-container side-space'>
            <div className='grid-2-container'>
                <div className='left-side'>
                 <LeftSidebar/>
                </div>
                
                <div className='manage-job-main'>
                    <div className='job-pipeline-container '>
                       <div class="heading mb-sm-2 mt-3 mb-3  mt-smm-0">
                        <h3 class="job-card-title fa-20"> Posted Jobs</h3>
                      </div>

                      <div className='d-grid-sm d-grid-lg d-grid-md gap-30'>
                        <JobPipeline jobs={jobsPiplineData} />
                      </div>
                    </div>

                    <div className='border-bottom-2 my-4'/>
                     
                     <JobPostList/>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default JobTracker
