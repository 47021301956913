import React from "react";
import {Link} from "react-router-dom";

const CustomerSupport = () => {
  return (
    <>
      <p className="fa-18 fw-500 black px-2">
        You do not have <b>active subscription</b> for Candidate search
      </p>
      <h3 className="py-3 border-blue"><Link className="fa-24 fa-sm-20 black" to="/packages">BUY NOW</Link></h3>

      <h3 className="card-title mt-3">Customer Support ?</h3>
      <div className="text-center">
        <Link to="#" className="black fa-14 fw-500">
          1800 102 5558
        </Link>
        <span className="mx-2">|</span>
        <Link to="#" className="black fa-14 fw-500">
      
          support@abc.com
        </Link>
      </div>
    </>
  );
};

export default CustomerSupport;
