import React from 'react'
import {BsSearch} from "react-icons/bs";

const HelpSearch = () => {
  return (
    <>
       <form className="search-form" action="">
            <input type="text" placeholder="Search by keyword..." name="search"/>
            <button type="submit"><BsSearch className="fa-24"/> </button>
      </form>
    </>
  )
}

export default HelpSearch
