import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import Education from "../../../CommonComponents/Education";

const ManageJobList = () => {
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});
 

  const handleItemMouseEnter = (item, event) => {
    setSelectedItem(item);
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const position = {
      top: rect.top + rect.height + scrollTop + 10, // Adjust this value
      left: rect.left + scrollLeft,
    };

    const centerX = rect.left + rect.width / 2;
    const boxWidth = '1600';
    const boxLeft = centerX - boxWidth / 2;

    setBoxPosition({
      ...position,
      left: boxLeft,
    });

    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };



  return (
    <>
    
      <div className="job-tracker-container table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
              Name <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Res. match <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Skill match <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Education <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Current Job <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Exp. <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Location <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
              Rated <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th className="text-right">
                <CiSliderVertical className="fa-20 black fw-700" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="j-gray-bg align-items-center border-18">
              <td style={{ width: "150px" }}>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("Condidate", e)}
                  onClick={() => handleItemClick("Condidate")}
                  className={selectedItem === "Condidate" ? "active" : ""}
                >
                  <p className="fa-14 fw-600 light-black mb-0">Rahul Sivastav</p>
                </Link>
              </td>
              <td>
                <span className="mb-2">70%</span>
                <div className="progress" style={{ height: "15px", borderRadius: "14px" }}>
                  <div className="progress-bar bg-dark-blue" style={{ width: "70%", height: "15px" }}></div>
                </div>
              </td>
              <td>
                <Link
                  onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                  onClick={(event) => handleItemClick("80%")}
                  className={`light-black ${selectedItem === "80%" ? "active" : ""}`}
                >
                  <span className="mb-2">80%</span>
                  <div className="progress" style={{ height: "15px", borderRadius: "14px" }}>
                    <div className="progress-bar bg-dark-blue" style={{ width: "80%", height: "15px" }}></div>
                  </div>
                </Link>
              </td>
              <td>
              <Link
                  onMouseEnter={(e) => handleItemMouseEnter("education", e)}
                  onClick={(event) => handleItemClick("education")}
                  className={`gray ${
                    selectedItem === "education" ? "active" : ""
                  }`}
                >
                  Education
                </Link>
              </td>
              <td>
                  Designdot
              </td>
             
              <td>
                5 Yrs.
              </td>
              <td>
                Noida
              </td>
              <td>
              good Fit
              </td>
              <td>
                <div className="dropdown dropright float-right">
                  <button
                    type="button"
                    className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                    data-toggle="dropdown"
                  >
                    <BsThreeDots className="black fa-26" />
                  </button>
                  <div className="dropdown-menu transition-0 j-gray-bg">
                    <Link to="/view-applicant" className="dropdown-item fa-14 light-black fw-400">View Profile </Link>
                    <Link className="dropdown-item fa-14 light-black fw-400">Download Resume </Link>
                    <Link className="dropdown-item fa-14 light-black fw-400">+91 9876543210  </Link>
                    <Link className="dropdown-item fa-14 light-black fw-400">rahul.srivastav@gmail.com</Link>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="position-relative">
      {showBox && (
        <div
          className={`box ${showBox ? "slide-down" : "slide-up"}`}
          style={{
            top: boxPosition.top,
            left: boxPosition.left,
          }}
        >
          {selectedItem === "Condidate" && (
            <div className="box-info">
              <p className="fa-14 gray fw-400 line-height-24">
                As a Marketing Professional my accentuation is on conversations, for which
                it is fundamental that we know our get-together of individuals well or
                potentially have the preparation to get some answers concerning them
                Pieces of information aren’t actually what examination are uncovering to
                us how ....
              </p>
              
              <div className="d-sm-flex d-block justify-content-between">
                <Link to="/profile" className="btn blue-btn mt-3 px-3 w-sm-100">
                    View Profile
                </Link>

                <Link to="#" className="btn blue-btn mt-3 px-3 w-sm-100">
                    Download Resume
                </Link>
                </div>
            </div>
          )}

          {selectedItem === "80%" && (
            <div className="skill-content">
              <ul className="skill-content-list">
                <li>
                  <span>NODE JS</span> <span>90%</span>
                </li>
                <li>
                  <span>ANGULAR</span> <span>90%</span>
                </li>
                <li>
                  <span>ORACLE</span> <span>90%</span>
                </li>
                <li>
                  <span>PYTHON</span> <span>80%</span>
                </li>
              </ul>
            </div>
          )}

          {selectedItem === "senior" && (
            <div className="work-experience">
               <p className="fa-16 gray mb-1"> CTC - Rs. 4,00,000 </p>
               <h4 className="card-title fa-18 mb-3"> Other Skills </h4>
               <WorkSkills/>
            </div>
          )}

          {selectedItem === "education" && (
            <div className="work-experience">
              <Education/>
            </div>
          )}

          <button className="box-close" onClick={handleBoxClose}>
            X
          </button>
        </div>
      )}
    </div>
      <div className="pagination-row float-right">
        <Pagination />
      </div>
    </>
  );
};

export default ManageJobList;
