
import React from 'react';
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import Dummy from '../../../../assets/dummy.jpg';
import EditProfileSummary from '../Profile/ProfileModal/EditProfileSummary';
import AddNewExperience from '../../UserPanel/Profile/ProfileModal/AddNewExperience';
import AddNewEducation from '../../UserPanel/Profile/ProfileModal/AddNewEducation';
import AddProjects from '../../UserPanel/Profile/ProfileModal/AddProjects';
import '../../../CommonComponents/InputTag.css';
import AddInterest from './ProfileModal/AddInterest';
import AwardsRecognition from './ProfileModal/AwardsRecognition';


const ProfileSummaryData = [
  {
    heading: 'Summary',
    paragraphs: [
      {
        content:'As a Marketing Professional my accentuation is on conversations, for which it is fundamental that we know our get-together of individuals well or potentially have the preparation to get some answers concerning them  Pieces of information arent actually what examination are uncovering to us how arrangements channels are working , yet certified pieces of information begin from real conversations   We create winning products and AI | New-technology | IOT | Mobile / Cloud Application | Web | Branding | Advertising | Digital Media Marketing | Marketing Campaigns | Sales-automation'
      }
    ],
      icons: [<EditProfileSummary/>]
  },
  {
    heading: 'Experiences',
    subheading: 'Senior Business Development Manager',
    paragraphs: [
      {
        content: "Designdot Technologies Pvt Ltd · Full-time",
      },
      {
        content: "Jan 2019 - Present · 4 yrs 6 mos",
      },
      {
        content:"We create winning products and Marketing Campaigns | Sales-automation | AI | New-technology | IOT | Mobile / Cloud Application",
        className:'mt-2',
      },
    ],
    icons: [
      <AddNewExperience icon={<AiOutlinePlusCircle className='gray fa-28 fa-mdd-24'/>} className="icon-deafult"  />,
      <AddNewExperience  icon={<AiOutlineEdit className='gary ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
    ]
  },
  {
    heading: 'Education',
    subheading: 'Netaji Subhas Institute of Technology',
    image: Dummy,
    paragraphs: [
      {
        content:"Bachelor's degree, Bachelor of Engineering",
      },
      {
        content:'2015 - 2019',
      
      }
      ],
    icons: [
      <AddNewEducation icon={<AiOutlinePlusCircle className='gray fa-28 fa-mdd-24'/>} className="icon-deafult"  />,
      <AddNewEducation  icon={<AiOutlineEdit className='gray ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
    ]
  },
  
  {
    heading: 'Certification',
    subheading: 'Netaji Subhas Institute of Technology',
    image: Dummy,
    paragraphs: [
      {
        content:"Bachelor's degree, Bachelor of Engineering",
      },
      {
        content:'2015 - 2019',
        
      }
      ],
    icons: [
      <AddNewEducation icon={<AiOutlinePlusCircle className='gray fa-28 fa-mdd-24'/>} className="icon-deafult"  />,
      <AddNewEducation  icon={<AiOutlineEdit className='gray ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
    ]
  },


  {
    heading: 'Projects',
    subheading: 'Sales CRM',
    paragraphs: [
      {
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'   
      },

      {
        content: '2015 - 2019'   
      },
      ],
    icons: [
      <AddProjects icon={<AiOutlinePlusCircle className='gray fa-28 fa-mdd-24'/>} className="icon-deafult"  />,
      <AddProjects  icon={<AiOutlineEdit className='gray ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
    ]
  },

  {
    heading: 'Awards & Recognition',
    subheading: 'Najm Award for Innovation, EmiratesSales CRM',
    paragraphs: [
      {
        content: '2019'  , 
        className:'gray',
      },
      ],
    icons: [
      <AwardsRecognition icon={<AiOutlinePlusCircle className='gray fa-28 fa-mdd-24'/>} className="icon-deafult"  />,
      <AwardsRecognition  icon={<AiOutlineEdit className='gray ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
    ]
  },

  {
    heading: 'Interest',
    paragraphs: [
      {
        content: 'Game' ,  
        className:'gray',
      },
      ],
    icons: [
      <AddInterest icon={<AiOutlinePlusCircle className='gray fa-28 fa-mdd-24'/>} className="icon-deafult"  />,
      <AddInterest  icon={<AiOutlineEdit className='gary-text ml-1 ml-sm-3 fa-28 fa-mdd-24'/>} className="icon-deafult" />
    ]
  },
];

const ProfileSummary = () => {
  return (
    <>
        {ProfileSummaryData.map((section, index) => (
        <div className='profile-summary-container' key={index}>
          <div className='profile-summary-header line-height-0 d-flex'>
            <div className='profile-summary-heading'>
              <h2>{section.heading}</h2>
            </div>
            <div className='profile-summary-icon'>
              {section.icons}
            </div>
          </div>
          <div className='profile-summary-body mt-1'>
          <div className='profile-summary-inner-container d-flex'>
              {section.image && (
                  <div className='profile-view-image mr-sm-4 mr-2'>
                    <img src={section.image} alt="Title" className='p-view-image' />
                  </div>
                )}
                <div className='profile-view-content'>
                  <h4>{section.subheading}</h4>
                  {section.paragraphs.map((paragraph, index) => (
                    <p key={index} className={paragraph.className}>{paragraph.content}</p>
                  ))}
                </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProfileSummary;
